import { useQuery } from "@tanstack/react-query";

import { CustomerApi } from "../api/CustomerApi";

export const CustomerQueryKeys = {
  all: ["customers"] as const,
  list: () => [...CustomerQueryKeys.all, "list"] as const,
  details: (customerId: string) =>
    [...CustomerQueryKeys.all, customerId] as const,
};

export const useCustomersListQuery = () => {
  return useQuery({
    queryKey: CustomerQueryKeys.list(),
    queryFn: () => CustomerApi.listCustomers(),
  });
};

export const useCustomerQuery = ({
  customerId,
  enabled = true,
}: {
  customerId: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: CustomerQueryKeys.details(customerId),
    queryFn: () => CustomerApi.getCustomer(customerId),
    enabled,
  });
};
