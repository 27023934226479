import { FC, useState } from "react";
import { DialogTitle } from "@headlessui/react";

import { I18nKeyType } from "@/i18n";
import { useTranslate } from "@/i18n/useTranslate";
import { useTranslator } from "@/i18n/useTranslator";

import { DsButton } from "./DsButton";
import { DsModal, DsModalPropsType } from "./DsModal";
import { DsText } from "./DsText";

type DsConfirmationModalPropsType = Omit<DsModalPropsType, "children"> & {
  title?: I18nKeyType;
  titlei18nOptions?: object;
  message?: I18nKeyType;
  messagei18nOptions?: object;
  onConfirm: () => void;
  onCancel: () => void;
  cancelButtonText?: I18nKeyType;
  confirmButtonText?: I18nKeyType;
  confirmButtoni18nOptions?: object;
  isConfirmButtonLoading?: boolean;
  hasConfirmationInpupt?: boolean;
  confirmationText?: string;
};

const DsConfirmationModal: FC<DsConfirmationModalPropsType> = ({
  title,
  titlei18nOptions,
  message,
  messagei18nOptions,
  onConfirm,
  onCancel,
  cancelButtonText = "cancel",
  confirmButtonText = "yes",
  confirmButtoni18nOptions,
  isConfirmButtonLoading,
  hasConfirmationInpupt,
  confirmationText,
  ...modalProps
}) => {
  const { t } = useTranslator();

  const parsedTitle = useTranslate(title, titlei18nOptions);
  const parsedMessage = useTranslate(message, messagei18nOptions);
  const parsedConfirmationText = useTranslate(
    confirmButtonText,
    confirmButtoni18nOptions,
  );

  const [confirmInputValue, setConfirmInputValue] = useState("");

  const isConfirmationButtonDisabled =
    hasConfirmationInpupt &&
    !isConfirmButtonLoading &&
    confirmInputValue !== confirmationText;

  return (
    <DsModal {...modalProps}>
      <div className="sm:w-full sm:max-w-lg">
        <div>
          <div className="mt-3 text-center sm:mt-5">
            {parsedTitle && (
              <DialogTitle
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900">
                {parsedTitle}
              </DialogTitle>
            )}

            {parsedMessage && (
              <div className="mt-2">
                <p className="text-sm text-gray-500">{parsedMessage}</p>
              </div>
            )}
          </div>
        </div>

        {hasConfirmationInpupt && (
          <>
            <DsText className="mt-4 text-red-500 font-medium">
              {t("confirmation_modal.confirmation_message", {
                confirmationText,
              })}
            </DsText>
            <input
              className="border px-4 py-2 rounded-lg w-full"
              onChange={e => setConfirmInputValue(e.target.value)}
            />
          </>
        )}

        <div className="mt-5 flex flex-row gap-4">
          <DsButton className="inline-flex w-full" onClick={onCancel}>
            {t(cancelButtonText)}
          </DsButton>
          <DsButton
            className="inline-flex w-full"
            variant="secondary"
            disabled={isConfirmationButtonDisabled}
            onClick={onConfirm}
            isLoading={isConfirmButtonLoading}>
            {parsedConfirmationText}
          </DsButton>
        </div>
      </div>
    </DsModal>
  );
};

export { DsConfirmationModal };
