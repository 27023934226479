import * as React from "react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
} from "@headlessui/react";

import { I18nKeyType } from "@/i18n";
import { useTranslate } from "@/i18n/useTranslate";
import { useDebouncedValue } from "@/src/hooks/useDebouncedValue";
import { classNames } from "@/src/utils/classNames";

import { DsIcon } from "../DsIcon";
import { DsLoading } from "../DsLoading";
import { DsText } from "../DsText";
import { DsComboBoxItem, DsComboBoxItemType } from "./DsComboBoxItem";

type DsComboBoxPropsType = {
  title?: I18nKeyType;
  i18nOptions?: object;
  selectedValue: string;
  items?: DsComboBoxItemType[];
  children?: React.ReactNode;
  onSelect: (value: DsComboBoxItemType | null) => void;
  className?: string;
  listBoxClassName?: string;
  listBoxButtonClassName?: string;
  isLoading?: boolean;
};

const DsComboBox = React.forwardRef<HTMLButtonElement, DsComboBoxPropsType>(
  (
    {
      selectedValue,
      onSelect,
      title,
      items,
      i18nOptions,
      children,
      className,
      listBoxClassName,
      listBoxButtonClassName,
      isLoading,
    },
    ref,
  ) => {
    const i18nText = useTranslate(title, i18nOptions);

    const [searchText, setSearchText] = React.useState("");
    const debouncedSearchText = useDebouncedValue(searchText, 300);

    const emptyOptionText = useTranslate("no_options");

    const selected = items?.find(item => item.value === selectedValue);

    const filteredItems =
      debouncedSearchText === ""
        ? items
        : items?.filter(item =>
            item?.label
              ?.toLowerCase()
              .includes(debouncedSearchText.toLowerCase()),
          );

    const handleOnChange = (value: DsComboBoxItemType | null) => {
      setSearchText("");
      onSelect(value);
    };

    const getDisplayValue = (item: DsComboBoxItemType) => item?.label;

    return (
      <Combobox as="div" immediate value={selected} onChange={handleOnChange}>
        <>
          {i18nText && (
            <DsText className="mb-2" variant="dataDisplayTitle">
              {i18nText}
            </DsText>
          )}
          <div className={classNames("relative", className)}>
            <ComboboxInput
              className="w-full outline-sos-yellow/80 rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm sm:text-sm/6"
              onChange={event => setSearchText(event.target.value)}
              onBlur={() => setSearchText("")}
              displayValue={getDisplayValue}
            />
            <ComboboxButton
              ref={ref}
              onClick={event => event.stopPropagation()}
              className={classNames(
                "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2",
                listBoxButtonClassName,
              )}>
              <span
                className={classNames(
                  "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2",
                )}>
                {isLoading ? (
                  <DsLoading size="small" />
                ) : (
                  <DsIcon icon="ChevronUpDown" />
                )}
              </span>
            </ComboboxButton>

            {Boolean(filteredItems?.length) && (
              <ComboboxOptions
                className={classNames(
                  "absolute z-10 mt-1 max-h-60 w-full overflow-auto py-1 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm",
                  listBoxClassName,
                )}>
                {!isLoading && !items?.length && !children && (
                  <DsText className="px-4 py-1 text-gray-500 italic">
                    {emptyOptionText}
                  </DsText>
                )}

                {filteredItems?.map(item => (
                  <DsComboBoxItem
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    labelSecondaryText={item.labelSecondaryText}
                  />
                ))}
                {children && children}
              </ComboboxOptions>
            )}
          </div>
        </>
      </Combobox>
    );
  },
);
DsComboBox.displayName = "DsComboBox";

export { DsComboBox };
