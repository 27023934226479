import { FC } from "react";

import { useTranslator } from "@/i18n/useTranslator";

import { DsText } from "../components/ui";
import { useServiceQuery } from "../data/queries/serviceQueries";

type DashboardCustomerNameTextPropsType = {
  jobId: string;
  serviceId: string;
};

export const DashboardCustomerNameText: FC<
  DashboardCustomerNameTextPropsType
> = ({ jobId, serviceId }) => {
  const { t } = useTranslator();

  // Get task's parent service data
  const { data: serviceData } = useServiceQuery({ jobId, serviceId });

  let text = serviceData?.customer?.name;

  if (!text) {
    text = t("dashboard_customer_name_text_block.customer_not_found");
  }

  return <DsText>{text}</DsText>;
};
