import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";

import { classNames } from "@/src/utils/classNames";

export type DsDividerPropsType = {
  flex?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof dividerVariants>;

const dividerVariants = cva("border-sos-grey", {
  variants: {
    orientation: {
      horizontal: "w-full h-0",
      vertical:
        "h-full w-0 data-[flex=true]:self-stretch data-[flex=true]:h-auto",
    },
    variant: {
      middle:
        "data-[orientation='horizontal']:w-[80%] data-[orientation='vertical']:my-2",
    },
    thickness: {
      thick:
        "data-[orientation='horizontal']:border-t data-[orientation='vertical']:border-l",
      thin: "data-[orientation='horizontal']:border-t-[0.5px] data-[orientation='vertical']:border-l-[0.5px]",
    },
  },
  defaultVariants: {
    orientation: "horizontal",
    thickness: "thick",
  },
});

const DsDivider: React.FC<DsDividerPropsType> = ({
  orientation = "horizontal",
  thickness,
  variant,
  flex = false,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        dividerVariants({ orientation, thickness, variant, className }),
      )}
      data-orientation={orientation}
      data-flex={flex}
      {...props}
    />
  );
};

export { DsDivider };
