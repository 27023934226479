import {
  collection,
  doc,
  getDoc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import {
  FirebaseStorage,
  ref,
  UploadResult,
  uploadString,
} from "firebase/storage";
import { useStorage } from "reactfire";

import {
  BinSizeEnum,
  BinTaskEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";
import { firestore } from "@/firebase.config";
import { MATERIAL_LIST } from "@/src/domain/constants";
import { JobFormSubmissionType, UpdateJobPropsType } from "@/src/domain/job";
import { GetTaskPropsType } from "@/src/domain/task";
import { convertTaskTypeToTaskId } from "@/src/utils/bin";
import { generateFirebaseTimestamp } from "@/src/utils/date";
import { resizeFile } from "@/src/utils/file";
import { logger } from "@/src/utils/logger";

import { ServiceApi } from "./ServiceApi";
import { SpikeSosDb } from "./SpikesosDb";

export const JobApi = {
  getRef: (jobId: string) => {
    return doc(SpikeSosDb.jobs, jobId);
  },
  getJob: async (jobId: string) => {
    try {
      const docRef = doc(SpikeSosDb.jobs, jobId);
      const document = await getDoc(docRef);

      if (!document.exists()) {
        throw new Error("Job not found");
      }

      return {
        id: document.id,
        ...document.data(),
      };
    } catch (error) {
      logger.error("Error getting job", error);

      throw error;
    }
  },
  // Create a new job
  createJob: async (
    jobData: JobFormSubmissionType & { storage: FirebaseStorage },
  ) => {
    const {
      customer,
      schedulePreference = "flexible",
      site,
      user,
      notes,
      images,
      adminNotes,
      taskNotes,
      material,
      yardAmount,
      storage,
      ...restParams
    } = jobData;

    const serviceConnectedTask = restParams.connectedTask
      ? {
          ...restParams.connectedTask,
          connectedTask: null,
        }
      : null;

    const { name, customerId, ...jobSite } = site;
    if (!customer || !site || !user) {
      throw new Error("Missing required parameters");
    }

    try {
      await runTransaction(firestore, async transaction => {
        const setPromises = [];

        //Use transaction to create job
        const jobRef = doc(SpikeSosDb.jobs);
        const jobTransaction = transaction.set(jobRef, {
          ...restParams,
          customer,
          site: jobSite,
          geopoint: site.geopoint,
          connectedTask: null,
          completed: false,
          completedAt: null,
          started: false,
          billed: false,
          address: jobSite.address,
          aggregate:
            material &&
            yardAmount &&
            (restParams.binTask === BinTaskEnum.DROPOFF ||
              restParams.binTask === BinTaskEnum.EXCHANGE ||
              restParams.binTask === BinTaskEnum.WAITANDLOAD)
              ? {
                  ...MATERIAL_LIST.find(item => item.material === material)!,
                  yardAmount,
                }
              : null,
          date: generateFirebaseTimestamp(jobData.date),
          startDate: generateFirebaseTimestamp(jobData.date),
          createdAt: generateFirebaseTimestamp(),
          createdBy: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
          },
          cleanupType:
            restParams.type === ServiceTypeEnum.JUNK
              ? restParams.cleanupType
              : null,
        });
        setPromises.push(jobTransaction);

        const jobId = jobRef.id;

        //Use transaction to write service data
        const serviceRef = doc(collection(firestore, `jobs/${jobId}/services`));

        const serviceTransaction = transaction.set(serviceRef, {
          jobId,
          binTask: restParams.binTask ?? null,
          size: restParams.hasBin
            ? (restParams.size ?? BinSizeEnum.FIVE)
            : null,
          assignedBin: null,
          connectedTask: serviceConnectedTask,
          floorSqftSiteLocked: Boolean(site?.floorSqft),
          floorsCleanedSiteLocked: Boolean(site?.floorsCleaned),
          floorSqft: site?.floorSqft ?? "",
          floorCleaned: site.floorsCleaned,
          geopoint: site.geopoint,
          address: site.address,
          customer,
          contact: site.contact,
          completed: false,
          billed: false,
          stage: 0,
          started: false,
          deleted: false,
          adminNotes: adminNotes ?? null,
          notes: notes ?? null,
          schedulePreference,
          type: restParams.type,
          taskNotes: taskNotes ?? null,
          date: generateFirebaseTimestamp(restParams.date),
          dueAt: restParams.hasBin
            ? generateFirebaseTimestamp(restParams.date)
            : null,
          createdAt: generateFirebaseTimestamp(),
        });
        setPromises.push(serviceTransaction);

        const serviceId = serviceRef.id;

        //Use transaction to write notes if exist
        const notesCollection = collection(
          firestore,
          `jobs/${jobId}/services/${serviceId}/notes`,
        );

        const customerNotesCollection = collection(
          firestore,
          `jobs/${jobId}/services/${serviceId}/customerNotes`,
        );

        const noteParams = {
          createdAt: generateFirebaseTimestamp(),
          createdBy: {
            id: user.uid,
            photoURL: user.photoURL,
            name: user.displayName,
          },
          files: [],
          jobId,
          serviceId,
          taskId: "",
        };
        if (notes || images) {
          let uploadResult: UploadResult[] = [];
          if (images && images.length > 0) {
            const resizedImages = await Promise.all(images.map(resizeFile));

            const imagesRef = resizedImages.map((images, index) => ({
              src: images as string,
              ref: ref(
                storage,
                `jobs/${jobId}/notes/notefile-${Date.now()}-${index}.jpg`,
              ),
            }));
            const uploadingQueries = imagesRef.map(imageRef =>
              uploadString(imageRef.ref, imageRef.src, "data_url"),
            );
            uploadResult = await Promise.all(uploadingQueries);
          }
          const notesRef = doc(notesCollection);
          const notesTransaction = transaction.set(notesRef, {
            ...noteParams,
            note: notes,
            files: uploadResult.map(image => image.ref.fullPath),
          });

          setPromises.push(notesTransaction);
          if (jobData.site) {
            const siteNotesRef = doc(notesCollection);

            const siteNotesTransaction = transaction.set(siteNotesRef, {
              ...noteParams,
              note: site.notes,
            });
            setPromises.push(siteNotesTransaction);
            if (site.lockbox) {
              const lockboxNoteRef = doc(notesCollection);

              const siteLockboxTransaction = transaction.set(lockboxNoteRef, {
                ...noteParams,
                note: `Lockbox: ${jobData.site.lockbox}`,
              });
              setPromises.push(siteLockboxTransaction);
            }
          }
        }
        if (jobData.customerNote) {
          const customerNotesRef = doc(customerNotesCollection);
          const customerNotesTransaction = transaction.set(customerNotesRef, {
            note: jobData.customerNote,
            ...noteParams,
          });
          setPromises.push(customerNotesTransaction);
        }
        //Use transaction to set tasks
        let taskId;
        let connectedTask = null;
        if (restParams.hasJunk) taskId = "junkremoval";

        if (restParams.hasBin) {
          switch (restParams.binTask) {
            case BinTaskEnum.DROPOFF:
              taskId = "dropbin";
              break;
            case BinTaskEnum.PICKUP:
              taskId = "pickupbin";
              connectedTask = restParams.connectedTask ?? null;
              break;
            case BinTaskEnum.DUMPANDRETURN:
              taskId = "dumpandreturnbin";
              connectedTask = restParams.connectedTask ?? null;
              break;
            case BinTaskEnum.EXCHANGE:
              taskId = "exchangebin";
              connectedTask = serviceConnectedTask;
              break;
            case BinTaskEnum.WAITANDLOAD:
              taskId = "waitandloadbin";
              break;
            default:
              taskId = "dropbin";
              break;
          }
        }
        const taskParams = {
          serviceId,
          jobId,
          address: site.address,
          geopoint: site?.geopoint,
          assignedTo: null,
          completed: false,
          completedUpdatedAt: null,
          dueAt: generateFirebaseTimestamp(jobData.date),
          end: generateFirebaseTimestamp(jobData.date),
          size: jobData.hasBin ? (restParams.size ?? BinSizeEnum.FIVE) : null,
          started: false,
          startedUpdatedAt: null,
          cleanupType: jobData.hasJunk ? restParams.cleanupType : null,
          connectedTask,
        };

        if (taskId) {
          const taskRef = doc(
            firestore,
            `jobs/${jobId}/services/${serviceId}/tasks`,
            taskId,
          );
          const taskTransaction = transaction.set(taskRef, {
            ...taskParams,
            taskId,
          });
          setPromises.push(taskTransaction);
        }
        return Promise.allSettled(setPromises);
      });
    } catch (error) {
      logger.error("Error creating job", error);

      throw error;
    }
  },
  updateJob: async ({ jobId, ...jobParams }: UpdateJobPropsType) => {
    try {
      if (!jobId) {
        throw new Error("Missing required parameters");
      }
      const jobRef = JobApi.getRef(jobId);
      await updateDoc(jobRef, jobParams);

      return { jobId, ...jobParams };
    } catch (error) {
      logger.error("Error updating job", error);

      throw error;
    }
  },
  updateJobType: async ({
    jobId,
    serviceId,
    taskId,
    type,
    ...jobParams
  }: UpdateJobPropsType) => {
    if (!jobId) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(firestore, async transaction => {
        const setPromises = [];
        const jobRef = JobApi.getRef(jobId);
        const jobDoc = await getDoc(jobRef);
        const jobTransaction = transaction.update(jobRef, {
          ...jobDoc.data(),
          ...jobParams,
          hasBin: Boolean(type === ServiceTypeEnum.BIN),
          hasJunk: Boolean(type === ServiceTypeEnum.JUNK),
          binTask: type === ServiceTypeEnum.BIN ? jobParams.binTask : null,
          cleanupType:
            type === ServiceTypeEnum.JUNK ? jobParams.cleanupType : null,
          size:
            type === ServiceTypeEnum.JUNK
              ? null
              : (jobParams.size ?? jobDoc.data()?.size),
          aggregate:
            type === ServiceTypeEnum.JUNK
              ? null
              : (jobParams.aggregate ?? jobDoc.data()?.aggregate),
          connectedTask:
            type === ServiceTypeEnum.JUNK
              ? null
              : (jobParams.connectedTask ?? jobDoc.data()?.connectedTask),
        });
        setPromises.push(jobTransaction);

        if (serviceId) {
          const serviceRef = ServiceApi.getRef({ jobId, serviceId });
          const oldService = (await getDoc(serviceRef)).data();
          const serviceTransaction = transaction.update(serviceRef, {
            ...oldService,
            binTask: type === ServiceTypeEnum.JUNK ? null : jobParams.binTask,
            connectedTask:
              type === ServiceTypeEnum.JUNK
                ? null
                : (jobParams.connectedTask ?? oldService?.connectedTask),
            size:
              type === ServiceTypeEnum.JUNK
                ? null
                : (jobParams.size ?? oldService?.size),
            type,
          });
          setPromises.push(serviceTransaction);
        }
        //Remove old task doc and create a new one since we need the new task type as taskId
        const taskType = jobParams.binTask ?? jobParams.cleanupType;
        if (taskType && taskId) {
          const newTaskId = convertTaskTypeToTaskId(taskType);
          const oldTaskRef = doc(
            SpikeSosDb.jobs,
            `${jobId}/services/${serviceId}/tasks/${taskId}`,
          );
          const oldTaskData = (await getDoc(oldTaskRef)).data();
          const deleteTaskTransaction = transaction.delete(oldTaskRef);
          setPromises.push(deleteTaskTransaction);
          const newTaskRef = doc(
            firestore,
            `jobs/${jobId}/services/${serviceId}/tasks`,
            newTaskId,
          );
          const { binTask, aggregate, ...restTask } = jobParams;
          const newTaskTransaction = transaction.set(newTaskRef, {
            ...oldTaskData,
            ...restTask,
            jobId,
            serviceId,
            taskId: newTaskId,
          });
          setPromises.push(newTaskTransaction);
        }

        return Promise.allSettled(setPromises);
      });
      return {
        jobId,
        serviceId,
        taskId,
      };
    } catch (error) {
      logger.error("Error updating job", error);

      throw error;
    }
  },
  deleteJob: async ({ jobId, serviceId, taskId }: GetTaskPropsType) => {
    try {
      await runTransaction(firestore, async transaction => {
        const setPromises = [];
        const jobRef = doc(SpikeSosDb.jobs, jobId);
        const serviceRef = doc(
          SpikeSosDb.jobs,
          `${jobId}/services/${serviceId}`,
        );
        const taskRef = doc(
          SpikeSosDb.jobs,
          `${jobId}/services/${serviceId}/tasks/${taskId}`,
        );

        setPromises.push(transaction.delete(jobRef));
        setPromises.push(transaction.delete(taskRef));
        setPromises.push(transaction.delete(serviceRef));

        return Promise.allSettled(setPromises);
      });

      return { jobId, serviceId, taskId };
    } catch (error) {
      logger.error("Error deleting job", error);

      throw error;
    }
  },
};
