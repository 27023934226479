import {
  BinStatusEnum,
  BinTaskEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";
import { ServicesType } from "@/domain/models/job";
import { i18n } from "@/i18n";

import { ServiceTypeEnumSchema } from "../domain/job";

export const getServiceTypeOptions = (
  disabledFields?: ServiceTypeEnum.BIN | ServiceTypeEnum.JUNK | "both",
) => [
  {
    value: ServiceTypeEnumSchema.Enum.bin,
    disabled: Boolean(
      disabledFields === ServiceTypeEnum.BIN || disabledFields === "both",
    ),
    label: i18n.t("add_new_job_drawer.bin_rental"),
  },
  {
    value: ServiceTypeEnumSchema.Enum.junk,
    disabled: Boolean(
      disabledFields === ServiceTypeEnum.JUNK || disabledFields === "both",
    ),
    label: i18n.t("add_new_job_drawer.site_cleanup"),
  },
];

export const filterServicesForBinConnection = (data: ServicesType[]) => {
  const excludedBinIdentifiers = data
    .filter(service => !service.completed && service.connectedTask)
    .map(service => service.connectedTask?.assignedBin?.identifier);

  return data.filter(service => {
    if (!service.assignedBin) return false;

    const isExchangeTask =
      service.binTask === BinTaskEnum.EXCHANGE &&
      service.assignedBin.status === BinStatusEnum.ASSIGNED;

    const isDropoffTask =
      service.binTask === BinTaskEnum.DROPOFF && !service.completed;

    return (
      (isExchangeTask || isDropoffTask) &&
      !excludedBinIdentifiers.includes(service.assignedBin?.identifier)
    );
  });
};
