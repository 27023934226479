import { BinMaterialEnum } from "@/domain/models/common";

export const TASK_TIME_DURATION = 90;

export const MATERIAL_LIST = [
  { material: BinMaterialEnum.CLEAR_LIMESTONE, unitPrice: 50 },
  { material: BinMaterialEnum.LIMESTONE_CRUSH, unitPrice: 40 },
  { material: BinMaterialEnum.HPB_LIMESTONE, unitPrice: 44 },
  { material: BinMaterialEnum.LIMESTONE_SCREENING, unitPrice: 37 },
  { material: BinMaterialEnum.PEA_STONE_GRAVEL, unitPrice: 62 },
  { material: BinMaterialEnum.RIVER_STONE_GRAVEL, unitPrice: 75 },
  { material: BinMaterialEnum.BRICK_SAND, unitPrice: 41 },
  { material: BinMaterialEnum.CONCRETE_SAND, unitPrice: 41 },
  { material: BinMaterialEnum.STOCK_PILE_SOIL, unitPrice: 18 },
  { material: BinMaterialEnum.SCREENED_TOPSOIL, unitPrice: 30 },
  { material: BinMaterialEnum.TRIPLE_MIX_SOIL, unitPrice: 55 },
  { material: BinMaterialEnum.GROW_MAX_SOIL, unitPrice: 67 },
  { material: BinMaterialEnum.SHREDDED_PINE_MULCH, unitPrice: 62 },
  { material: BinMaterialEnum.BLACK_BEAUTY_MULCH, unitPrice: 62 },
  { material: BinMaterialEnum.CLEAN_CONCRETE, unitPrice: 25 },
  { material: BinMaterialEnum.MIX_BRICK, unitPrice: 30 },
  { material: BinMaterialEnum.ASPHALT, unitPrice: 35 },
];

export const GOOGLE_MAP_BASE_URL = `https://www.google.com/maps?daddr=`;
