import { FC } from "react";

import { I18nKeyType } from "@/i18n";
import { useTranslate } from "@/i18n/useTranslate";

import { DsText, DsTextPropsType } from "../components/ui";
import { classNames } from "../utils/classNames";

type DashboardDriverSelectorButtonPropsType = Pick<
  DsTextPropsType,
  "variant"
> & {
  onClick: () => void;
  className?: string;
  titleClassName?: string;
  title: I18nKeyType;
  titlei18nOptions?: object;
};

const DashboardDriverSelectorButton: FC<
  DashboardDriverSelectorButtonPropsType
> = ({
  onClick,
  className,
  titleClassName,
  title,
  titlei18nOptions,
  variant = "drawerTitle",
}) => {
  const i18nText = useTranslate(title, titlei18nOptions);

  return (
    <div
      onClick={onClick}
      className={classNames(
        "min-h-14 min-w-24 max-w-32 md:w-24 flex items-center rounded-lg justify-center py-1 md:py-0 border border-gray-300 bg-white hover:cursor-pointer",
        className,
      )}>
      <DsText
        variant={variant}
        className={classNames("text-center", titleClassName)}>
        {i18nText}
      </DsText>
    </div>
  );
};

export { DashboardDriverSelectorButton };
