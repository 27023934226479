import { BinType } from "@/domain/models/bin";
import {
  BinSizeEnum,
  CleanupTypeEnum,
  ServiceTaskTypeEnum,
} from "@/domain/models/common";
import { i18n } from "@/i18n";

import { dateAsDayjs } from "./date";

export type RentalStatusType = "available" | "rented" | "overdue";

type RentalStatusReturnType = {
  rentalStatus: RentalStatusType;
  rentalFormattedStatus: string;
  isOverdue: boolean;
};

export const getBinRentalStatus = (bin: BinType): RentalStatusReturnType => {
  const dropDate = bin.currentData?.dropAt?.toDate();

  if (!dropDate) {
    return {
      rentalStatus: "available",
      rentalFormattedStatus: "--",
      isOverdue: false,
    };
  }

  const now = dateAsDayjs().startOf("day");

  // Must include a 14 day period for the bin to be considered overdue
  const dropDateasDayjs = dateAsDayjs(dropDate).add(14, "days").startOf("day");

  const { t } = i18n;

  const dateDiff = dropDateasDayjs.diff(now, "days");
  const dateDiffAbsolute = Math.abs(dateDiff === 0 ? 1 : dateDiff);

  // If it's already overdue
  if (dateDiff < 0) {
    return {
      rentalStatus: "overdue",
      rentalFormattedStatus: t("bins_page.days_overdue", {
        count: dateDiffAbsolute,
      }),
      isOverdue: true,
    };
  }

  if (dropDateasDayjs.isSame(now, "day")) {
    return {
      rentalStatus: "rented",
      rentalFormattedStatus: t("bins_page.due_today"),
      isOverdue: false,
    };
  }

  return {
    rentalStatus: "rented",
    rentalFormattedStatus: t("bins_page.overdue_in", {
      count: dateDiffAbsolute,
    }),
    isOverdue: false,
  };
};

export const getNameInitials = (name: string) => {
  const initials = name
    .split(" ")
    .map(word => word[0].toUpperCase())
    .join("");

  return initials;
};

// Makes Driver Name look like "John D."
export const formatDriverName = (name: string) => {
  const [firstName, lastName] = name.split(" ");

  return `${firstName} ${lastName ? `${lastName?.[0]}.` : ""}`;
};

export const formatDriverTaskType = (taskType: ServiceTaskTypeEnum) => {
  switch (taskType) {
    case ServiceTaskTypeEnum.DROPBIN:
      return i18n.t("format_driver_task_type.drop_bin");
    case ServiceTaskTypeEnum.PICKUPBIN:
      return i18n.t("format_driver_task_type.pickup_bin");
    case ServiceTaskTypeEnum.EXCHANGEBIN:
      return i18n.t("format_driver_task_type.exchange_bin");
    case ServiceTaskTypeEnum.WAITANDLOADBIN:
      return i18n.t("format_driver_task_type.wait_and_load");
    case ServiceTaskTypeEnum.JUNKREMOVAL:
      return i18n.t("format_driver_task_type.junk_removal");
    case ServiceTaskTypeEnum.DUMPANDRETURNBIN:
      return i18n.t("format_driver_task_type.dump_and_return_bin");
    default:
      return "";
  }
};

export const formatCleanupType = (cleanupType: CleanupTypeEnum) => {
  switch (cleanupType) {
    case CleanupTypeEnum.ROUGHSWEEP:
      return i18n.t("cleanup_type.rough_sweep");
    case CleanupTypeEnum.FINESWEEP:
      return i18n.t("cleanup_type.fine_sweep");
    case CleanupTypeEnum.FULLDETAIL:
      return i18n.t("cleanup_type.full_detail");
    default:
      return i18n.t("cleanup_type.junk_removal");
  }
};

export const formatBinSize = (size: BinSizeEnum) => {
  return `${size} ${i18n.t("yard")}`;
};
