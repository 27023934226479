import { FC, useState } from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";

import { UploadButton } from "../blocks";
import { DsIconButton } from "../components/ui";
import { JobFormType } from "../domain/job";

type ImageUploadPropsType = {
  control: Control<JobFormType>;
  value: File[] | null;
  setValue: UseFormSetValue<JobFormType>;
};

const ImageUploadController: FC<ImageUploadPropsType> = ({
  control,
  value,
  setValue,
}) => {
  const [previewImages, setPreviewImages] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setValue("images", files);
      const previews = files.map(file => URL.createObjectURL(file));
      setPreviewImages(previews);
    }
  };
  const handleRemove = (currentIndex: number) => {
    setValue(
      "images",
      value?.filter((_item, index) => index !== currentIndex) ?? null,
    );

    setPreviewImages(prev => {
      URL.revokeObjectURL(prev[currentIndex]);
      return prev.filter((_item, index) => index !== currentIndex);
    });
  };
  return (
    <>
      {previewImages?.length > 0 && (
        <div className="flex gap-1 mb-1">
          {previewImages.map((src, index) => (
            <div key={index} className="relative">
              <img
                src={src}
                alt={`Preview_${index + 1}`}
                className="w-16 h-16 object-fill"
              />
              <DsIconButton
                type="button"
                icon="Close"
                size="custom"
                className="absolute top-1 right-1 p-1 rounded-md"
                onClick={() => handleRemove(index)}
              />
            </div>
          ))}
        </div>
      )}
      <Controller
        name="images"
        defaultValue={[]}
        control={control}
        render={() => (
          <UploadButton
            type="file"
            id="hidden-input"
            multiple
            accept="image/*"
            onChange={e => {
              handleFileChange(e);
              e.target.value = "";
            }}
          />
        )}
      />
    </>
  );
};

export { ImageUploadController };
