import { FC } from "react";
import { ComboboxOption } from "@headlessui/react";

import { DsText } from "../DsText";

export type DsComboBoxItemType = {
  label: string;
  labelSecondaryText?: string;
  value: string;
};

const DsComboBoxItem: FC<DsComboBoxItemType> = ({
  label,
  value,
  labelSecondaryText,
}) => {
  return (
    <ComboboxOption
      value={value}
      onClick={e => e.stopPropagation()}
      className="group relative cursor-default select-none py-2 px-3 text-gray-900 data-[focus]:bg-sos-yellow/60 ">
      <div className="flex justify-between">
        <DsText className="truncate">{label}</DsText>
        {labelSecondaryText && (
          <DsText className="ml-2 truncate text-gray-500 ">
            {labelSecondaryText}
          </DsText>
        )}
      </div>
    </ComboboxOption>
  );
};

export { DsComboBoxItem };
