import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUser } from "reactfire";

import { BinApi } from "@/src/data/api/BinApi";
import { BinQueryKeys } from "@/src/data/queries/binQueries";
import { UnassignBinPropsType } from "@/src/domain/bin";
import { UpdateBinSizeParamsType } from "@/src/domain/task";

import { ServiceQueryKeys } from "../queries/serviceQueries";
import { TaskQueryKeys } from "../queries/taskQueries";

export const useDeleteBin = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, unknown, string>({
    mutationFn: (binId: string) => BinApi.deleteBin(binId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: BinQueryKeys.all });
    },
  });

  return mutation;
};

export const useUnassignBin = () => {
  const queryClient = useQueryClient();

  const { data: user } = useUser();

  const mutation = useMutation<
    string,
    unknown,
    Omit<UnassignBinPropsType, "user">
  >({
    mutationFn: ({ binId, jobId, serviceId }) =>
      BinApi.unassignBin({
        binId,
        jobId,
        serviceId,
        user,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: BinQueryKeys.all });
    },
  });

  return mutation;
};

export const useUpdateBinSizeMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateBinSizeParamsType,
    unknown,
    UpdateBinSizeParamsType
  >({
    mutationFn: params => BinApi.updateBinSize(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ServiceQueryKeys.all,
      });
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};
