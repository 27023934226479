import { FC } from "react";

import { DsIcon } from "../components/ui";
import { useTaskNotesQuery } from "../data/queries/notesQueries";
import { useServiceQuery } from "../data/queries/serviceQueries";

type DashboardTableNotesIconPropsType = {
  jobId: string;
  serviceId: string;
  taskId: string;
};

export const DashboardTableNotesIcon: FC<DashboardTableNotesIconPropsType> = ({
  jobId,
  serviceId,
  taskId,
}) => {
  // Get task's parent service data
  const { data: serviceData } = useServiceQuery({ jobId, serviceId });

  // Get task's notes
  const { data: taskNotes } = useTaskNotesQuery({ jobId, serviceId, taskId });

  const shouldShowIcon =
    Boolean(serviceData?.notes) ||
    Boolean(serviceData?.adminNotes) ||
    Boolean(taskNotes?.length);

  if (!shouldShowIcon) {
    return null;
  }

  return (
    <div className="bg-sos-yellow rounded-lg">
      <DsIcon icon="Notes" className="my-1 mx-3" />
    </div>
  );
};
