import { useState } from "react";

import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";
import { HeaderButton } from "@/src/blocks/HeaderButton";
import { NewBinForm } from "@/src/components/admin/NewBinForm";
import { RightPanel } from "@/src/components/RightPanel";

const BinsPageHeaderButton = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const { t } = useTranslator();

  const newBinKey: I18nKeyType = "bins_page.new_bin";

  const newBinTitle = t(newBinKey);

  return (
    <>
      <RightPanel
        controlledOpen={isPanelOpen}
        setControlledOpen={setIsPanelOpen}
        title={newBinTitle}
        content={
          <div className="m-4">
            <NewBinForm onSuccess={() => setIsPanelOpen(false)} />
          </div>
        }
        onDrawerClosed={() => setIsPanelOpen(false)}
      />
      <HeaderButton
        buttons={[
          {
            i18nKey: newBinKey,
            handleOnClick: () => setIsPanelOpen(true),
            icon: "Plus",
          },
        ]}
      />
    </>
  );
};

export { BinsPageHeaderButton };
