import { useQuery } from "@tanstack/react-query";

import { GetServicePropsType, ServiceFiltersType } from "@/src/domain/service";
import { filterServicesForBinConnection } from "@/src/utils/service";

import { ServiceApi } from "../api/ServiceApi";

export const ServiceQueryKeys = {
  all: ["services"] as const,
  list: (filters?: ServiceFiltersType) =>
    filters
      ? ([...ServiceQueryKeys.all, "list", { filters }] as const)
      : ([...ServiceQueryKeys.all, "list"] as const),
  details: (props: GetServicePropsType) => [...ServiceQueryKeys.all, { props }],
};

type ServicesListQueryType = {
  filters?: ServiceFiltersType;
};

export const useServicesListQuery = (params?: ServicesListQueryType) => {
  const { filters } = params ?? {};

  return useQuery({
    queryKey: ServiceQueryKeys.list(filters),
    queryFn: () => ServiceApi.listServices(filters),
  });
};

export const useServiceQuery = (params: GetServicePropsType) => {
  return useQuery({
    queryKey: ServiceQueryKeys.details(params),
    queryFn: () => ServiceApi.getService(params),
    enabled: Boolean(params.jobId) && Boolean(params.serviceId),
  });
};

export const useActiveServicesQuery = ({
  filters,
  enabled = true,
}: {
  filters?: ServiceFiltersType;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ServiceQueryKeys.list(filters),
    queryFn: () => ServiceApi.listServices(filters),
    enabled,
    select: data => filterServicesForBinConnection(data),
  });
};
