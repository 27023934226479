import React from "react";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete from "react-places-autocomplete";

import { CountryCodesEnum } from "../domain/commons";

type SiteAddressFieldPropsType = {
  value: string;
  onChange: (val: string) => void;
  onSelect: (val: string) => void;
};

const SiteAddressField = React.forwardRef<
  HTMLInputElement,
  SiteAddressFieldPropsType
>(({ value, onChange, onSelect, ...props }, ref) => {
  const { t } = useTranslation();
  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={{
        componentRestrictions: { country: [CountryCodesEnum.Canada] },
      }}
      {...props}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              autoComplete: "off",
              placeholder: t("add_new_job_drawer.site_address"),
              className:
                "w-full px-3 py-2 rounded-md text-sm border border-gray-300 text-black",
            })}
            ref={ref}
          />
          {(suggestions.length > 0 || loading) && (
            <div className="rounded-md shadow-sm border border-gray-300">
              {loading && (
                <div className="px-4 py-2 bg-white rounded-md hover:bg-yellow-200 hover:cursor-pointer">
                  {t("add_new_job_drawer.loading")}
                </div>
              )}
              {suggestions.map((suggestion, index) => (
                <div
                  {...getSuggestionItemProps(suggestion)}
                  key={index}
                  className={`px-4 py-2 bg-white text-sm rounded-md text-gray-500 hover:bg-yellow-200 hover:cursor-pointer ${
                    suggestion.active ? "bg-yellow-100 dark:bg-yellow-400" : ""
                  }`}>
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
});

SiteAddressField.displayName = "SiteAddressField";
export { SiteAddressField };
