import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

//@ts-expect-error Will be fixed slowly when migrating to TypeScript
import { SPACED_WRAPPER_ID } from "../components/admin/layout";

export const classNames = (...classes: ClassValue[]) => {
  return twMerge(clsx(classes));
};

export const changeMainWrapperSpacedClass = (className: string) => {
  const mainSpacedWrapper = document.getElementById(SPACED_WRAPPER_ID);

  if (mainSpacedWrapper) {
    mainSpacedWrapper.className = className;
  }
};
