import { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { upperFirst } from "lodash";

import { classNames } from "@/src/utils/classNames";

import { DashboardDriverSelectorButton } from "../blocks";
import { useDashboardDrivers } from "../hooks/useDashboardDrivers";
import {
  adminDashboardSelectedDateAtom,
  adminDashboardSelectedDriverIdAtom,
  adminDashboardSelectedTaskViewAtom,
} from "../stores/atoms";
import { DashboardDriverSelectorCard } from "./DashboardDriverSelectorCard";

const DashboardDriverSelector = () => {
  const driversRaw = useDashboardDrivers();

  const drivers = driversRaw.filter(driver => !driver.role_manager);
  const driverManagers = driversRaw.filter(driver => driver.role_manager);

  const [selectedDriverId, setSelectedDriverId] = useAtom(
    adminDashboardSelectedDriverIdAtom,
  );
  const [isShowingDriverManagers, setIsShowingDriverManagers] = useState(false);

  const selectedDate = useAtomValue(adminDashboardSelectedDateAtom);
  const selectedTaskView = useAtomValue(adminDashboardSelectedTaskViewAtom);

  return (
    <div className="pl-4 sm:pl-6 md:pl-8 flex flex-row gap-2 my-6">
      <DashboardDriverSelectorButton
        onClick={() => setSelectedDriverId("")}
        className={classNames(
          !selectedDriverId && "bg-sos-yellow border-sos-yellow",
          selectedTaskView === "all" && "px-4",
        )}
        title="dashboard_page.all_drivers_button"
        titlei18nOptions={{
          driverType:
            selectedTaskView !== "all" ? upperFirst(selectedTaskView) : "",
        }}
      />

      <div className="flex gap-1 overflow-x-scroll pr-4 sm:pr-6 md:pr-8">
        {drivers.map(driver => (
          <DashboardDriverSelectorCard
            key={driver.id}
            driverName={driver.displayName}
            driverId={driver.id}
            onDriverSelect={() => setSelectedDriverId(driver.id)}
            isSelected={driver.id === selectedDriverId}
            selectedDate={selectedDate}
            driverType={driver.driver_type}
          />
        ))}

        <div className="border-l border-gray-300 my-2 mx-2" />

        <DashboardDriverSelectorButton
          onClick={() => setIsShowingDriverManagers(!isShowingDriverManagers)}
          className={classNames(
            isShowingDriverManagers ? "bg-gray-200" : "bg-white ",
            "mr-1",
          )}
          title={
            isShowingDriverManagers
              ? "dashboard_page.hide_managers"
              : "dashboard_page.show_managers"
          }
          variant="tableColumnHeader"
          titleClassName={
            isShowingDriverManagers ? "text-black" : "text-sos-red"
          }
        />

        {isShowingDriverManagers &&
          driverManagers.map(manager => (
            <DashboardDriverSelectorCard
              key={manager.id}
              driverName={manager.displayName}
              driverId={manager.id}
              onDriverSelect={() => setSelectedDriverId(manager.id)}
              isSelected={manager.id === selectedDriverId}
              selectedDate={selectedDate}
              driverType={manager.driver_type}
            />
          ))}
      </div>
    </div>
  );
};

export { DashboardDriverSelector };
