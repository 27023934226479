import React, { forwardRef } from "react";

import "react-datepicker/dist/react-datepicker.css";

import { classNames } from "@/src/utils/classNames";

import { DsIcon } from "..";

type CustomInputType = {
  value?: string;
  onClick?: () => void;
  className?: string;
  placeholder?: string;
};

const CustomInput = forwardRef<HTMLButtonElement, CustomInputType>(
  ({ value, onClick, className, placeholder }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className={classNames(
          className,
          "flex w-full items-center",
          value ? "text-gray-900" : "text-gray-500",
        )}>
        <span className="flex-1 text-left sm:text-sm">
          {value ? value : placeholder}
        </span>
        <DsIcon icon="ChevronDown" width={20} />
      </button>
    );
  },
);

CustomInput.displayName = "CustomInput";

export { CustomInput };
