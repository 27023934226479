import { useQuery } from "@tanstack/react-query";

import { JobApi } from "../api/JobApi";

export const JobQueryKeys = {
  all: ["jobs"] as const,
  details: (jobId: string) => [...JobQueryKeys.all, jobId] as const,
};

export const useJobQuery = ({ jobId }: { jobId: string }) => {
  return useQuery({
    queryKey: JobQueryKeys.details(jobId),
    queryFn: () => JobApi.getJob(jobId),
  });
};
