import { doc, getDoc, getDocs, query, where } from "firebase/firestore";

import { DriversFiltersType } from "@/src/domain/user";
import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const UserApi = {
  listDrivers: async (filters?: DriversFiltersType) => {
    try {
      const usersCollection = SpikeSosDb.users;

      const constraints = [where("role_driver", "==", true)];

      if (filters?.driver_type) {
        constraints.push(where("driver_type", "in", filters.driver_type));
      }

      const driversQuery = query(usersCollection, ...constraints);

      // Execute the query and return the results
      const querySnapshot = await getDocs(driversQuery);
      const drivers = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      return drivers;
    } catch (error) {
      logger.error("Error fetching drivers", error);

      throw error;
    }
  },
  getUser: async (userId: string) => {
    try {
      const userRef = doc(SpikeSosDb.users, userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        return { id: userDoc.id, ...userData };
      }
    } catch (error) {
      logger.error("Error fetching user", error);

      throw error;
    }
  },
};
