import { useQuery } from "@tanstack/react-query";

import { DriverTypeEnum } from "@/domain/models/common";

import { UserApi } from "../api/UserApi";

export const UserQueryKeys = {
  all: ["users"] as const,
  details: (userId: string) => [...UserQueryKeys.all, userId] as const,
  drivers: () => [...UserQueryKeys.all, "drivers"] as const,
  binDrivers: () => [...UserQueryKeys.drivers(), "binDrivers"] as const,
  junkDrivers: () => [...UserQueryKeys.drivers(), "junkDrivers"] as const,
  binAndJunkDrivers: () =>
    [...UserQueryKeys.drivers(), "binAndJunkDrivers"] as const,
};

export const useBinDriversListQuery = () => {
  return useQuery({
    queryKey: UserQueryKeys.binDrivers(),
    queryFn: () => UserApi.listDrivers({ driver_type: [DriverTypeEnum.BIN] }),
  });
};

export const useJunkDriversListQuery = () => {
  return useQuery({
    queryKey: UserQueryKeys.junkDrivers(),
    queryFn: () => UserApi.listDrivers({ driver_type: [DriverTypeEnum.JUNK] }),
  });
};

export const useBinAndJunkDriversListQuery = () => {
  return useQuery({
    queryKey: UserQueryKeys.binAndJunkDrivers(),
    queryFn: () => UserApi.listDrivers({ driver_type: [DriverTypeEnum.BOTH] }),
  });
};

export const useUserQuery = ({
  userId,
  enabled,
}: {
  userId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: UserQueryKeys.details(userId),
    queryFn: () => UserApi.getUser(userId),
    enabled,
  });
};
