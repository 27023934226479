import { FC } from "react";

import { CleanupTypeEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";
import { useJobQuery } from "@/src/data/queries/jobQueries";
import { useServiceQuery } from "@/src/data/queries/serviceQueries";
import { classNames } from "@/src/utils/classNames";
import { formatBinSize } from "@/src/utils/strings";

import { DsText } from "./DsText";

type DsSchedulerNotesPropsType = {
  jobId: string;
  serviceId: string;
  taskId: string;
};

const FIVE_YARD = "5 YARD";
const SIX_YARD = "6 YARD";
const TEN_YARD = "10 YARD";
const FOURTEEN_YARD = "14 YARD";
const TWENTY_YARD = "20 YARD";
const YARD = "YARD";
const MORNING = "MORNING";
const EVENING = "EVENING";
const AFTERNOON = "AFTERNOON";
const FLEXIBLE = "FLEXIBLE";
const IDTBC = "ID-TBC";

const DsSchedulerNotes: FC<DsSchedulerNotesPropsType> = ({
  jobId,
  serviceId,
}) => {
  const { t } = useTranslator();

  // Get task's parent service data
  const { data: serviceData } = useServiceQuery({ jobId, serviceId });

  // Get job details
  const { data: jobData } = useJobQuery({ jobId });

  const schedulePreference = serviceData?.schedulePreference ?? "";
  const taskNotes = serviceData?.taskNotes ?? "";
  const size =
    serviceData?.connectedTask?.assignedBin?.size ?? serviceData?.size ?? "";
  const jobCleanupType =
    jobData?.cleanupType && jobData.cleanupType !== CleanupTypeEnum.NONE
      ? jobData?.cleanupType
      : "";

  const sizeDescription = size ? formatBinSize(size) : "";
  const taskNotesDescription = taskNotes ? ` - ${taskNotes}` : "";
  const jobCleanupTypeDescription = jobCleanupType
    ? ` - ${jobCleanupType}`
    : "";

  const schedulerNotes = `${sizeDescription} ${schedulePreference}${taskNotesDescription} ${jobCleanupTypeDescription}`;

  const treatMobileSchedulerNotes = () => {
    if (schedulerNotes === "") {
      return [];
    }

    let finalSchedulerNotes: string[] = [];

    let schedulerNotesUpperCased = schedulerNotes.toUpperCase();

    const pushAndReplace = (toPush: string, toReplace: string) => {
      if (schedulerNotesUpperCased.includes(toReplace)) {
        finalSchedulerNotes.push(toPush);

        schedulerNotesUpperCased = schedulerNotesUpperCased.replace(
          toReplace,
          "",
        );
      }
    };

    pushAndReplace(t("scheduler_notes_component.5_yard_short"), FIVE_YARD);
    pushAndReplace(t("scheduler_notes_component.6_yard_short"), SIX_YARD);
    pushAndReplace(t("scheduler_notes_component.10_yard_short"), TEN_YARD);
    pushAndReplace(t("scheduler_notes_component.14_yard_short"), FOURTEEN_YARD);
    pushAndReplace(t("scheduler_notes_component.20_yard_short"), TWENTY_YARD);
    pushAndReplace(t("scheduler_notes_component.yard_short"), YARD);
    pushAndReplace(t("scheduler_notes_component.morning_short"), MORNING);
    pushAndReplace(t("scheduler_notes_component.evening_short"), EVENING);
    pushAndReplace(t("scheduler_notes_component.afternoon_short"), AFTERNOON);
    pushAndReplace("", FLEXIBLE);
    pushAndReplace(t("scheduler_notes_component.id_tbc_short"), IDTBC);
    pushAndReplace(
      t("scheduler_notes_component.rough_sweep_short"),
      CleanupTypeEnum.ROUGHSWEEP.toUpperCase(),
    );
    pushAndReplace(
      t("scheduler_notes_component.fine_sweep_short"),
      CleanupTypeEnum.FINESWEEP.toUpperCase(),
    );
    pushAndReplace(
      t("scheduler_notes_component.full_detail_short"),
      CleanupTypeEnum.FULLDETAIL.toUpperCase(),
    );

    // Finally, removes spaces and dashes from schedulerNotesUpperCased
    schedulerNotesUpperCased = schedulerNotesUpperCased.replace(/-| /g, "");

    // If there's still characters in schedulerNotesUpperCased, it means that there's some custom text
    if (schedulerNotesUpperCased.length) {
      finalSchedulerNotes.push(t("scheduler_notes_component.custom_short"));
    }

    // Removes any empty strings from the array
    finalSchedulerNotes = finalSchedulerNotes.filter(Boolean);

    return finalSchedulerNotes;
  };

  const mobileSchedulerNotes = treatMobileSchedulerNotes();

  const fontBaseClassName = "font-bold text-xs text-center";

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {mobileSchedulerNotes.map(note => (
          <DsText
            key={note}
            className={classNames("flex lg:hidden", fontBaseClassName)}>
            {note}
          </DsText>
        ))}
      </div>
      <DsText className={classNames("hidden lg:flex", fontBaseClassName)}>
        {schedulerNotes.toUpperCase()}
      </DsText>
    </>
  );
};

export { DsSchedulerNotes };
