import { FC } from "react";

import { RightPanel } from "../components/RightPanel";
import { useJobQuery } from "../data/queries/jobQueries";
import { useServiceQuery } from "../data/queries/serviceQueries";
import { useTaskQuery } from "../data/queries/taskQueries";
import { ServiceDetails } from "./ServiceDetails";

type ServiceDetailDrawerPropsType = {
  jobId: string;
  serviceId: string;
  taskId: string;
  onCloseDrawer: () => void;
  isOpen: boolean;
};

const ServiceDetailsDrawer: FC<ServiceDetailDrawerPropsType> = ({
  jobId,
  serviceId,
  onCloseDrawer,
  taskId,
  isOpen,
}) => {
  const { data: jobData, isLoading: isLoadingJob } = useJobQuery({
    jobId,
  });

  const { data: serviceData, isLoading: isLoadingService } = useServiceQuery({
    jobId,
    serviceId,
  });

  const { data: taskData, isLoading: isLoadingTask } = useTaskQuery({
    jobId,
    serviceId,
    taskId,
  });

  return (
    <RightPanel
      title={serviceData?.customer.name ?? ""}
      subtitle={{
        i18nKey: "job_detail_drawer.subtitle",
        values: { taskType: taskId?.toUpperCase() },
      }}
      controlledOpen={isOpen}
      content={
        serviceData &&
        taskData &&
        jobData && (
          <ServiceDetails
            job={jobData}
            service={serviceData}
            task={taskData}
            onDrawerClosed={onCloseDrawer}
          />
        )
      }
      isLoading={isLoadingService ?? isLoadingTask ?? isLoadingJob}
      onDrawerClosed={onCloseDrawer}
    />
  );
};

export { ServiceDetailsDrawer };
