export const sortArrayByCustomOrder = <T extends Record<string, unknown>>({
  array,
  key,
  customOrder,
}: {
  array: T[];
  key: keyof T;
  customOrder: string[];
}) => {
  return array.sort((a, b) => {
    const aIndex = customOrder.indexOf(a[key] as string);
    const bIndex = customOrder.indexOf(b[key] as string);

    return aIndex - bIndex;
  });
};
