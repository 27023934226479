import { FC } from "react";
import { upperCase } from "lodash";

import { ServiceTaskTypeEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";
import { classNames } from "@/src/utils/classNames";

import { DsText } from "./DsText";

type DsTaskTypePropsType = {
  taskType: ServiceTaskTypeEnum;
};

const DsTaskTypeBadge: FC<DsTaskTypePropsType> = ({ taskType }) => {
  const { t } = useTranslator();

  const getTaskTypeParams = (taskType: ServiceTaskTypeEnum) => {
    switch (taskType) {
      case ServiceTaskTypeEnum.WAITANDLOADBIN:
        return {
          shortLabel: t("task_type_badge.wait_and_load_short"),
          bgClassName: "bg-[#2B69FD]",
        };

      case ServiceTaskTypeEnum.PICKUPBIN:
        return {
          shortLabel: t("task_type_badge.pickup_bin_short"),
          bgClassName: "bg-[#E60058]",
        };

      case ServiceTaskTypeEnum.JUNKREMOVAL:
        return {
          shortLabel: t("task_type_badge.junk_removal_short"),
          bgClassName: "bg-[#008564]",
        };

      case ServiceTaskTypeEnum.EXCHANGEBIN:
        return {
          shortLabel: t("task_type_badge.exchange_bin_short"),
          bgClassName: "bg-[#6300A8]",
        };

      case ServiceTaskTypeEnum.DROPBIN:
        return {
          shortLabel: t("task_type_badge.drop_bin_short"),
          bgClassName: "bg-[#BD5100]",
        };

      case ServiceTaskTypeEnum.DUMPANDRETURNBIN:
        return {
          shortLabel: t("task_type_badge.dump_and_return_bin_short"),
          bgClassName: "bg-[#B80299]",
        };
    }
  };

  const { shortLabel, bgClassName } = getTaskTypeParams(taskType) ?? {};

  const fontBaseClassName = "text-white font-bold text-xs";

  return (
    <div className={classNames("rounded-lg py-1 px-1.5", bgClassName)}>
      <DsText className={classNames("flex lg:hidden", fontBaseClassName)}>
        {shortLabel}
      </DsText>
      <DsText className={classNames("hidden lg:flex", fontBaseClassName)}>
        {upperCase(taskType)}
      </DsText>
    </div>
  );
};

export { DsTaskTypeBadge };
