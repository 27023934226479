import { FC } from "react";
import { t } from "i18next";
import { Control, Controller } from "react-hook-form";

import {
  BinTaskEnum,
  CleanupTypeEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";

import { DsButton, DsDivider, DsText, DsToggleGroup } from "../components/ui";
import { JobDetailFormType } from "../domain/job";
import { getBinTaskOptions } from "../utils/bin";
import { getCleanupTypeOptions } from "../utils/job";
import { getServiceTypeOptions } from "../utils/service";

type JobTypeSectionFormPropsType = {
  control: Control<JobDetailFormType>;
  selectedServiceType?: ServiceTypeEnum;
  selectedTaskType?: BinTaskEnum | null;
  selectedCleanupType?: CleanupTypeEnum | null;
  isPending?: boolean;
};

const JobTypeSectionForm: FC<JobTypeSectionFormPropsType> = ({
  control,
  selectedServiceType,
  selectedTaskType,
  selectedCleanupType,
  isPending,
}) => {
  return (
    <div className="space-y-4 mb-20 mt-2">
      <DsText variant="button">{t("job_detail_drawer.change_job_type")}</DsText>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <DsToggleGroup
            ref={field.ref}
            options={getServiceTypeOptions()}
            value={field.value}
            onChange={e => {
              field.onChange(e);
            }}
          />
        )}
      />
      {selectedServiceType === ServiceTypeEnum.BIN && (
        <>
          <DsDivider />
          <DsText variant="button">
            {t("job_detail_drawer.select_a_task_type")}
          </DsText>
          <Controller
            name="binTask"
            control={control}
            render={({ field }) => (
              <DsToggleGroup
                ref={field.ref}
                options={getBinTaskOptions()}
                value={field.value}
                onChange={e => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </>
      )}
      {selectedServiceType === ServiceTypeEnum.JUNK && (
        <>
          <DsDivider />
          <DsText variant="button">
            {t("job_detail_drawer.select_a_cleanup_type")}
          </DsText>
          <Controller
            name="cleanupType"
            control={control}
            render={({ field }) => (
              <DsToggleGroup
                ref={field.ref}
                options={getCleanupTypeOptions()}
                value={field.value}
                onChange={e => field.onChange(e)}
              />
            )}
          />
        </>
      )}
      {(selectedTaskType ?? selectedCleanupType) && (
        <DsButton type="submit" isLoading={isPending}>
          {t("job_detail_drawer.update_job")}
        </DsButton>
      )}
    </div>
  );
};

export { JobTypeSectionForm };
