import {
  BinTaskEnum,
  CleanupTypeEnum,
  SchedulePreferenceEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";
import { i18n } from "@/i18n";

import { MATERIAL_LIST } from "../domain/constants";
import { JobDetailFormType } from "../domain/job";
import { formatCleanupType } from "./strings";

export const getCleanupTypeOptions = () => [
  {
    value: CleanupTypeEnum.NONE,
    label: formatCleanupType(CleanupTypeEnum.NONE),
  },
  {
    value: CleanupTypeEnum.ROUGHSWEEP,
    label: formatCleanupType(CleanupTypeEnum.ROUGHSWEEP),
  },
  {
    value: CleanupTypeEnum.FINESWEEP,
    label: formatCleanupType(CleanupTypeEnum.FINESWEEP),
  },
  {
    value: CleanupTypeEnum.FULLDETAIL,
    label: formatCleanupType(CleanupTypeEnum.FULLDETAIL),
  },
];

export const getSchedulingPreferenceOptions = () =>
  Object.values(SchedulePreferenceEnum).map(value => ({
    label: i18n.t(`schedule_preference.${value}`),
    value,
  }));

export const handleParsingJob = (jobParams: JobDetailFormType) => {
  let parsedData = {};
  switch (jobParams.type) {
    case ServiceTypeEnum.BIN: {
      const {
        cleanupType,
        size,
        connectedTask,
        material,
        yardAmount,
        ...rest
      } = jobParams;
      const aggregate =
        material && yardAmount
          ? {
              ...MATERIAL_LIST.find(item => item.material === material),
              yardAmount,
            }
          : null;
      switch (jobParams.binTask) {
        case BinTaskEnum.DROPOFF: {
          parsedData = {
            ...rest,
            aggregate,
            size,
          };
          break;
        }
        case BinTaskEnum.DUMPANDRETURN: {
          parsedData = {
            ...rest,
            connectedTask,
          };
          break;
        }
        case BinTaskEnum.EXCHANGE: {
          parsedData = {
            ...rest,
            size,
            connectedTask,
            aggregate,
          };
          break;
        }
        case BinTaskEnum.PICKUP: {
          parsedData = {
            ...rest,
            connectedTask,
          };
          break;
        }
        case BinTaskEnum.WAITANDLOAD: {
          parsedData = {
            ...rest,
            size,
            aggregate,
          };
          break;
        }
        default:
          break;
      }
      break;
    }
    case ServiceTypeEnum.JUNK: {
      const { material, yardAmount, connectedTask, size, binTask, ...rest } =
        jobParams;
      parsedData = rest;
      break;
    }

    default:
      break;
  }
  return parsedData;
};
