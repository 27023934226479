import { FC } from "react";
import { useAtomValue } from "jotai";

import { DriverTypeEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";

import { DsSelectBoxItem } from "../components/ui";
import {
  useBinTasksCountQuery,
  useJunkTasksCountQuery,
} from "../data/queries/taskQueries";
import { useUserQuery } from "../data/queries/userQueries";
import {
  adminDashboardSelectedDateAtom,
  adminDashboardSelectedTaskViewAtom,
} from "../stores/atoms";
import { dateAsDayjs } from "../utils/date";

type DashboardAssignedDriverSelectBoxItemPropsType = {
  driverId: string;
};

const DashboardAssignedDriverSelectBoxItem: FC<
  DashboardAssignedDriverSelectBoxItemPropsType
> = ({ driverId }) => {
  const selectedDate = useAtomValue(adminDashboardSelectedDateAtom);
  const selectedTaskView = useAtomValue(adminDashboardSelectedTaskViewAtom);

  const { t } = useTranslator();

  const { data: driverData } = useUserQuery({
    userId: driverId,
    enabled: Boolean(driverId),
  });

  const driverType = driverData?.driver_type;

  const isBothDriver = driverType === DriverTypeEnum.BOTH;
  const isBinDriver = driverType === DriverTypeEnum.BIN || isBothDriver;
  const isJunkDriver = driverType === DriverTypeEnum.JUNK || isBothDriver;

  const shouldLoadBinsTasks =
    isBinDriver && (selectedTaskView === "bins" || selectedTaskView === "all");
  const shouldLoadJunkTasks =
    isJunkDriver && (selectedTaskView === "junk" || selectedTaskView === "all");

  const { data: binDriverTasksCount } = useBinTasksCountQuery({
    filters: {
      dueAtStart: dateAsDayjs(selectedDate).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(selectedDate).endOf("day").toDate(),
      completed: false,
      assignedTo: driverId,
    },
    enabled: shouldLoadBinsTasks,
  });

  const { data: junkDriverTasksCount } = useJunkTasksCountQuery({
    filters: {
      dueAtStart: dateAsDayjs(selectedDate).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(selectedDate).endOf("day").toDate(),
      completed: false,
      assignedTo: driverId,
    },
    enabled: shouldLoadJunkTasks,
  });

  const secondaryTextBinJobs = `${shouldLoadBinsTasks ? t("dashboard_assigned_driver_select_box_item.bin_jobs", { count: binDriverTasksCount }) : ""}`;
  const secondaryTextJunkJobs = `${shouldLoadJunkTasks ? t("dashboard_assigned_driver_select_box_item.junk_jobs", { count: junkDriverTasksCount }) : ""}`;

  const secondaryText = `${secondaryTextBinJobs}${secondaryTextJunkJobs}`;

  if (!driverId) {
    return (
      <DsSelectBoxItem
        label={t("dashboard_assigned_driver_select_box_item.unassigned")}
        value=""
      />
    );
  }

  return (
    <DsSelectBoxItem
      label={driverData?.displayName ?? ""}
      value={driverId}
      labelSecondaryText={secondaryText}
    />
  );
};

export { DashboardAssignedDriverSelectBoxItem };
