import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { classNames } from "@/src/utils/classNames";

const loadingVariants = cva(
  "animate-spin rounded-full h-12 w-12 border-t-2 border-b-2",
  {
    variants: {
      size: {
        small: "h-5 w-5",
        default: "h-12 w-12",
        large: "h-24 w-24",
      },
      color: {
        primary: "border-sos-yellow",
        secondary: "border-gray-700",
      },
    },
    defaultVariants: {
      size: "default",
      color: "primary",
    },
  },
);

export type DsLoadingPropsType = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof loadingVariants>;

const DsLoading = React.forwardRef<HTMLButtonElement, DsLoadingPropsType>(
  ({ className, color, size }) => (
    <div className={classNames(loadingVariants({ size, color, className }))} />
  ),
);
DsLoading.displayName = "DsLoading";

export { DsLoading };
