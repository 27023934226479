import { FieldValue } from "firebase/firestore";

export type FirebaseErrorType = Error & {
  code: string;
};

export type FirebaseDateType = FieldValue & {
  toDate: () => Date;
};

export enum ResourceEnum {
  USERS = "users",
  STATS = "stats",
  SITES = "sites",
  JOBS = "jobs",
  EVENTS = "events",
  CUSTOMERS = "customers",
  CONFIGURATIONS = "configurations",
  BINS = "bins",
  ACTIVITY = "activity",
  ACCOUNT_REQUESTS = "account-requests",
  SERVICES = "services",
  TASKS = "tasks",
  NOTES = "notes",
}

export type FirestoreDataType<T> = Omit<T, "id">;

export type FirebaseDocType = {
  id: string;
};
