import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";
import { classNames } from "@/src/utils/classNames";
import { filterObjectData } from "@/src/utils/search";

import { DsIcon } from "./DsIcon";

type DsSearchBarBaseType = {
  placeHolder?: I18nKeyType;
  className?: string;
};

type DsSearchBarWithDataType<T extends object> = DsSearchBarBaseType & {
  data: T[];
  handleOnSearch: (result: T[]) => void;
  returnAs: "filteredData";
};

type DsSearchBarWithoutDataType = DsSearchBarBaseType & {
  data?: never;
  handleOnSearch: (result: string) => void;
  returnAs: "string";
};

type DsSearchBarType<T extends object> =
  | DsSearchBarWithDataType<T>
  | DsSearchBarWithoutDataType;

const DsSearchBar = <T extends object>({
  data,
  handleOnSearch,
  placeHolder = "search_bar.search",
  returnAs = "filteredData",
  className,
}: DsSearchBarType<T>) => {
  const { t } = useTranslator();

  const onSearch = (text: string) => {
    if (returnAs === "string") {
      (handleOnSearch as (result: string) => void)(text);
      return;
    }

    if (!text) {
      (handleOnSearch as (result: T[]) => void)(data ?? []);
      return;
    }

    const filteredData = filterObjectData(data ?? [], text);

    (handleOnSearch as (result: T[]) => void)(filteredData ?? []);
  };

  return (
    <div
      className={classNames(
        "flex flex-row border bg-white border-gray-300 rounded-lg p-2 text-base md:w-2/3 w-full items-center",
        className,
      )}>
      <DsIcon icon="Search" className="top-0 left-0" height={18} width={18} />
      <input
        placeholder={placeHolder ? (t(placeHolder) as string) : ""}
        className="ml-2 w-full bg-transparent focus:outline-none"
        onChange={e => onSearch(e.target.value)}
      />
    </div>
  );
};

export { DsSearchBar };
