import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { classNames } from "@/src/utils/classNames";

import { DsButton } from ".";

export type DsTogglePropsType = {
  isChecked?: boolean;
  isDisabled?: boolean;
  onToggle?: (checked: boolean) => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof toggleVariants>;

const toggleVariants = cva(
  "inline-flex items-center justify-center rounded-md text-base font-bold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-sos-yellow/80 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed whitespace-nowrap",
  {
    variants: {
      variant: {
        primary: "bg-sos-yellow text-black hover:bg-sos-yellow/90",
        secondary: "bg-gray-200 text-black hover:bg-gray-200/80",
        outlined:
          "bg-white text-sos-red border data-[active=true]:bg-sos-yellow data-[active=true]:text-black",
        text: "bg-white data-[active=true]:bg-sos-yellow data-[active=true]:text-black",
      },
      size: {
        md: "h-10 px-4 py-2",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

const DsToggle = React.forwardRef<HTMLButtonElement, DsTogglePropsType>(
  (
    {
      className,
      variant,
      size,
      isChecked = false,
      isDisabled,
      onToggle,
      ...props
    },
    ref,
  ) => {
    const handleClick = () => {
      if (!isDisabled && onToggle) {
        onToggle(!isChecked);
      }
    };

    return (
      <DsButton
        type="button"
        className={classNames(
          toggleVariants({
            variant,
            size,
            className,
          }),
          {
            "cursor-not-allowed": isDisabled,
          },
        )}
        ref={ref}
        disabled={isDisabled}
        onClick={handleClick}
        data-active={isChecked}
        {...props}
      />
    );
  },
);

DsToggle.displayName = "DsToggle";

export { DsToggle };
