/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react'
import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  MenuIcon,
  TruckIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";

import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import GpsIcon from "../../icons/gps";
import logoblack from "../../images/SOS_Black.png";
import logowhite from "../../images/SOS_White.png";
import { classNames } from "../../utils/classNames";
import VersionCheck from "../VersionCheck";

import { BinsPageHeaderButton, DashboardPageHeaderButton } from '../../interactors'
import { DsIcon } from "../ui";

export const SPACED_WRAPPER_ID = 'spaced_wrapper';

const navigation = [
  {
    name: "Dashboard",
    href: "/admin/dashboard/",
    icon: HomeIcon,
    pageTitle: "Dashboard",
    headerRight: () => <DashboardPageHeaderButton />
  },
  {
    name: "Jobs",
    href: "/admin/jobs/",
    icon: UsersIcon,
    pageTitle: "Current Jobs",
    headerRight: null
  },
  {
    name: "Sites",
    href: "/admin/sites/",
    icon: GpsIcon,
    pageTitle: "Customers",
    headerRight: null
  },
  {
    name: "Bins",
    href: "/admin/bins/",
    icon: TruckIcon,
    pageTitle: "Bins",
    headerRight: () => <BinsPageHeaderButton />
  },
  {
    name: "Customers",
    href: "/admin/customers/",
    icon: FolderIcon,
    pageTitle: "Customers",
    headerRight: null
  },
  {
    name: "Settings",
    href: "/admin/settings/",
    icon: CalendarIcon,
    pageTitle: "Settings",
    headerRight: null
  },
  {
    name: "Accounts",
    href: "/admin/accounts/",
    icon: UsersIcon,
    pageTitle: "Accounts",
    headerRight: null
  },
];

const MenuItem = ({ item }) => {
  const location = useLocation();

  const isSelected = item.href === location.pathname;

  return (
    <Link
      to={item.href}
      className={classNames(
        isSelected
          ? "bg-yellow-500 text-black"
          : "text-black hover:bg-yellow-600 hover:bg-opacity-75",
        "group flex items-center px-2 py-2 text-base font-medium rounded-md",
      )}>
      <item.icon
        className="mr-4 flex-shrink-0 h-6 w-6 text-yellow-700"
        aria-hidden="true"
      />
      {item.name}
    </Link>
  );
};

const HeaderRight = () => {
  const location = useLocation();

  const HeaderRightComponent = navigation.find(
    item => item.href === location.pathname
  )?.headerRight;

  if (!HeaderRightComponent) {
    return null;
  }

  return <HeaderRightComponent />;
}

export default function AdminLayoutWrapper({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  const title =
    navigation.find(item => item.href === location.pathname)?.pageTitle ?? "";

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: "admin",
        }}
      />
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#ffe502] px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <Link
                    to="/driver/dashboard"
                    className="flex-shrink-0 flex items-center">
                    <img
                      className="h-8 w-auto mx-auto dark:hidden"
                      src={logoblack}
                      alt="Spike SOS"
                    />
                    <img
                      className="h-8 w-auto mx-auto hidden dark:block"
                      src={logowhite}
                      alt="Spike SOS"
                    />
                    <p className="font-black uppercase ml-2">Admin</p>
                  </Link>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map(item => (
                          <MenuItem key={item.name} item={item} />
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
                <VersionCheck />
              </div>
            </DialogPanel>
          </div>
        </Dialog>



        {/* Static sidebar for desktop */}
        <div className="z-50 hidden md:flex md:w-48 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-[#ffe502]">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Link
                  to="/app/launch"
                  className="flex-shrink-0 flex items-center">
                  <img
                    className="h-8 w-auto mx-auto dark:hidden"
                    src={logoblack}
                    alt="Spike SOS"
                  />
                  <img
                    className="h-8 w-auto mx-auto hidden dark:block"
                    src={logowhite}
                    alt="Spike SOS"
                  />
                </Link>
                <Link to="/admin/dashboard">
                  <p className="font-black uppercase ml-2">Admin</p>
                </Link>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {navigation.map(item => (
                  <MenuItem key={item.name} item={item} />
                ))}
              </nav>
              <VersionCheck />
            </div>
            {/* <div className="flex-shrink-0 flex border-t border-yellow-500 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-black">Tom Cook</p>
                    <p className="text-xs font-medium text-yellow-200 group-hover:text-black">
                      View profile
                    </p>
                  </div>
                </div>
              </a>
            </div> */}
          </div>
        </div>
        <div className="md:pl-48 flex flex-col flex-1">
          {/* Menu button */}
          <div className="flex">
            <div className="absolute top-3.5 z-20 md:hidden flex px-4 sm:px-6 md:px-8 bg-gray-100 hover:cursor-pointer">
              <DsIcon className="h-5 w-5" icon={'Menu'} onClick={() => setSidebarOpen(true)}  />
            </div>

            {/* Page title */}
            <div className="absolute z-10 px-4 sm:px-6 md:px-8 top-2 w-full flex items-center justify-center md:items-start md:justify-start md:mt-10 md:relative">
              <h1 className="text-lg font-bold text-gray-900">{title}</h1>
            </div>

            {/* Right buttons */}
            <div className="absolute z-20 md:right-4 right-2 flex-row flex px-4 sm:px-6 md:px-8 top-3.5 md:top-10 gap-4">
              <HeaderRight />
            </div>
          </div>

          {/* Page content */}
          <main className="flex-1">
            <div className="py-6">
              <div className="mx-auto px-4 sm:px-6 md:px-8" id={SPACED_WRAPPER_ID}>
                <div className="py-8 md:py-0">{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
