import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { classNames } from "@/src/utils/classNames";

const textVariants = cva("text-black", {
  variants: {
    variant: {
      body: "text-sm font-normal", //14px - fontWeight: 400
      tableColumnHeader: "text-sm font-medium", //14px - fontWeight: 500
      pageTitle: "text-lg font-bold", //18px - fontWeight: 700
      button: "text-base font-bold", //16px - fontWeight: 700
      drawerTitle: "text-lg font-medium", //18px - fontWeight: 500
      dataDisplayTitle: "text-sm font-medium text-[#6B7280]", //14px - fontWeight: 500
      dataDisplayValue: "text-base font-medium", //16px - fontWeight: 500
      cardTitle: "text-base font-semibold", //16px - fontWeight: 600
      badgeText: "text-sm font-semibold", //14px - fontWeight: 600
      errorText: "text-sm text-[#ba2727] mt-1", //14px
    },
  },
  defaultVariants: {
    variant: "body",
  },
});

export type DsTextPropsType = {
  asChild?: boolean;
} & React.LabelHTMLAttributes<HTMLParagraphElement> &
  VariantProps<typeof textVariants>;

const DsText = React.forwardRef<HTMLParagraphElement, DsTextPropsType>(
  ({ className, variant, asChild = false, ...props }, ref) => (
    <p
      className={classNames(textVariants({ variant, className }))}
      ref={ref}
      {...props}
    />
  ),
);
DsText.displayName = "DsText";

export { DsText };
