export enum DriverTypeEnum {
  JUNK = "junk",
  BIN = "bin",
  BOTH = "both",
}

export enum BinStatusEnum {
  AVAILABLE = "available",
  ONSITE = "onsite",
  DELIVERING = "delivering",
  DUMPING = "dumping",
  DOWN = "down",
  ASSIGNED = "assigned",
}

export enum ServiceTypeEnum {
  JUNK = "junk",
  BIN = "bin",
}

export enum BinMaterialEnum {
  CLEAR_LIMESTONE = '3/4" Clear Limestone',
  LIMESTONE_CRUSH = '3/4" Limestone Crush',
  HPB_LIMESTONE = "HPB Limestone",
  LIMESTONE_SCREENING = "Limestone Screening",
  PEA_STONE_GRAVEL = '3/8" Pea Stone Gravel',
  RIVER_STONE_GRAVEL = "River Stone Gravel",
  BRICK_SAND = "Brick Sand",
  CONCRETE_SAND = "Concrete Sand",
  STOCK_PILE_SOIL = "Stock Pile Soil",
  SCREENED_TOPSOIL = "Screened Topsoil",
  TRIPLE_MIX_SOIL = "Triple Mix Soil",
  GROW_MAX_SOIL = "Grow Max Soil",
  SHREDDED_PINE_MULCH = "Shredded Pine Mulch",
  BLACK_BEAUTY_MULCH = "Black Beauty Mulch",
  CLEAN_CONCRETE = "Clean / Concrete",
  MIX_BRICK = "Mix / Brick",
  ASPHALT = "Asphalt",
}

// Bin size is represented in yards
export enum BinSizeEnum {
  FIVE = "5",
  SIX = "6",
  TEN = "10",
  FOURTEEN = "14",
  TWENTY = "20",
}

export enum JunkSizeEnum {
  ONE_THIRD = "1/3",
  TWO_THIRDS = "2/3",
  FULL = "Full",
  OVERFILLED = "Overfilled",
}

export enum JunkTypeEnum {
  NONE = "None",
  INTERIOR = "Interior",
  EXTERIOR = "Exterior",
  BOTH = "Both",
}

export enum BinTaskEnum {
  DROPOFF = "dropoff",
  PICKUP = "pickup",
  EXCHANGE = "exchange",
  DUMPANDRETURN = "dumpandreturn",
  WAITANDLOAD = "waitandload",
}

export enum SchedulePreferenceEnum {
  MORNING = "morning",
  AFTERNOON = "afternoon",
  FLEXIBLE = "flexible",
}

export enum CleanupTypeEnum {
  NONE = "none",
  FINESWEEP = "finesweep",
  FULLDETAIL = "fulldetail",
  ROUGHSWEEP = "roughsweep",
}

export enum ServiceTaskTypeEnum {
  JUNKREMOVAL = "junkremoval",
  DROPBIN = "dropbin",
  PICKUPBIN = "pickupbin",
  EXCHANGEBIN = "exchangebin",
  DUMPANDRETURNBIN = "dumpandreturnbin",
  WAITANDLOADBIN = "waitandloadbin",
}

// The CreatedBy object should be the User's collection reference and not an object with the user's details
export type CreatedByType = {
  displayName: string | null;
  email: string | null;
  uid: string; // User's collection id
};

// The Customer object should be the Customer's collection reference and not an object with the customer's details
export type CustomerType = {
  address?: string;
  email: string;
  id: string; // This is the same id as customer's id in customers collection
  name: string;
  companyName?: string | null;
  phone?: string | number;
  contacts?: ContactType[];
  customerId?: string;
  customerPhoto?: string;
};

export type ContactType = {
  email: string;
  name: string;
  phone: string | number;
  key?: number;
};
