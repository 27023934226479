import { useCallback, useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";

import { DsIconButton } from "../components/ui";
import { adminDashboardSelectedDateAtom } from "../stores/atoms";
import { dateAsDayjs, getDayjsRange } from "../utils/date";
import { DashboardWeekSelectorDateCard } from "./DashboardWeekSelectorDateCard";

const CARD_WIDTH = 72;
const GAP_WIDTH = 60;

const DashboardWeekSelector = () => {
  const [selectedDate, setSelectedDate] = useAtom(
    adminDashboardSelectedDateAtom,
  );

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [weekSelected, setWeekSelected] = useState(0);

  const getWeekDates = useCallback(() => {
    const today = dateAsDayjs();

    const firstDateOfTheWeek = today.add(weekSelected, "week").startOf("week");
    const lastDateOfTheWeek = today.add(weekSelected, "week").endOf("week");

    const weekDates = getDayjsRange({
      start: firstDateOfTheWeek.toISOString(),
      end: lastDateOfTheWeek.toISOString(),
      unit: "day",
    });

    return weekDates;
  }, [weekSelected]);

  const weekDates = getWeekDates();

  useEffect(() => {
    if (scrollRef.current) {
      // Get the current day
      const currentDay = dateAsDayjs();

      // Find the index of the current day in the weekDates array
      const currentIndex = weekDates.findIndex(date =>
        date.isSame(currentDay, "day"),
      );

      if (currentIndex !== -1) {
        // Get the device's screen size
        const screenWidth = window.innerWidth;

        // Calculate the scroll position dynamically based on the index
        const scrollPosition =
          currentIndex * (CARD_WIDTH + GAP_WIDTH) -
          (screenWidth / 2 - CARD_WIDTH / 2);

        // Set the scroll position to show the current day
        scrollRef.current.scrollLeft = scrollPosition;
      }
    }
  }, [weekDates]);

  return (
    <div
      ref={scrollRef}
      className="px-4 sm:px-6 md:px-8 flex gap-4 items-center w-full max-w-6xl my-6 overflow-x-scroll">
      <DsIconButton
        icon="ChevronLeft"
        onClick={() => setWeekSelected(prev => prev - 1)}
        variant="custom"
      />
      <ul className="flex gap-1 flex-grow py-1">
        {weekDates.map((date, index) => (
          <li key={index} className="flex-grow">
            <DashboardWeekSelectorDateCard
              date={date}
              onDateSelect={setSelectedDate}
              isSelected={date.isSame(selectedDate, "day")}
            />
          </li>
        ))}
      </ul>
      <DsIconButton
        icon="ChevronRight"
        onClick={() => setWeekSelected(prev => prev + 1)}
        variant="custom"
      />
    </div>
  );
};

export { DashboardWeekSelector };
