import { FC } from "react";

import { useTranslator } from "@/i18n/useTranslator";
import { DsText } from "@/src/components/ui";
import { classNames } from "@/src/utils/classNames";
import {
  dateAsDayjs,
  DateType,
  formatCalendarDate,
  formatCalendarMonthAndDate,
  formatCalendarWeekDay,
} from "@/src/utils/date";

import {
  useBinTasksCountQuery,
  useJunkTasksCountQuery,
} from "../data/queries/taskQueries";

type DashboardWeekSelectorDateCardPropsType = {
  date: DateType;
  onDateSelect: (date: DateType) => void;
  isSelected?: boolean;
};

const DashboardWeekSelectorDateCard: FC<
  DashboardWeekSelectorDateCardPropsType
> = ({ date, onDateSelect, isSelected }) => {
  const { t } = useTranslator();

  const isTodaysCard = dateAsDayjs(date).isSame(dateAsDayjs(), "day");

  const { data: binsTasksCount } = useBinTasksCountQuery({
    filters: {
      dueAtStart: dateAsDayjs(date).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(date).endOf("day").toDate(),
      completed: false,
    },
  });

  const { data: junkTasksCount } = useJunkTasksCountQuery({
    filters: {
      dueAtStart: dateAsDayjs(date).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(date).endOf("day").toDate(),
      completed: false,
    },
  });

  const textColorClassName =
    !isSelected && isTodaysCard ? "text-[#BD5100]" : "";

  return (
    <div
      onClick={() => onDateSelect(date)}
      className={classNames(
        "flex-grow text-center items-center h-full rounded-2xl w-[73px] md:w-32 p-2 border border-gray-300 flex flex-col justify-center px-2 hover:cursor-pointer bg-white",
        isTodaysCard && isSelected && "bg-sos-yellow border-sos-yellow",
        isSelected && !isTodaysCard && "bg-gray-200 ring-gray-300 ring-2",
      )}>
      <DsText
        variant="dataDisplayValue"
        className={classNames(textColorClassName, "px-0 md:px-0")}>
        <p className="justify-center hidden md:flex">
          {formatCalendarDate(date)}
        </p>
        <p className="justify-center flex md:hidden">
          {formatCalendarWeekDay(date)}
        </p>
        <p className="justify-center flex md:hidden">
          {formatCalendarMonthAndDate(date)}
        </p>
      </DsText>
      <DsText
        className={
          textColorClassName
        }>{`${binsTasksCount ?? 0} ${t("bin")}`}</DsText>
      <DsText
        className={
          textColorClassName
        }>{`${junkTasksCount ?? 0} ${t("junk")}`}</DsText>
    </div>
  );
};

export { DashboardWeekSelectorDateCard };
