import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";

import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";
import { classNames } from "@/src/utils/classNames";
import { logger } from "@/src/utils/logger";
import { errorToast, successToast } from "@/src/utils/toast";

import { DsCheckbox, DsConfirmationModal } from ".";
import { DsIcon } from "./DsIcon";
import { DsTableColumnHead, DsTableColumnHeadType } from "./DsTableColumnHead";
import { DsTableData, DsTableDataType } from "./DsTableData";
import { DsTableSelectionHeader } from "./DsTableSelectionHeader";

type TExtendedType = {
  className?: string;
};

type DsDataColumnType<T> = DsTableDataType & {
  dataColumn: keyof T;
};

export type SortDirectionType = "asc" | "desc";

type DsTableBasePropsType<T> = {
  headerColumns: DsTableColumnHeadType[];
  dataColumns: DsDataColumnType<T>[];
  data: T[];
  className?: string;
  onRowClick?: (data: T) => void;
  onDragEnd?: (result: DropResult<string>, reorderedList: T[]) => void;
  defaultSortColumn?: keyof T;
  onTableSort?: (
    activeColumnSort: keyof T,
    direction: SortDirectionType,
  ) => void;
};

type DragAndDropEnabledType<T> = DsTableBasePropsType<T> & {
  hasDragAndDrop: boolean;
  droppableId: string;
};

type DragAndDropDisabledType<T> = DsTableBasePropsType<T> & {
  hasDragAndDrop?: false;
  droppableId?: never;
};

export type DsTableType<T> =
  | DragAndDropEnabledType<T>
  | DragAndDropDisabledType<T>;

type SelectionPropsType = {
  selectionActionLabel?: I18nKeyType;
  confirmationModalTitle: I18nKeyType;
  isConfirmButtonLoading?: boolean;
};

type SelectionEnabledType<T> = DsTableType<T> & {
  hasSelection: boolean;
  selectionProps?: SelectionPropsType;
  onSelectionConfirm: (
    selectedRows: string[],
  ) => Promise<PromiseSettledResult<void>[]> | void;
};

type SelectionDisabledType<T> = DsTableType<T> & {
  hasSelection?: false;
  onSelectionConfirm?: never;
  selectionProps?: never;
};

type ExtendedTablePropsType<T> =
  | SelectionEnabledType<T>
  | SelectionDisabledType<T>;

const DsTable = <T,>({
  headerColumns,
  dataColumns,
  data,
  className,
  onRowClick,
  hasDragAndDrop,
  droppableId,
  onDragEnd,
  defaultSortColumn,
  onTableSort,
  hasSelection,
  onSelectionConfirm,
  selectionProps,
}: ExtendedTablePropsType<T>) => {
  const { t } = useTranslator();

  const didDragAndDrop = useRef(false);
  const [orderedList, setOrderedList] = useState([] as T[]);
  const [isDragging, setIsDragging] = useState(false);
  const [columnActiveSort, setColumnActiveSort] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>("asc");

  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const getTClassName = (element: T & TExtendedType) => {
    return element.className;
  };

  useEffect(() => {
    // This prevents the reordering of the list when the data changes
    if (didDragAndDrop.current) {
      didDragAndDrop.current = false;
    } else {
      setOrderedList(data);
    }
  }, [data]);

  const reorder = <T,>({
    list,
    startIndex,
    endIndex,
  }: {
    list: T[];
    startIndex: number;
    endIndex: number;
  }): T[] => {
    didDragAndDrop.current = true;
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleOnDragEnd = (result: DropResult<string>) => {
    setIsDragging(false);

    if (!result.destination || !orderedList) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder({
      list: orderedList,
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });

    const reorderedList = items.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

    setOrderedList(reorderedList);
    onDragEnd?.(result, reorderedList);
  };

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const handleOnSortClick = (sortedColumnIndex: number) => {
    const columnId = dataColumns[sortedColumnIndex].dataColumn;

    const newSortDirection = sortDirection === "asc" ? "desc" : "asc";

    setColumnActiveSort(columnId);
    setSortDirection(newSortDirection);

    onTableSort?.(columnId, newSortDirection);
  };
  const handleSelection = ({
    checked,
    id,
  }: {
    checked: boolean | ChangeEvent<HTMLInputElement>;
    id: string;
  }) => {
    setSelectedRows(prev => {
      if (checked) {
        const newRows = [...prev];
        newRows.push(id);
        return newRows;
      } else {
        return prev.filter(item => item !== id);
      }
    });
  };
  const handleBatchProcess = async () => {
    try {
      await onSelectionConfirm?.(selectedRows);
      successToast({ text: t("toast.delete", { key: "Bins" }) });
    } catch (error) {
      errorToast({
        text: t("toast.error", { key: "delete", count: selectedRows.length }),
      });
      logger.error("Error on batch processing", error);
      throw error;
    } finally {
      setIsOpenModal(false);
      setIsSelecting(false);
      setSelectedRows([]);
    }
  };
  const visibleColumnsOnLastRow = dataColumns.filter(
    column => !column.shouldHideOnMobile,
  );

  return (
    <DragDropContext
      onBeforeDragStart={onBeforeDragStart}
      onDragEnd={handleOnDragEnd}>
      <div
        className={classNames("mx-0", { relative: hasSelection }, className)}>
        {hasSelection && (
          <DsTableSelectionHeader
            actionLabel={selectionProps?.selectionActionLabel}
            isSelecting={isSelecting}
            setIsSelecting={setIsSelecting}
            onCancel={() => {
              setIsSelecting(false);
              setSelectedRows([]);
            }}
            onConfirm={() => setIsOpenModal(true)}
            selectedRows={selectedRows}
          />
        )}
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {isSelecting && <DsTableColumnHead />}
              {headerColumns.map((column, index) => (
                <DsTableColumnHead
                  key={`table-column-${index}`}
                  onSortClick={
                    column.canSort ? () => handleOnSortClick(index) : () => null
                  }
                  isSortActive={
                    columnActiveSort === dataColumns[index].dataColumn
                  }
                  sortDirection={sortDirection}
                  {...column}
                />
              ))}

              {/* Renders an extra space at the end of the headers to show the drag and drop icon */}
              {hasDragAndDrop && (
                <DsTableColumnHead
                  className={classNames("hidden md:table-cell md:w-[65px]")}
                />
              )}
            </tr>
          </thead>

          {/* Empty state */}
          {data.length === 0 && (
            <tbody>
              <tr className="bg-white">
                <td
                  colSpan={headerColumns.length}
                  className="text-center py-4 text-sm text-gray-500 rounded-b-2xl">
                  {t("table_component.no_data")}
                </td>
              </tr>
            </tbody>
          )}

          <Droppable droppableId={droppableId ?? "unset"}>
            {provider => (
              <tbody
                ref={provider.innerRef}
                {...provider.droppableProps}
                className="divide-y divide-gray-300">
                {orderedList.map((element, elementIdx) => (
                  <Draggable
                    draggableId={elementIdx.toString()}
                    index={elementIdx}
                    key={elementIdx}
                    isDragDisabled={!hasDragAndDrop}>
                    {provider => (
                      <tr
                        {...provider.draggableProps}
                        {...provider.dragHandleProps}
                        ref={provider.innerRef}
                        key={`table-row-${elementIdx}`}
                        className={classNames(
                          "relative",
                          onRowClick && "hover:cursor-pointer group bg-white",
                          getTClassName(element as T & TExtendedType),
                        )}
                        onClick={() => onRowClick?.(element)}>
                        {isSelecting && (
                          <DsTableData
                            shouldCentralizeContent
                            isFromLastRow={
                              elementIdx === orderedList.length - 1
                            }
                            isDragOccurring={isDragging}
                            className={classNames("px-3 py-3.5 md:table-cell")}
                            onClick={e => {
                              e.stopPropagation();
                              const isItemChecked =
                                selectedRows.findIndex(
                                  id => id === element.id,
                                ) !== -1;
                              handleSelection({
                                checked: !isItemChecked,
                                id: element.id as string,
                              });
                            }}>
                            <DsCheckbox
                              checked={
                                selectedRows.findIndex(
                                  id => id === element.id,
                                ) !== -1
                              }
                              onChange={checked =>
                                handleSelection({
                                  checked,
                                  id: element.id as string,
                                })
                              }
                              onClick={e => {
                                e.stopPropagation();
                              }}
                            />
                          </DsTableData>
                        )}
                        {dataColumns.map((column, columnIndex) => {
                          const isLastVisibleCell =
                            column ===
                            visibleColumnsOnLastRow[
                              visibleColumnsOnLastRow.length - 1
                            ];
                          return (
                            <DsTableData
                              key={`table-data-${elementIdx}-${columnIndex}`}
                              isDragOccurring={isDragging}
                              columnIndex={columnIndex}
                              isFromLastRow={
                                elementIdx === orderedList.length - 1
                              }
                              className={classNames(
                                "px-3 py-3.5 text-sm lg:table-cell",
                                column.className,
                              )}
                              shouldHideOnMobile={column.shouldHideOnMobile}
                              shouldCentralizeContent={
                                column.shouldCentralizeContent
                              }
                              isLastVisibleCell={
                                isLastVisibleCell &&
                                elementIdx === orderedList.length - 1
                              }>
                              {element[column.dataColumn] as ReactNode}
                            </DsTableData>
                          );
                        })}
                        {hasDragAndDrop && (
                          <DsTableData
                            shouldCentralizeContent
                            isFromLastRow={
                              elementIdx === orderedList.length - 1
                            }
                            isDragOccurring={isDragging}
                            className={classNames(
                              "px-3 py-3.5 text-sm hidden md:table-cell cursor-move md:w-[65px]",
                            )}>
                            <DsIcon icon="DragDrop" />
                          </DsTableData>
                        )}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </div>
      {hasSelection && (
        <DsConfirmationModal
          title={selectionProps?.confirmationModalTitle}
          titlei18nOptions={{ count: selectedRows.length }}
          isConfirmButtonLoading={selectionProps?.isConfirmButtonLoading}
          confirmButtonText="bins_page.delete_bin_confirm_message"
          confirmButtoni18nOptions={{ count: selectedRows.length }}
          isOpen={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          onConfirm={() => {
            void handleBatchProcess();
          }}
        />
      )}
    </DragDropContext>
  );
};

export { DsTable };
