import { FC, useEffect } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormSetValue,
} from "react-hook-form";

import { ServicesType } from "@/domain/models/job";
import { JobPanelDataField } from "@/src/blocks";

import { DsSelectBox, DsSelectBoxSelectedItem } from "../components/ui";
import { useUpdateConnectedTaskMutation } from "../data/mutations/taskMutations";
import { useActiveServicesQuery } from "../data/queries/serviceQueries";
import { JobDetailFormType } from "../domain/job";

type ConnectedTaskControllerPropsType = {
  control: Control<JobDetailFormType>;
  isChangingMode: boolean;
  taskId: string;
  service: ServicesType;
  setValue: UseFormSetValue<JobDetailFormType>;
};

const ConnectedTaskController: FC<ConnectedTaskControllerPropsType> = ({
  control,
  isChangingMode,
  taskId,
  service,
  setValue,
}) => {
  const { data: connectedTasks, isLoading: isLoadingConnectedTasks } =
    useActiveServicesQuery({
      filters: {
        type: service.type,
        customerId: service.customer.id,
        address: service.address,
      },
    });

  const { mutate: updateConnectedTask, isPending: isPendingConnectedTask } =
    useUpdateConnectedTaskMutation();

  useEffect(() => {
    setValue("connectedTask", service.connectedTask);
  }, [service, setValue]);

  const handleChangeConnectedTask = ({
    serviceId,
    field,
  }: {
    serviceId: string;
    field: ControllerRenderProps<JobDetailFormType, "connectedTask">;
  }) => {
    const connectedTask = connectedTasks?.find(
      service => service.id === serviceId,
    );
    if (isChangingMode) {
      field.onChange(connectedTask ?? null);
    } else {
      connectedTask &&
        updateConnectedTask({
          jobId: service.jobId,
          serviceId: service.id,
          taskId,
          connectedTask,
        });
    }
  };
  return (
    <JobPanelDataField
      title="job_detail_drawer.connected_bin_id"
      isLoading={isPendingConnectedTask}
      secondary={
        <Controller
          name="connectedTask"
          control={control}
          defaultValue={service.connectedTask}
          render={({ field, fieldState }) => (
            <DsSelectBox
              ref={field.ref}
              selectedValue={field.value?.id ?? ""}
              selectedItem={
                <DsSelectBoxSelectedItem
                  label={service.connectedTask?.assignedBin?.identifier ?? ""}
                />
              }
              error={fieldState.error?.message}
              isLoading={isLoadingConnectedTasks}
              onSelect={serviceId =>
                handleChangeConnectedTask({ serviceId, field })
              }
              items={connectedTasks?.map(service => ({
                label: service.assignedBin?.identifier ?? "",
                value: service.id,
              }))}
            />
          )}
        />
      }
    />
  );
};

export { ConnectedTaskController };
