/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import i18n, { ParseKeys } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import "intl-pluralrules";

import en from "./en.json";

export const resources = {
  en: {
    translation: en,
  },
} as const;

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export type I18nKeyType = ParseKeys;

export { i18n };
