import { useCallback } from "react";
import { useAtomValue } from "jotai";

import {
  useBinAndJunkDriversListQuery,
  useBinDriversListQuery,
  useJunkDriversListQuery,
} from "../data/queries/userQueries";
import { adminDashboardSelectedTaskViewAtom } from "../stores/atoms";

type DashboardDriverFilterType = {
  shouldListBinDrivers?: boolean;
  shouldListJunkDrivers?: boolean;
};

export const useDashboardDrivers = ({
  shouldListBinDrivers = true,
  shouldListJunkDrivers = true,
}: DashboardDriverFilterType = {}) => {
  const selectedTaskView = useAtomValue(adminDashboardSelectedTaskViewAtom);

  const { data: binDriversBase } = useBinDriversListQuery();
  const { data: junkDriversBase } = useJunkDriversListQuery();
  const { data: binAndJunkDrivers } = useBinAndJunkDriversListQuery();

  const getDrivers = useCallback(() => {
    const binDrivers = shouldListBinDrivers ? binDriversBase : [];
    const junkDrivers = shouldListJunkDrivers ? junkDriversBase : [];

    let drivers = [];

    switch (selectedTaskView) {
      case "bins":
        drivers = [...(binDrivers ?? []), ...(binAndJunkDrivers ?? [])];
        break;

      case "junk":
        drivers = [...(junkDrivers ?? []), ...(binAndJunkDrivers ?? [])];
        break;

      default:
        drivers = [
          ...(binDrivers ?? []),
          ...(junkDrivers ?? []),
          ...(binAndJunkDrivers ?? []),
        ];
    }

    return drivers;
  }, [
    selectedTaskView,
    binDriversBase,
    junkDriversBase,
    binAndJunkDrivers,
    shouldListBinDrivers,
    shouldListJunkDrivers,
  ]);

  return getDrivers() ?? [];
};
