import { addDoc, getDocs, query, where } from "firebase/firestore";

import { SiteFiltersType, SiteFormType } from "@/src/domain/site";
import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const SiteApi = {
  // Fetch all sites
  listSites: async (filters?: SiteFiltersType) => {
    try {
      const sitesCollection = SpikeSosDb.sites;

      const constraints = [];

      if (filters?.customerId) {
        constraints.push(where("customerId", "==", filters.customerId));
      }

      const siteQuery = query(sitesCollection, ...constraints);
      const querySnapshot = await getDocs(siteQuery);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      logger.error("Error fetching sites", error);

      throw error;
    }
  },
  addSite: async (siteData: SiteFormType) => {
    try {
      const sitesCollection = SpikeSosDb.sites;

      const siteSnapshot = await addDoc(sitesCollection, siteData);

      return siteSnapshot.id;
    } catch (error) {
      logger.error("Error adding site", error);

      throw error;
    }
  },
};
