import { FC } from "react";

import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";
import { classNames } from "@/src/utils/classNames";

import { DsIcon } from "./DsIcon";
import { SortDirectionType } from "./DsTable";
import { DsText } from "./DsText";

export type DsTableColumnHeadType = {
  title?: I18nKeyType;
  shouldHideOnMobile?: boolean;
  className?: string;
  isSortActive?: boolean;
  onSortClick?: () => void;
  sortDirection?: SortDirectionType;
  canSort?: boolean;
};

const DsTableColumnHead: FC<DsTableColumnHeadType> = ({
  title,
  shouldHideOnMobile,
  className,
  canSort,
  isSortActive,
  onSortClick,
  sortDirection,
}) => {
  const { t } = useTranslator();

  return (
    <th
      scope="col"
      onClick={e => {
        e.preventDefault();

        onSortClick?.();
      }}
      className={classNames(
        `px-3 py-3.5 text-left first:rounded-tl-2xl last:rounded-tr-2xl bg-white ${shouldHideOnMobile && "hidden md:table-cell"} ${className}`,
      )}>
      <a href="#" className="group inline-flex items-center">
        {title && <DsText variant="tableColumnHeader">{t(title)}</DsText>}
        {canSort && (
          <span
            className={classNames(
              "invisible ml-2 flex-none text-gray-900 group-hover:visible group-focus:visible",
              isSortActive && "visible",
            )}>
            <DsIcon
              icon={sortDirection === "asc" ? "ChevronUp" : "ChevronDown"}
            />
          </span>
        )}
      </a>
    </th>
  );
};

export { DsTableColumnHead };
