import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "@firebase/storage";
import React, { useRef, useState } from "react";
import Compress from "react-image-file-resizer";
import { useStorage } from "reactfire";

function UploadFile({ basePath, baseName, multiple, onUploadComplete }) {
  const storage = useStorage();
  const filesInputRef = useRef();
  const [files, setFiles] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  if (uploadedPhotos.length > 0 && uploadedPhotos.length === files.length) {
    filesInputRef.current.value = "";
    setFiles(() => []);
    setUploadedPhotos(() => []);
  }

  function handleSingleUpload() {
    uploadString(files[0].ref, files[0].src, "data_url")
      .then(async (snapshot) => {
        // // console.log("Uploaded a data_url string!", snapshot);
        onUploadComplete(snapshot.ref.fullPath);
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  function handleMultipleUpload() {
    files.forEach((file, index) => {
      uploadString(file.ref, file.src, "data_url")
        .then((snapshot) => {
          // // console.log("Uploaded a data_url string!", snapshot);
          setUploadedPhotos((prevState) => {
            const newPhotos = [...prevState, snapshot.ref.fullPath];
            onUploadComplete(newPhotos);
            return newPhotos;
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }
  function handleUpload(e) {
    e.preventDefault();
    multiple ? handleMultipleUpload() : handleSingleUpload();
  }
  function handleChange(e) {
    const selectedFiles = e.target.files;
    setFiles([]);
    for (let i = 0; i < selectedFiles.length; i++) {
      let file = selectedFiles.item(i);
      // console.log(file.name);
      Compress.imageFileResizer(
        file, // the file from input
        1920, // width
        1920, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        80, // quality
        0, // rotation
        (uri) => {
          // You upload logic goes here
          setFiles((oldFiles) => [
            ...oldFiles,
            {
              src: uri,
              ref: ref(
                storage,
                `${basePath}/${baseName}-${Date.now()}-${oldFiles.length}.jpg`
              ),
            },
          ]);
        },
        "base64" // blob or base64 default base64
      );
    }
  }
  return (
    <div>
      <div className="flex gap-4 mb-2">
        {files.map((file, index) => (
          <img
            className="w-24 h-24 object-cover rounded border"
            key={index}
            src={file.src}
          />
        ))}
      </div>
      <input
        ref={filesInputRef}
        className="block mb-4 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        multiple={multiple}
        type="file"
        name="file"
        accept="image/*"
        onChange={handleChange}
      />
      {files.length > 0 && (
        <button
          onClick={handleUpload}
          className="w-full shadow bg-[#ffe502] rounded py-2 text-gray-600 font-bold uppercase"
        >
          Upload Image{multiple ? "s" : ""}
        </button>
      )}
    </div>
  );
}

export default UploadFile;
