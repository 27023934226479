import { cva } from "class-variance-authority";
import { Slide, ToastContainer } from "react-toastify";

import { classNames } from "@/src/utils/classNames";

import { DsIcon } from ".";

const toastVariants = cva(
  "border-2 border-sos-green rounded-[16px] flex items-center p-5 md:w-[500px] mb-2 w-[92vw] mx-auto first:mt-8",
  {
    variants: {
      variant: {
        success: "bg-[#E5F3EF]",
        error: "bg-red-600",
        info: "bg-gray-600",
        warning: "bg-orange-400",
        default: "bg-[#E5F3EF]",
        dark: "bg-white-600 text-gray-300",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);
const DsToastContainer = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      icon={({ type }) =>
        type === "success" && (
          <DsIcon icon="CheckCircle" width="20px" height="20px" />
        )
      }
      toastClassName={context =>
        classNames(
          "relative flex min-h-10 justify-between overflow-hidden cursor-pointer",
          toastVariants({ variant: context?.type ?? "default" }),
        )
      }
      bodyClassName={context =>
        classNames(
          context?.defaultClassName,
          "font-semibold text-black flex-grow flex items-center",
        )
      }
      hideProgressBar
      newestOnTop
      closeOnClick
      pauseOnHover
      transition={Slide}
    />
  );
};

export { DsToastContainer };
