import { Link } from "gatsby";

import { CleanupTypeEnum, ServiceTaskTypeEnum } from "@/domain/models/common";
import { TasksType } from "@/domain/models/job";
import { useTranslator } from "@/i18n/useTranslator";

import { DsIcon, DsText, DsYellowBadge } from "../components/ui";
import { classNames } from "../utils/classNames";
import {
  formatBinSize,
  formatCleanupType,
  formatDriverTaskType,
} from "../utils/strings";

const DriverTaskCard = ({ task }: { task: TasksType }) => {
  const { t } = useTranslator();

  /* If Drop Off / Wait and Load / Exchange, show the size of the bin being dropped off (task.size), 
    if Pick Up / Dump and Return, show the size of the bin being picked up (task.connectedTask.size),
    unless unassigned, in which case show SIZE TBC */
  const binSize =
    task.taskId === ServiceTaskTypeEnum.DROPBIN ||
    task.taskId === ServiceTaskTypeEnum.EXCHANGEBIN ||
    task.taskId === ServiceTaskTypeEnum.WAITANDLOADBIN
      ? formatBinSize(task.size)
      : task.connectedTask
        ? formatBinSize(task.connectedTask?.size)
        : t("driver_task_card.size_tbc");

  return (
    <Link
      to={`/driver/jobs/${task.jobId}/services/${task.serviceId}/tasks/${task.taskId}`}
      onClick={e => {
        if (task.completed) {
          e.preventDefault();
        }
      }}
      className={classNames(
        "block hover:bg-gray-50 rounded-lg border border-gray-300 bg-white mt-1",
        task.completed &&
          "bg-[#DADADA] opacity-50 hover:cursor-default hover:bg-[#DADADA]",
      )}>
      <div className="px-4 py-4 sm:px-6 ">
        <div className="flex items-center justify-between">
          <div className="flex-shrink-0 flex">
            <DsText variant="cardTitle">
              {formatDriverTaskType(task.taskId)}
            </DsText>
            {task.taskId === ServiceTaskTypeEnum.JUNKREMOVAL &&
              task.cleanupType !== CleanupTypeEnum.NONE && (
                <>
                  <DsYellowBadge
                    text={formatCleanupType(
                      task.cleanupType ?? CleanupTypeEnum.NONE,
                    )}
                    className="ml-2"
                  />
                </>
              )}
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="w-full flex items center flex-col md:flex-row">
            {!task.cleanupType && (
              <p className="flex items-center text-sm text-gray-500 w-full md:w-1/5 lg:w-1/6 mb-2 md:mb-0">
                <DsIcon icon="Truck" className="mr-2" />
                {binSize}
              </p>
            )}

            <p className="flex items-center text-sm text-gray-500 w-full md:w-4/5 lg:w-5/6 ">
              <DsIcon icon="LocationMark" className="mr-2" />
              {task.address}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export { DriverTaskCard };
