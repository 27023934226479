import defaultTheme from "tailwindcss/defaultTheme";

const checkBreakPoint = (breakPoint: string) => {
  const isBreakPointMatching = window.matchMedia(
    `(min-width: ${breakPoint})`,
  ).matches;

  return isBreakPointMatching;
};

export const useThemeBreakPoints = () => {
  const smBreakPoint = defaultTheme.screens.sm;
  const mdBreakPoint = defaultTheme.screens.md;
  const lgBreakPoint = defaultTheme.screens.lg;

  return {
    isSmallScreen: checkBreakPoint(smBreakPoint),
    isMediumScreen: checkBreakPoint(mdBreakPoint),
    isLargeScreen: checkBreakPoint(lgBreakPoint),
  };
};
