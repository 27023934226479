import React from "react";
import { cva, VariantProps } from "class-variance-authority";

type TextAreaPropsType = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  VariantProps<typeof textAreaVariants>;

const textAreaVariants = cva(
  "resize-none rounded-md text-base w-full shadow-sm text-black",
  {
    variants: {
      variant: {
        primary: "border border-gray-300",
      },
      size: {
        md: "h-32 p-2",
        sm: "h-10 p-2",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

const DsTextArea = React.forwardRef<HTMLTextAreaElement, TextAreaPropsType>(
  ({ className, placeholder, variant, size, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        className={textAreaVariants({ variant, size, className })}
        placeholder={placeholder}
        {...props}
      />
    );
  },
);

DsTextArea.displayName = "DsTextArea";

export { DsTextArea };
