import { FC, useEffect } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormSetValue,
} from "react-hook-form";

import { BinSizeEnum } from "@/domain/models/common";
import { ServicesType } from "@/domain/models/job";
import { useTranslator } from "@/i18n/useTranslator";
import { JobPanelDataField } from "@/src/blocks";

import { DsSelectBox, DsSelectBoxSelectedItem } from "../components/ui";
import { useUpdateBinSizeMutation } from "../data/mutations/binMutations";
import { JobDetailFormType } from "../domain/job";
import { getBinSizeOptions } from "../utils/bin";

type BinSizeControllerPropsType = {
  control: Control<JobDetailFormType>;
  isChangingMode: boolean;
  taskId: string;
  service: ServicesType;
  setValue: UseFormSetValue<JobDetailFormType>;
};

const BinSizeController: FC<BinSizeControllerPropsType> = ({
  control,
  isChangingMode,
  taskId,
  service,
  setValue,
}) => {
  // Group of field mutations
  const { mutate: updateBinSize, isPending: isPendingBinsize } =
    useUpdateBinSizeMutation();

  const { t } = useTranslator();

  const binSizeOptions = getBinSizeOptions();

  useEffect(() => {
    setValue("size", service.size);
  }, [service, setValue]);

  const handleChangeBinSize = ({
    size,
    field,
  }: {
    size: BinSizeEnum;
    field: ControllerRenderProps<JobDetailFormType, "size">;
  }) => {
    if (isChangingMode) {
      field.onChange(size);
    } else {
      updateBinSize({
        jobId: service.jobId,
        serviceId: service.id,
        taskId,
        size,
      });
    }
  };
  return (
    <JobPanelDataField
      title="bins_page.size"
      secondary={
        <Controller
          name="size"
          control={control}
          defaultValue={service.size}
          rules={{
            required: t("new_bin_form.bin_size_required"),
          }}
          render={({ field, fieldState }) => (
            <DsSelectBox
              ref={field.ref}
              selectedValue={field.value}
              selectedItem={
                !field.value ? <DsSelectBoxSelectedItem label="" /> : undefined
              }
              error={fieldState.error?.message}
              isLoading={isPendingBinsize}
              onSelect={value =>
                handleChangeBinSize({ size: value as BinSizeEnum, field })
              }
              items={binSizeOptions}
            />
          )}
        />
      }
    />
  );
};

export { BinSizeController };
