import { useState } from "react";

import { useTranslator } from "@/i18n/useTranslator";
import { HeaderButton } from "@/src/blocks/HeaderButton";
import { RightPanel } from "@/src/components/RightPanel";
import { JobCreationForm } from "@/src/interactors";

const DashboardPageHeaderButton = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const { t } = useTranslator();

  return (
    <>
      <RightPanel
        controlledOpen={isPanelOpen}
        setControlledOpen={setIsPanelOpen}
        title={t("add_new_job_drawer.title")}
        content={<JobCreationForm onPanelClose={() => setIsPanelOpen(false)} />}
        onDrawerClosed={() => setIsPanelOpen(false)}
      />
      <HeaderButton
        buttons={[
          {
            i18nKey: "dashboard_page.add_job",
            handleOnClick: () => setIsPanelOpen(true),
            icon: "Plus",
          },
        ]}
      />
    </>
  );
};

export { DashboardPageHeaderButton };
