import { z } from "zod";

import {
  BinSizeEnum,
  CleanupTypeEnum,
  ServiceTaskTypeEnum,
} from "@/domain/models/common";
import { FirebaseDateType } from "@/domain/models/firebase";
import { JobConnectedTaskType } from "@/domain/models/job";

import { DateType } from "../utils/date";

export const taskFilterSchema = z.object({
  dueAtStart: z.date().optional(),
  dueAtEnd: z.date().optional(),
  completed: z.boolean().optional(),
  taskIds: z.array(z.nativeEnum(ServiceTaskTypeEnum)).optional(),
  assignedTo: z.string().optional(),
});

export type TaskFiltersType = z.infer<typeof taskFilterSchema>;

export type UpdateTaskBaseType = {
  jobId: string;
  serviceId: string;
  taskId: string;
};

export type AssignTaskToDriverParamsType = UpdateTaskBaseType & {
  assignedTo: string;
  selectedDate: DateType;
};

export type UpdateTaskDueAtParamsType = UpdateTaskBaseType & {
  dueAt: FirebaseDateType;
};

export type UpdateTaskCleanupParamsType = UpdateTaskBaseType & {
  cleanupType: CleanupTypeEnum;
};

export type GetTaskPropsType = {
  jobId: string;
  serviceId: string;
  taskId: string;
};

export const DASHBOARD_TASKS_ORDER = [
  ServiceTaskTypeEnum.DROPBIN,
  ServiceTaskTypeEnum.WAITANDLOADBIN,
  ServiceTaskTypeEnum.PICKUPBIN,
  ServiceTaskTypeEnum.EXCHANGEBIN,
  ServiceTaskTypeEnum.DUMPANDRETURNBIN,
  ServiceTaskTypeEnum.JUNKREMOVAL,
];

export type UpdateBinSizeParamsType = UpdateTaskBaseType & {
  size: BinSizeEnum;
};

export type UpdateConnectedTaskType = UpdateTaskBaseType & {
  connectedTask: JobConnectedTaskType;
};
