import { I18nKeyType } from "i18n";

import { useTranslate } from "@/i18n/useTranslate";
import { DsButton, DsIcon } from "@/src/components/ui";

import { IconType } from "../icons/icons";

type HeaderButtonType = {
  handleOnClick: () => void;
  i18nKey: I18nKeyType;
  i18nOptions?: object;
  icon: IconType;
};

const HeaderButton = ({ buttons }: { buttons: HeaderButtonType[] }) => {
  const t = useTranslate;

  return (
    <>
      {buttons.map((button, index) => {
        const { handleOnClick, i18nKey, i18nOptions, icon } = button;

        const title = t(i18nKey, i18nOptions);

        return (
          <div key={`header-button-${index}`}>
            <DsButton className="hidden md:flex" onClick={handleOnClick}>
              {title}
            </DsButton>

            <DsIcon
              onClick={handleOnClick}
              icon={icon}
              className="w-4 h-4 md:hidden hover:cursor-pointer"
            />
          </div>
        );
      })}
    </>
  );
};

export { HeaderButton };
