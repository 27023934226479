import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";

import { I18nKeyType } from "@/i18n";

import "react-datepicker/dist/react-datepicker.css";

import { useTranslate } from "@/i18n/useTranslate";

import { CustomInput } from "./CustomInput";

type DsDatePickerBaseType = {
  name?: string;
  value: Date | null;
  className?: string;
  onChange: (date: Date | null) => void;
  onBlur?: () => void;
  showTimeInput?: boolean;
  placeholder?: I18nKeyType;
};

const DsDatePicker = forwardRef<HTMLInputElement, DsDatePickerBaseType>(
  (
    { value, onChange, onBlur, name, className, placeholder, ...props },
    ref,
  ) => {
    const placeholderText = useTranslate(placeholder);
    return (
      <ReactDatePicker
        ref={ref}
        selected={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        className="w-full px-3 pr-1 py-2.5 text-base rounded-md focus:outline-none shadow-sm ring-1 ring-inset ring-gray-300"
        wrapperClassName="w-full"
        disabledKeyboardNavigation
        placeholderText={placeholderText}
        customInput={<CustomInput placeholder={placeholderText} />}
        {...props}
      />
    );
  },
);

DsDatePicker.displayName = "DsDatePicker";

export { DsDatePicker };
