import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { classNames } from "@/src/utils/classNames";

import { DsLoading } from "./DsLoading";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-lg text-base font-bold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-sos-yellow/80 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-[#C7C7C7] disabled:border disabled:border-sos-grey disabled:text-[#8D8D8D] disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        primary: "bg-sos-yellow text-black hover:bg-sos-yellow/90",
        secondary: "bg-gray-200 text-black hover:bg-gray-200/80",
        outline: "text-[#A16207] border border-gray-200",
        danger: "text-white bg-[#BA2727] hover:bg-sos-red",
        custom: "bg-white",
      },
      size: {
        primary: "h-10 px-4 py-2",
        custom: "",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "primary",
    },
  },
);

export type DsButtonPropsType = {
  asChild?: boolean;
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const DsButton = React.forwardRef<HTMLButtonElement, DsButtonPropsType>(
  (
    {
      className,
      variant,
      size,
      isLoading,
      asChild = false,
      children,
      ...props
    },
    ref,
  ) => (
    <button
      className={classNames(
        buttonVariants({
          variant,
          size,
          className,
        }),
        { "cursor-not-allowed": isLoading },
      )}
      ref={ref}
      disabled={isLoading}
      {...props}>
      <div className="flex items-center relative justify-center overflow-hidden">
        {isLoading && (
          <DsLoading color="secondary" className="mr-3" size="small" />
        )}
        {children}
      </div>
    </button>
  ),
);
DsButton.displayName = "DsButton";

export { DsButton };
