import {
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { FirestoreDataType } from "@/domain/models/firebase";
import { TasksType } from "@/domain/models/job";
import { TASK_TIME_DURATION } from "@/src/domain/constants";
import {
  AssignTaskToDriverParamsType,
  GetTaskPropsType,
  TaskFiltersType,
  UpdateConnectedTaskType,
  UpdateTaskBaseType,
  UpdateTaskCleanupParamsType,
  UpdateTaskDueAtParamsType,
} from "@/src/domain/task";
import { dateAsDayjs, generateFirebaseTimestamp } from "@/src/utils/date";
import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const TaskApi = {
  listTasks: async ({ filters }: { filters?: TaskFiltersType }) => {
    try {
      const tasksCollection = SpikeSosDb.tasks;

      const constraints = [];

      if (filters?.dueAtStart) {
        constraints.push(where("dueAt", ">=", filters.dueAtStart));
      }

      if (filters?.dueAtEnd) {
        constraints.push(where("dueAt", "<", filters.dueAtEnd));
      }

      if (filters?.completed !== undefined) {
        constraints.push(where("completed", "==", filters.completed));
      }

      if (filters?.taskIds) {
        constraints.push(where("taskId", "in", filters.taskIds));
      }

      if (filters?.assignedTo) {
        constraints.push(where("assignedTo", "==", filters.assignedTo));
      }

      const tasksQuery = query(tasksCollection, ...constraints);

      // Execute the query and return the results
      const querySnapshot = await getDocs(tasksQuery);
      const tasks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      return tasks;
    } catch (error) {
      logger.error("Error fetching tasks", error);

      throw error;
    }
  },
  countTasks: async (filters?: TaskFiltersType) => {
    try {
      const tasksCollection = SpikeSosDb.tasks;

      const constraints = [];

      if (filters?.dueAtStart) {
        constraints.push(where("dueAt", ">=", filters.dueAtStart));
      }

      if (filters?.dueAtEnd) {
        constraints.push(where("dueAt", "<", filters.dueAtEnd));
      }

      if (filters?.completed) {
        constraints.push(where("completed", "==", filters.completed));
      }

      if (filters?.taskIds) {
        constraints.push(where("taskId", "in", filters.taskIds));
      }

      if (filters?.assignedTo) {
        constraints.push(where("assignedTo", "==", filters.assignedTo));
      }

      const tasksQuery = query(tasksCollection, ...constraints);

      // Execute the query and return the count of results
      const querySnapshot = await getCountFromServer(tasksQuery);
      const tasksCount = querySnapshot.data().count;

      return tasksCount;
    } catch (error) {
      logger.error("Error fetching tasks count", error);

      throw error;
    }
  },
  assignTaskToDriver: async ({
    jobId,
    serviceId,
    taskId,
    assignedTo,
    selectedDate,
  }: AssignTaskToDriverParamsType) => {
    try {
      if (!jobId || !serviceId || !taskId) {
        throw new Error(
          "Missing required parameters to assign task from driver",
        );
      }

      // Default due at time is 6am
      let dueAtBase = dateAsDayjs(selectedDate).startOf("day").add(6, "hour");

      if (assignedTo) {
        // Get the driver's current list of tasks
        const tasks = await TaskApi.listTasks({
          filters: {
            assignedTo,
            dueAtStart: dateAsDayjs(selectedDate).startOf("day").toDate(),
            dueAtEnd: dateAsDayjs(selectedDate).endOf("day").toDate(),
          },
        });

        if (tasks.length) {
          // Get the latest task
          const latestTask = tasks.reduce((prev, current) =>
            prev.dueAt > current.dueAt ? prev : current,
          );

          if (latestTask.dueAt) {
            // Add 90 minutes to the latest task's due at time
            dueAtBase = dateAsDayjs(latestTask.dueAt.toDate()).add(
              TASK_TIME_DURATION,
              "minute",
            );
          }
        }
      }

      const dueAt = generateFirebaseTimestamp(dueAtBase.toDate());
      const end = generateFirebaseTimestamp(
        dateAsDayjs(dueAtBase.toDate())
          .add(TASK_TIME_DURATION, "minute")
          .toDate(),
      );

      const jobRef = doc(SpikeSosDb.jobs, jobId);
      const serviceRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const taskRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );

      await updateDoc(jobRef, { assignedTo, dueAt, end });
      await updateDoc(serviceRef, { assignedTo, dueAt, end });
      await updateDoc(taskRef, { assignedTo, dueAt, end });

      return { jobId, serviceId, taskId, assignedTo, selectedDate };
    } catch (error) {
      logger.error("Error assigning task", error);

      throw error;
    }
  },
  unassignTaskFromDriver: async ({
    jobId,
    serviceId,
    taskId,
  }: UpdateTaskBaseType) => {
    try {
      if (!jobId || !serviceId || !taskId) {
        throw new Error(
          "Missing required parameters to unassign task from driver",
        );
      }

      const jobRef = doc(SpikeSosDb.jobs, jobId);
      const serviceRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const taskRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );

      await updateDoc(jobRef, { assignedTo: "" });
      await updateDoc(serviceRef, { assignedTo: "" });
      await updateDoc(taskRef, { assignedTo: "" });

      return { jobId, serviceId, taskId };
    } catch (error) {
      logger.error("Error unassigning task", error);

      throw error;
    }
  },
  updateTaskDueAt: async ({
    jobId,
    serviceId,
    taskId,
    dueAt,
  }: UpdateTaskDueAtParamsType) => {
    try {
      if (!jobId || !serviceId || !taskId || !dueAt) {
        throw new Error("Missing required parameters");
      }

      const jobRef = doc(SpikeSosDb.jobs, jobId);
      const serviceRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const taskRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );

      const end = generateFirebaseTimestamp(
        dateAsDayjs(dueAt.toDate()).add(90, "minute").toDate(),
      );

      await updateDoc(jobRef, { dueAt, end });
      await updateDoc(serviceRef, { dueAt, end });
      await updateDoc(taskRef, { dueAt, end });

      return { jobId, serviceId, taskId, dueAt };
    } catch (error) {
      logger.error("Error updating task due at", error);

      throw error;
    }
  },
  updateTaskCleanupType: async ({
    jobId,
    serviceId,
    taskId,
    cleanupType,
  }: UpdateTaskCleanupParamsType) => {
    try {
      if (!jobId || !serviceId || !taskId || !cleanupType) {
        throw new Error("Missing required parameters");
      }
      const jobRef = doc(SpikeSosDb.jobs, jobId);

      const serviceRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const taskRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );
      await updateDoc(jobRef, { cleanupType });
      await updateDoc(serviceRef, { cleanupType });
      await updateDoc(taskRef, { cleanupType });

      return { jobId, serviceId, taskId, cleanupType };
    } catch (error) {
      logger.error("Error updating task due at", error);

      throw error;
    }
  },
  getTask: async ({ jobId, serviceId, taskId }: GetTaskPropsType) => {
    try {
      if (!jobId || !serviceId || !taskId) {
        throw new Error("Missing required parameters");
      }

      const docRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );
      const document = await getDoc(docRef);

      if (!document.exists()) {
        throw new Error("Task not found");
      }

      return {
        id: document.id,
        ...(document.data() as unknown as FirestoreDataType<TasksType>),
      };
    } catch (error) {
      logger.error("Error fetching task", error);

      throw error;
    }
  },
  updateConnectedTask: async ({
    jobId,
    serviceId,
    taskId,
    connectedTask,
  }: UpdateConnectedTaskType) => {
    try {
      const jobRef = doc(SpikeSosDb.jobs, jobId);
      const serviceRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const taskRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );

      await updateDoc(jobRef, { connectedTask });
      await updateDoc(taskRef, { connectedTask });
      await updateDoc(serviceRef, { connectedTask });

      return { jobId, serviceId, taskId, connectedTask };
    } catch (error) {
      logger.error("Error updating connected task", error);

      throw error;
    }
  },
};
