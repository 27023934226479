import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { addDoc, collection, DocumentData } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";

import { logger } from "@/src/utils/logger";

import { BinType } from "../../../domain/models/bin";
import { BinSizeEnum, BinStatusEnum } from "../../../domain/models/common";
import { useTranslator } from "../../../i18n/useTranslator";
import { BinQueryKeys } from "../../data/queries/binQueries";
import { classNames } from "../../utils/classNames";
import { DsButton } from "../ui";

type NewBinFormPropsType = {
  onSuccess: () => void;
};

type FormDataType = {
  identifier: string;
  size: BinSizeEnum;
};

export const NewBinForm = ({ onSuccess }: NewBinFormPropsType): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormDataType>();

  const firestore = useFirestore();
  const binRef = collection(firestore, "bins");

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successBin, setSuccessBin] = useState<string>("");
  const [isCreatingBin, setIsCreatingBin] = useState<boolean>(false);

  const { data: user } = useUser();
  const { data: bins } = useFirestoreCollectionData(binRef);

  const queryClient = useQueryClient();

  const { t } = useTranslator();

  const onSubmit = async (data: FormDataType): Promise<void> => {
    // Reset error messages
    setIsError(false);
    setIsSuccess(false);

    //Check if there is no inputted size
    if (!data.size) {
      setIsError(true);
      setErrorMessage(t("new_bin_form.bin_size_required"));
      return;
    }

    //Check if a bin with the entered ID exists
    const binChecker = (bins as DocumentData[] | undefined)?.find(
      (bin: DocumentData) => (bin as BinType).identifier === data.identifier,
    );

    if (binChecker) {
      setIsError(true);
      setErrorMessage(t("new_bin_form.bin_already_exists"));
      return;
    }

    const bin: Partial<BinType> = {
      createdBy: {
        displayName: user?.displayName ?? "",
        email: user?.email ?? "",
        uid: user?.uid ?? "",
      },
      currentData: null,
      currentJob: null,
      currentService: null,
      deleted: false,
      identifier: data.identifier,
      size: data.size,
      status: BinStatusEnum.AVAILABLE,
    };

    try {
      setIsCreatingBin(true);

      await addDoc(binRef, bin);
      reset();
      await queryClient.invalidateQueries({ queryKey: BinQueryKeys.all });
      setIsSuccess(true);
      setSuccessBin(data.identifier);
      setIsCreatingBin(false);
      onSuccess();
    } catch (err) {
      logger.error("Error creating bin", err);
      setIsCreatingBin(false);
      reset();
      setIsError(true);
    }
  };

  const size = watch("size");

  return (
    <>
      {(isSuccess || isError) && (
        <p
          className={`p-4 ${
            isSuccess
              ? "bg-green-200 text-green-500"
              : "bg-red-200 text-red-500"
          } rounded-lg my-4`}>
          {isSuccess
            ? t("new_bin_form.bin_created_successfully", { successBin })
            : t("new_bin_form.unable_to_create_bin", { errorMessage })}
        </p>
      )}

      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
        className="pb-4"
        autoComplete="off">
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t("new_bin_form.bin_identifier")}
            </label>
            <input
              className="border border-gray-300 rounded p-2 text-base block w-full"
              defaultValue=""
              {...register("identifier", { required: true })}
            />
            {errors.identifier && (
              <span className="text-red-500 text-xs italic">
                {t("new_bin_form.bin_identifier_required")}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t("new_bin_form.bin_size")}
            </label>
            <div className="grid grid-cols-3 mb-4">
              <button
                type="button"
                onClick={() => setValue("size", BinSizeEnum.FIVE)}
                className={classNames(
                  size === BinSizeEnum.FIVE
                    ? "bg-[#ffe502] border-yellow-400 text-gray-900"
                    : "bg-gray-200 border-gray-300 text-gray-400",
                  `border col-span-1 px-2 py-4 rounded-tl rounded-bl`,
                )}>
                5
              </button>
              <button
                type="button"
                onClick={() => setValue("size", BinSizeEnum.SIX)}
                className={classNames(
                  size === BinSizeEnum.SIX
                    ? "bg-[#ffe502] border-yellow-400 text-gray-900"
                    : "bg-gray-200 border-gray-300 text-gray-400",
                  `border-t border-b col-span-1 px-2 py-4`,
                )}>
                6
              </button>
              <button
                type="button"
                onClick={() => setValue("size", BinSizeEnum.TEN)}
                className={classNames(
                  size === BinSizeEnum.TEN
                    ? "bg-[#ffe502] border-yellow-400 text-gray-900"
                    : "bg-gray-200 border-gray-300 text-gray-400",
                  `border col-span-1 px-2 py-4 rounded-tr rounded-br`,
                )}>
                10
              </button>
              <button
                type="button"
                onClick={() => setValue("size", BinSizeEnum.FOURTEEN)}
                className={classNames(
                  size === BinSizeEnum.FOURTEEN
                    ? "bg-[#ffe502] border-yellow-400 text-gray-900"
                    : "bg-gray-200 border-gray-300 text-gray-400",
                  `border col-span-1 px-2 py-4 rounded-tr rounded-br`,
                )}>
                14
              </button>
              <button
                type="button"
                onClick={() => setValue("size", BinSizeEnum.TWENTY)}
                className={classNames(
                  size === BinSizeEnum.TWENTY
                    ? "bg-[#ffe502] border-yellow-400 text-gray-900"
                    : "bg-gray-200 border-gray-300 text-gray-400",
                  `border col-span-1 px-2 py-4 rounded-tr rounded-br`,
                )}>
                20
              </button>
            </div>
          </div>

          <DsButton type="submit" isLoading={isCreatingBin} className="w-full">
            {t("new_bin_form.add_bin")}
          </DsButton>
        </>
      </form>
    </>
  );
};
