import { getDocs, query, where } from "firebase/firestore";

import { GetTaskNotesPropsType } from "@/src/domain/notes";
import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const NoteApi = {
  listTaskNotes: async ({
    jobId,
    serviceId,
    taskId,
  }: GetTaskNotesPropsType) => {
    try {
      const notesCollection = SpikeSosDb.notes;
      const notesQuery = query(
        notesCollection,
        where("jobId", "==", jobId),
        where("serviceId", "==", serviceId),
        where("taskId", "in", ["", taskId]),
      );

      const notesSnapshot = await getDocs(notesQuery);

      const notes = notesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      return notes;
    } catch (error) {
      logger.error("Error fetching task notes", error);

      throw error;
    }
  },
};
