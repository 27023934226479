import { useState } from "react";

import { classNames } from "@/src/utils/classNames";

import { DsButton } from "../DsButton";
import { DsText } from "../DsText";

type DsSegmentedControlType<T> = {
  segments: {
    value: T;
    label: string;
  }[];
  onSelect: (value: T, index: number) => void;
  defaultIndex?: number;
  className?: string;
};

const DsSegmentedControl = <T,>({
  segments,
  onSelect,
  defaultIndex = 0,
  className,
}: DsSegmentedControlType<T>) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);

  const onInputChange = (value: T, index: number) => {
    setActiveIndex(index);
    onSelect(value, index);
  };

  return (
    <div
      className={classNames(
        "inline-flex h-10 w-full md:w-1/2 md:max-w-80 items-baseline justify-start rounded-lg border border-gray-300 bg-white sm:w-auto",
        className,
      )}>
      {segments?.map((item, i) => (
        <DsButton
          onClick={() => onInputChange(item.value, i)}
          key={`segmented-control-${i}-${item.label}`}
          className={classNames(
            "group inline-flex items-center h-full flex-1 justify-center whitespace-nowrap transition-all duration-300 ease-in-out disabled:cursor-not-allowed gap-1.5",
            i === activeIndex
              ? "rounded-md bg-sos-yellow shadow"
              : "bg-transparent hover:bg-transparent",
          )}>
          <DsText variant="button">{item.label}</DsText>
        </DsButton>
      ))}
    </div>
  );
};

export { DsSegmentedControl };
