import React, { FC } from "react";

import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";

type DsDataDisplayPropsType = {
  title: I18nKeyType;
  data: string | React.ReactElement | undefined;
};

const DsDataDisplay: FC<DsDataDisplayPropsType> = ({ title, data }) => {
  const { t } = useTranslator();

  return (
    <div className="items-center px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-[#DADADA]">
      <dt className="text-sm font-medium leading-5 text-gray-500">
        {t(title)}
      </dt>
      <dd className="mt-1 text-base font-medium leading-5 text-black sm:col-span-2 sm:mt-0">
        {data ?? "--"}
      </dd>
    </div>
  );
};

export { DsDataDisplay };
