import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useStorage, useUser } from "reactfire";

import { JobType } from "@/domain/models/job";
import { JobApi } from "@/src/data/api/JobApi";
import { JobQueryKeys } from "@/src/data/queries/jobQueries";
import { JobFormSubmissionType, UpdateJobPropsType } from "@/src/domain/job";
import { GetTaskPropsType } from "@/src/domain/task";

import { ServiceQueryKeys } from "../queries/serviceQueries";
import { TaskQueryKeys } from "../queries/taskQueries";

export const useCreateJob = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const storage = useStorage();
  const mutation = useMutation({
    mutationFn: (jobData: JobFormSubmissionType) =>
      JobApi.createJob({
        ...jobData,
        user,
        storage,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: JobQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};

export const useDeleteJob = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<GetTaskPropsType, unknown, GetTaskPropsType>({
    mutationFn: params => JobApi.deleteJob(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: JobQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: ServiceQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};

export const useUpdateJobMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<UpdateJobPropsType, unknown, UpdateJobPropsType>(
    {
      mutationFn: params => JobApi.updateJob(params),
      onSuccess: updatedjob => {
        const { jobId } = updatedjob;
        if (!jobId) {
          return;
        }
        queryClient.setQueryData(
          JobQueryKeys.details(jobId),
          (oldData: JobType | undefined) => {
            if (!oldData) {
              return oldData;
            }

            return {
              ...oldData,
              ...updatedjob,
            };
          },
        );
      },
    },
  );

  return mutation;
};

export const useUpdateJobTypeMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<UpdateJobPropsType, unknown, UpdateJobPropsType>(
    {
      mutationFn: params => JobApi.updateJobType(params),
      onSuccess: async updatedjob => {
        const { jobId, serviceId, taskId } = updatedjob;
        if (!jobId) return;
        await queryClient.invalidateQueries({
          queryKey: JobQueryKeys.details(jobId),
        });
        if (!serviceId) return;
        await queryClient.invalidateQueries({
          queryKey: ServiceQueryKeys.details({ jobId, serviceId }),
        });

        if (!taskId) return;
        await queryClient.invalidateQueries({
          queryKey: TaskQueryKeys.all,
        });
      },
    },
  );

  return mutation;
};
