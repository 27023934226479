import { FC } from "react";
import { Control, Controller, UseFormTrigger } from "react-hook-form";

import { BinMaterialEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";

import { JobPanelDataField } from "../blocks";
import { DsInput, DsSelectBox, DsText } from "../components/ui";
import { useUpdateJobMutation } from "../data/mutations/jobMutations";
import { MATERIAL_LIST } from "../domain/constants";
import { JobDetailFormType } from "../domain/job";
import { getBinMaterialOptions } from "../utils/bin";

type BinSizeControllerPropsType = {
  control: Control<JobDetailFormType>;
  trigger: UseFormTrigger<JobDetailFormType>;
  jobId: string;
  isChangingMode: boolean;
  material?: BinMaterialEnum;
  yardAmount?: number;
};

const AggregateController: FC<BinSizeControllerPropsType> = ({
  control,
  trigger,
  jobId,
  isChangingMode,
  material,
  yardAmount,
}) => {
  const { t } = useTranslator();
  const { mutate: updateJob, isPending } = useUpdateJobMutation();

  const selectedAggregate =
    material && yardAmount
      ? {
          ...MATERIAL_LIST.find(item => item.material === material)!,
          yardAmount,
        }
      : null;

  const handleUpdateAggregate = async (onClose?: () => void) => {
    const isValid = await trigger(["material", "yardAmount"]);
    if (!isValid) return;
    if (isChangingMode) {
      onClose?.();
    } else {
      updateJob(
        {
          jobId,
          aggregate: selectedAggregate,
        },
        {
          onSuccess: () => {
            onClose?.();
          },
        },
      );
    }
  };
  return (
    <JobPanelDataField
      title="add_new_job_drawer.aggregate"
      editContainerClassName="flex-col relative items-stretch"
      confirmButtonClassName="absolute right-0 top-[-28px] px-0 py-0 h-auto"
      secondary={
        <DsText variant="button">
          {selectedAggregate
            ? t("job_detail_drawer.aggregate_value_string", {
                ...selectedAggregate,
                count: yardAmount,
              })
            : t("none")}
        </DsText>
      }
      isEditable
      isLoading={isPending}
      editField={
        <>
          <Controller
            name="material"
            defaultValue={material}
            control={control}
            rules={{
              validate: (value, formValues) => {
                if (!value && formValues.yardAmount)
                  return t("add_new_job_drawer.rules.material_required");
                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <DsSelectBox
                ref={field.ref}
                selectedValue={field.value ?? ""}
                onSelect={e => {
                  field.onChange(e);
                  void trigger(["material", "yardAmount"]);
                }}
                items={[...getBinMaterialOptions(true)]}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="yardAmount"
            defaultValue={yardAmount}
            control={control}
            rules={{
              validate: (value, formValues) => {
                if (!value && formValues.material)
                  return t(
                    "add_new_job_drawer.rules.aggregate_amount_required",
                  );
                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <DsInput
                value={field.value ?? ""}
                type="number"
                placeholder="add_new_job_drawer.placeholder.amount_in_cubic_yards"
                onChange={e => {
                  field.onChange(e.target.valueAsNumber);
                  void trigger(["material", "yardAmount"]);
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </>
      }
      handleOnConfirm={callback => {
        void handleUpdateAggregate(callback);
      }}
    />
  );
};

export { AggregateController };
