import { FC, SVGProps } from "react";

import { iconRegistry, IconType } from "@/src/icons/icons";
import { classNames } from "@/src/utils/classNames";

type DsIconPropsType = SVGProps<SVGSVGElement> & {
  icon: IconType;
};

export const DsIcon: FC<DsIconPropsType> = ({ icon, ...rest }) => {
  const IconComponent = iconRegistry[icon];

  const isClickable = Boolean(rest.onClick);

  return (
    <IconComponent
      className={classNames(isClickable && "cursor-pointer")}
      {...rest}
    />
  );
};
