import { FC, useState } from "react";
import { useAtomValue } from "jotai";
import { Controller, useForm } from "react-hook-form";

import {
  BinTaskEnum,
  ContactType,
  SchedulePreferenceEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";
import { JobType, ServicesType, TasksType } from "@/domain/models/job";
import { useTranslator } from "@/i18n/useTranslator";
import { JobPanelDataField } from "@/src/blocks";
//@ts-expect-error Will be fixed slowly when migrating to TypeScript
import TaskNotes from "@/src/components/task/Notes";

import { AssignDriverComponent } from ".";
import { RightPanel } from "../components/RightPanel";
import {
  DsButton,
  DsConfirmationModal,
  DsDatePicker,
  DsSelectBox,
  DsText,
  DsTextArea,
  DsToggleGroup,
} from "../components/ui";
import {
  useDeleteJob,
  useUpdateJobTypeMutation,
} from "../data/mutations/jobMutations";
import { useUpdateServiceMutation } from "../data/mutations/serviceMutations";
import {
  useAssignTaskToDriverMutation,
  useUnassignTaskFromDriverMutation,
  useUpdateCleanupTypeMutation,
  useUpdateTaskDueAtMutation,
} from "../data/mutations/taskMutations";
import { GOOGLE_MAP_BASE_URL } from "../domain/constants";
import { CleanupTypeEnumType, JobDetailFormType } from "../domain/job";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { adminDashboardSelectedDateAtom } from "../stores/atoms";
import { generateFirebaseTimestamp } from "../utils/date";
import {
  getCleanupTypeOptions,
  getSchedulingPreferenceOptions,
  handleParsingJob,
} from "../utils/job";
import { successToast } from "../utils/toast";
import { AggregateController } from "./AggregateController";
import { BinSizeController } from "./BinSizeController";
import { ConnectedTaskController } from "./ConnectedTaskController";
import { JobTypeSectionForm } from "./JobTypeSectionForm";

type JobDetailsPropsType = {
  job: JobType;
  service: ServicesType;
  task: TasksType;
  onDrawerClosed: () => void;
};

const ServiceDetails: FC<JobDetailsPropsType> = ({
  job,
  service,
  task,
  onDrawerClosed,
}) => {
  const { data: currentUser } = useCurrentUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isChangingJobType, setIsChangingJobType] = useState(false);
  const selectedDate = useAtomValue(adminDashboardSelectedDateAtom);
  const { control, watch, trigger, handleSubmit, setValue } =
    useForm<JobDetailFormType>({
      defaultValues: {
        type: service.type,
        binTask: service.binTask,
        cleanupType: job.cleanupType,
        material: job.aggregate?.material,
        yardAmount: job.aggregate?.yardAmount,
      },
    });
  // Group of field mutations
  const {
    mutate: updateService,
    isPending: isPendingService,
    variables: serviceVariables,
  } = useUpdateServiceMutation();
  const { mutate: updateTaskDueAt, isPending: isPendingDueAt } =
    useUpdateTaskDueAtMutation();
  const { mutate: updateCleanupType, isPending: isPendingCleanupType } =
    useUpdateCleanupTypeMutation();
  const {
    mutate: assignTaskToDriver,
    isPending: isAssigningTaskToDriver,
    variables: assignTaskToDriverVariables,
  } = useAssignTaskToDriverMutation();
  const {
    mutate: unassignTaskFromDriver,
    isPending: isUnassigningTaskFromDriver,
    variables: unassignTaskFromDriverVariables,
  } = useUnassignTaskFromDriverMutation();
  const { mutate: cancelJob, isPending: isCancelling } = useDeleteJob();
  const { mutate: updateJobType, isPending: isPendingJob } =
    useUpdateJobTypeMutation();

  const onSelectDriver = ({
    taskData,
    driverId,
  }: {
    taskData: TasksType;
    driverId: string;
  }) => {
    if (driverId) {
      assignTaskToDriver({
        ...taskData,
        assignedTo: driverId,
        selectedDate,
      });
    } else {
      unassignTaskFromDriver({
        ...taskData,
      });
    }
  };

  const { t } = useTranslator();

  const cleanupTypeOptions = getCleanupTypeOptions();
  const schedulingPreferenceOptions = getSchedulingPreferenceOptions();

  const handleUpdateService = ({
    serviceParams,
    onClose,
  }: {
    serviceParams: Partial<ServicesType>;
    onClose?: () => void;
  }) => {
    updateService(
      {
        jobId: service.jobId,
        serviceId: service.id,
        ...serviceParams,
      },
      {
        onSettled: () => {
          onClose?.();
        },
      },
    );
  };

  const canDeleteJob =
    currentUser?.role_superadmin ||
    (currentUser?.role_manager && !service.completed && !service.started);

  const selectedContactEmail = watch("contact");
  const selectedJobType = watch("type");
  const selectedBinTask = watch("binTask");
  const selectedCleanupType = watch("cleanupType");
  const selectedMaterial = watch("material");
  const yardAmountInput = watch("yardAmount");

  const onSubmit = (data: JobDetailFormType) => {
    const parsedJobParams = handleParsingJob(data);
    updateJobType(
      {
        jobId: service.jobId,
        serviceId: service.id,
        taskId: task.taskId,
        ...parsedJobParams,
      },
      {
        onSettled: () => {
          setIsChangingJobType(false);
        },
        onSuccess: () => {
          onDrawerClosed();
          successToast({ text: t("toast.update", { key: "Job" }) });
        },
      },
    );
  };

  const siteContact = (
    Array.isArray(job.site?.contact) ? job.site.contact : [job.site?.contact]
  ) as ContactType[];

  const contactOptions = siteContact.map((item: ContactType | undefined) => ({
    label: item?.name ?? "",
    value: item?.email ?? "",
  }));

  return (
    <form
      onSubmit={e => {
        void handleSubmit(onSubmit)(e);
      }}>
      <JobPanelDataField
        title="job_detail_drawer.date"
        secondary={task?.dueAt?.toDate()?.toLocaleDateString()}
        isEditable
        isLoading={isPendingDueAt}
        editField={
          <Controller
            name="dueAt"
            defaultValue={service.dueAt?.toDate()}
            control={control}
            render={({ field }) => (
              <DsDatePicker
                value={field.value}
                onChange={e => field.onChange(e)}
              />
            )}
          />
        }
        handleOnConfirm={callback => {
          updateTaskDueAt(
            {
              jobId: service.jobId,
              serviceId: service.id,
              taskId: task.taskId,
              dueAt: generateFirebaseTimestamp(watch("dueAt")),
            },
            {
              onSuccess: () => {
                unassignTaskFromDriver({
                  taskId: task.taskId,
                  serviceId: service.id,
                  jobId: service.jobId,
                });
              },
              onSettled: () => {
                callback?.();
                onDrawerClosed();
              },
            },
          );
        }}
      />
      <JobPanelDataField
        title="job_detail_drawer.job_site"
        data={
          <a
            href={`${GOOGLE_MAP_BASE_URL}${service?.address}`}
            target="_blank"
            className="underline text-yellow-700 cursor-pointer"
            rel="noreferrer">
            <DsText className="text-base text-sos-brown">
              {service?.address}
            </DsText>
          </a>
        }
      />
      <JobPanelDataField
        title="job_detail_drawer.contact"
        data={
          <>
            <DsText className="text-base font-semibold">
              {service?.contact?.name ?? "--"}
            </DsText>
            <DsText className="text-base text-sos-brown">
              {service?.contact?.phone ?? "--"}
            </DsText>
          </>
        }
        isLoading={
          isPendingService && serviceVariables.contact === service.contact
        }
        editField={
          <Controller
            name="contact"
            defaultValue={siteContact?.[0].email}
            control={control}
            render={({ field }) => (
              <DsSelectBox
                containerClassName="flex-grow"
                selectedValue={field.value}
                onSelect={value => {
                  field.onChange(value);
                }}
                items={contactOptions}
              />
            )}
          />
        }
        handleOnConfirm={callback => {
          const selectedContact = siteContact?.find(
            item => item.email === selectedContactEmail,
          );
          handleUpdateService({
            serviceParams: { contact: selectedContact },
            onClose: callback,
          });
        }}
        isEditable
      />
      {selectedJobType === ServiceTypeEnum.JUNK && !isChangingJobType && (
        <JobPanelDataField
          title="add_new_job_drawer.interior_cleanup_type"
          isLoading={isPendingCleanupType}
          secondary={
            <DsSelectBox
              selectedValue={task.cleanupType ?? ""}
              isLoading={isPendingCleanupType}
              onSelect={value => {
                updateCleanupType({
                  jobId: service.jobId,
                  serviceId: service.id,
                  taskId: task.taskId,
                  cleanupType: value as CleanupTypeEnumType,
                });
              }}
              items={cleanupTypeOptions}
            />
          }
        />
      )}
      {selectedJobType === ServiceTypeEnum.BIN &&
        (selectedBinTask === BinTaskEnum.WAITANDLOAD ||
          selectedBinTask === BinTaskEnum.DROPOFF ||
          selectedBinTask === BinTaskEnum.EXCHANGE) && (
          <BinSizeController
            control={control}
            isChangingMode={isChangingJobType}
            service={service}
            taskId={task.taskId}
            setValue={setValue}
          />
        )}
      {selectedJobType === ServiceTypeEnum.BIN &&
        (selectedBinTask === BinTaskEnum.PICKUP ||
          selectedBinTask === BinTaskEnum.EXCHANGE ||
          selectedBinTask === BinTaskEnum.DUMPANDRETURN) && (
          <ConnectedTaskController
            service={service}
            control={control}
            taskId={task.taskId}
            isChangingMode={isChangingJobType}
            setValue={setValue}
          />
        )}
      <JobPanelDataField
        title="job_detail_drawer.job_notes"
        secondary={
          <RightPanel
            title="Task Notes"
            content={
              <TaskNotes
                jobId={service?.jobId}
                serviceId={service?.id}
                taskId={task.taskId}
              />
            }>
            <DsButton className="w-full" type="button">
              {t("job_detail_drawer.view_task_notes")}
            </DsButton>
          </RightPanel>
        }
      />
      <JobPanelDataField
        title="add_new_job_drawer.scheduling_preference"
        secondary={
          <DsToggleGroup
            options={schedulingPreferenceOptions}
            variant="flex"
            isLoading={
              isPendingService &&
              serviceVariables.schedulePreference === service.schedulePreference
            }
            onChange={value => {
              handleUpdateService({
                serviceParams: {
                  schedulePreference: value as SchedulePreferenceEnum,
                },
              });
            }}
            value={service.schedulePreference}
          />
        }
      />
      {selectedJobType === ServiceTypeEnum.BIN &&
        (selectedBinTask === BinTaskEnum.DROPOFF ||
          selectedBinTask === BinTaskEnum.EXCHANGE ||
          selectedBinTask === BinTaskEnum.WAITANDLOAD) && (
          <AggregateController
            jobId={service.jobId}
            material={selectedMaterial}
            yardAmount={yardAmountInput}
            control={control}
            isChangingMode={isChangingJobType}
            trigger={trigger}
          />
        )}
      <JobPanelDataField
        title="job_detail_drawer.scheduler_note"
        buttonClassName="self-end"
        secondary={<DsTextArea value={service.taskNotes} readOnly size="sm" />}
        isEditable
        isLoading={
          isPendingService && serviceVariables.taskNotes === service.taskNotes
        }
        editField={
          <Controller
            name="taskNotes"
            defaultValue={service.taskNotes}
            control={control}
            render={({ field }) => (
              <DsTextArea
                value={field.value}
                size="sm"
                onChange={e => field.onChange(e)}
              />
            )}
          />
        }
        handleOnConfirm={callback => {
          handleUpdateService({
            serviceParams: { taskNotes: watch("taskNotes") },
            onClose: callback,
          });
        }}
      />
      <JobPanelDataField
        title="dashboard_table.assigned_driver"
        secondary={
          <AssignDriverComponent
            taskAssignedTo={task.assignedTo}
            taskType={task.taskId}
            isDriverTable={Boolean(service.assignedTo)}
            isArrowOnlyMode={false}
            selectBoxClassName="static"
            isLoading={
              (isAssigningTaskToDriver &&
                assignTaskToDriverVariables.jobId === task.jobId) ||
              (isUnassigningTaskFromDriver &&
                unassignTaskFromDriverVariables.jobId === task.jobId)
            }
            selectedAssignedDriverId={task.assignedTo ?? ""}
            onSelectAssignedDriver={selectedDriverId =>
              onSelectDriver({ taskData: task, driverId: selectedDriverId })
            }
            isTaskCompleted={task.completed}
          />
        }
      />
      <JobPanelDataField
        title="add_new_job_drawer.admin_notes"
        secondary={
          <DsTextArea
            value={service.adminNotes}
            disabled={
              isPendingService &&
              serviceVariables.adminNotes === service.adminNotes
            }
            readOnly
            size="sm"
          />
        }
        isLoading={
          isPendingService && serviceVariables.adminNotes === service.adminNotes
        }
        isEditable
        buttonClassName="self-end"
        editField={
          <Controller
            name="adminNotes"
            control={control}
            defaultValue={service.adminNotes}
            render={({ field }) => (
              <DsTextArea
                value={field.value}
                size="sm"
                onChange={e => field.onChange(e)}
              />
            )}
          />
        }
        handleOnConfirm={callback => {
          handleUpdateService({
            serviceParams: { adminNotes: watch("adminNotes") },
            onClose: callback,
          });
        }}
      />
      <div className="py-4 flex flex-col gap-y-4">
        {canDeleteJob && (
          <DsButton
            type="button"
            variant="danger"
            isLoading={isCancelling}
            onClick={() => setIsOpenModal(true)}>
            {t("job_detail_drawer.cancel_job")}
          </DsButton>
        )}
        {!isChangingJobType && (
          <DsButton
            variant="outline"
            onClick={() => setIsChangingJobType(true)}>
            {t("job_detail_drawer.change_job_type")}
          </DsButton>
        )}
      </div>
      {isChangingJobType && (
        <JobTypeSectionForm
          control={control}
          selectedServiceType={selectedJobType}
          selectedTaskType={selectedBinTask}
          selectedCleanupType={selectedCleanupType}
          isPending={isPendingJob}
        />
      )}
      <DsConfirmationModal
        title="job_detail_drawer.job_delete_modal_title"
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        isConfirmButtonLoading={isCancelling}
        onConfirm={() =>
          cancelJob(
            {
              jobId: service.jobId,
              serviceId: service.id,
              taskId: task.taskId,
            },
            {
              onSuccess: () => {
                onDrawerClosed();
              },
              onSettled: () => {
                setIsOpenModal(false);
              },
            },
          )
        }
      />
    </form>
  );
};

export { ServiceDetails };
