import { FC } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

import { classNames } from "@/src/utils/classNames";

type DsModalPropsType = {
  onDismiss?: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
  rootClassName?: string;
  panelClassName?: string;
};

const DsModal: FC<DsModalPropsType> = ({
  onDismiss,
  isOpen,
  rootClassName,
  panelClassName,
  children,
}) => {
  const handleDismiss = () => onDismiss?.();

  return (
    <Dialog
      open={isOpen}
      onClose={handleDismiss}
      className={classNames("relative z-40", rootClassName)}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className={classNames(
              "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95",
              panelClassName,
            )}>
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export { DsModal };
export type { DsModalPropsType };
