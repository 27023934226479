import { collectionGroup, orderBy, query, where } from "@firebase/firestore";
import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import NotesFeed from "../NotesFeed";

function TaskNotes({ jobId, serviceId, taskId, customer }) {
  const notesRoute = customer ? "customerNotes" : "notes";
  const firestore = useFirestore();
  const notesCollection = collectionGroup(firestore, notesRoute);
  const notesQuery = query(
    notesCollection,
    where("jobId", "==", jobId),
    where("serviceId", "==", serviceId),
    where("taskId", "in", ["", taskId]),
    orderBy("createdAt", "asc")
  );

  const { status, data: notes } = useFirestoreCollectionData(notesQuery, {
    idField: "id",
  });

  if (status === "loading") return <div>Loading notes...</div>;

  return (
    <NotesFeed
      notes={notes}
      path={`jobs/${jobId}/services/${serviceId}/tasks/${taskId}/${notesRoute}`}
      ids={{ jobId, serviceId, taskId }}
    />
  );
}

export default TaskNotes;
