const filterObjectData = <T extends object>(data: T[], text: string) =>
  data?.filter(item =>
    Object.keys(item).some(key =>
      String(item[key as keyof T])
        .toLowerCase()
        .includes(text.toLowerCase()),
    ),
  );

export { filterObjectData };
