import { doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";

import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const CustomerApi = {
  // Fetch all customers
  listCustomers: async () => {
    try {
      const customersCollection = SpikeSosDb.customers;

      const customerQuery = query(customersCollection, orderBy("name"));
      const querySnapshot = await getDocs(customerQuery);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      logger.error("Error fetching customers", error);

      throw error;
    }
  },
  // Fetch a customer
  getCustomer: async (customerId: string) => {
    try {
      const customerRef = doc(SpikeSosDb.customers, customerId);
      const customerDoc = await getDoc(customerRef);

      if (customerDoc.exists()) {
        const customerData = customerDoc.data();
        return { customerId: customerDoc.id, ...customerData };
      }
    } catch (error) {
      logger.error("Error fetching customer", error);

      throw error;
    }
  },
};
