import { sendSentryError } from "./sentry";

const isDev = process.env.NODE_ENV === "development";

/** Logger
 *
 * Provides all the different sorts of logging
 * error debug query mutation analytics
 *
 */

export const logger = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  debug: (...args: any[]) => {
    if (isDev) {
      const textArg = args[0] as string;
      console.log(`DEBUG ====> ${textArg}`);
      if (args[1]) {
        for (const arg of args) {
          if (arg !== textArg) {
            console.log("DEBUG DATA ====>", arg);
          }
        }
      }
    }
  },
  error: (text: string, error: unknown) => {
    if (isDev) {
      console.warn(`ERROR ====> ${text}`, error);
    } else {
      sendSentryError({ message: text, error });
    }
  },
};
