import { useUser } from "reactfire";

import { useUserQuery } from "../data/queries/userQueries";

export const useCurrentUser = () => {
  // Retrieve the current authenticated user
  const { data: user } = useUser();

  // Fetch admin user details only when user ID is available
  const { data, isLoading } = useUserQuery({
    userId: user?.uid ?? "",
    enabled: Boolean(user?.uid),
  });

  return {
    data,
    isLoading,
  };
};
