import React, { FC, useState } from "react";

import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";
import { DsButton } from "@/src/components/ui";
import { classNames } from "@/src/utils/classNames";

type JobPanelDataFieldPropsType = {
  title: I18nKeyType;
  data?: string | React.ReactElement | undefined;
  secondary?: string | React.ReactElement | undefined;
  className?: string;
  buttonClassName?: string;
  editContainerClassName?: string;
  confirmButtonClassName?: string;
  isLoading?: boolean;
} & (
  | {
      isEditable: true;
      editField: React.ReactElement;
      handleOnConfirm?: (callback?: () => void) => void;
    }
  | { isEditable?: false; editField?: undefined; handleOnConfirm?: undefined }
);

const JobPanelDataField: FC<JobPanelDataFieldPropsType> = ({
  title,
  data,
  secondary,
  isEditable,
  className,
  buttonClassName,
  confirmButtonClassName,
  editField,
  isLoading,
  handleOnConfirm,
  editContainerClassName,
}) => {
  const { t } = useTranslator();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div
      className={classNames(
        "items-baseline flex py-4 gap-2 border-b border-[#DADADA]",
        className,
      )}>
      <div className="text-sm font-medium text-gray-500 flex-grow min-w-[30%]">
        {t(title)}
        {isEditing ? (
          <div
            className={classNames(
              "flex gap-2 items-center mt-1",
              editContainerClassName,
            )}>
            {editField}
            <DsButton
              type="button"
              variant="custom"
              isLoading={isLoading}
              className={classNames(
                "text-yellow-700 p-0",
                confirmButtonClassName,
              )}
              onClick={() => {
                handleOnConfirm?.(() => {
                  setIsEditing(false);
                });
              }}>
              {t("job_detail_drawer.done")}
            </DsButton>
          </div>
        ) : (
          secondary && (
            <div className="text-base font-medium text-black mt-1 leading-none">
              {secondary}
            </div>
          )
        )}
      </div>
      {data && !isEditing && (
        <div className="text-base font-medium leading-5 text-black flex-grow-[2] mr-10">
          {data ?? "--"}
        </div>
      )}
      {isEditable && !isEditing && (
        <DsButton
          variant="custom"
          type="button"
          isLoading={isLoading}
          className={classNames(
            "text-yellow-700 self-center p-0 mb-0.5",
            buttonClassName,
          )}
          onClick={() => setIsEditing(true)}>
          {t("job_detail_drawer.edit")}
        </DsButton>
      )}
    </div>
  );
};

export { JobPanelDataField };
