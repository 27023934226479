import React, { forwardRef, InputHTMLAttributes } from "react";

import { I18nKeyType } from "@/i18n";
import { useTranslate } from "@/i18n/useTranslate";

type UploadButtonPropsType = InputHTMLAttributes<HTMLInputElement> & {
  text?: I18nKeyType;
  i18nOptions?: object;
};

const UploadButton = forwardRef<HTMLInputElement, UploadButtonPropsType>(
  (
    { text = "add_new_job_drawer.upload_photos", i18nOptions, ...props },
    ref,
  ) => {
    const i18nText = useTranslate(text, i18nOptions);

    return (
      <label
        className="relative inline-flex items-center cursor-pointer rounded-lg text-base font-bold bg-gray-200 text-black hover:bg-gray-200/80 h-10 px-4 py-2"
        htmlFor="images">
        {i18nText}
        <input
          {...props}
          id="images"
          ref={ref}
          className="absolute inset-0 w-[1px] h-[1px] overflow-hidden whitespace-nowrap clip-rect-0 clip-path-[inset(50%)]"
        />
      </label>
    );
  },
);

UploadButton.displayName = "UploadButton";

export { UploadButton };
