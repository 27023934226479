import { FC } from "react";

import { DriverTypeEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";
import { DsText } from "@/src/components/ui";
import { classNames } from "@/src/utils/classNames";
import { formatDriverName, getNameInitials } from "@/src/utils/strings";

import {
  useBinTasksCountQuery,
  useJunkTasksCountQuery,
} from "../data/queries/taskQueries";
import { dateAsDayjs, DateType } from "../utils/date";

type DashboardDriverSelectorCardPropsType = {
  driverName: string;
  driverId: string;
  onDriverSelect: () => void;
  isSelected?: boolean;
  selectedDate: DateType;
  driverType: DriverTypeEnum;
};

const DashboardDriverSelectorCard: FC<DashboardDriverSelectorCardPropsType> = ({
  driverName,
  driverId,
  onDriverSelect,
  isSelected,
  selectedDate,
  driverType,
}) => {
  const { t } = useTranslator();

  const isBothDriver = driverType === DriverTypeEnum.BOTH;
  const isBinDriver = driverType === DriverTypeEnum.BIN || isBothDriver;
  const isJunkDriver = driverType === DriverTypeEnum.JUNK || isBothDriver;

  const { data: binDriverTasksCount } = useBinTasksCountQuery({
    filters: {
      dueAtStart: dateAsDayjs(selectedDate).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(selectedDate).endOf("day").toDate(),
      completed: false,
      assignedTo: driverId,
    },
    enabled: isBinDriver,
  });

  const { data: junkDriverTasksCount } = useJunkTasksCountQuery({
    filters: {
      dueAtStart: dateAsDayjs(selectedDate).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(selectedDate).endOf("day").toDate(),
      completed: false,
      assignedTo: driverId,
    },
    enabled: isJunkDriver,
  });

  return (
    <div
      onClick={onDriverSelect}
      className={classNames(
        "flex-grow text-center items-center h-full rounded-lg min-h-14 min-w-32 p-2 border border-gray-300 flex flex-col justify-center px-2 hover:cursor-pointer bg-white",
        isSelected && "bg-sos-yellow border-sos-yellow",
      )}>
      <div
        className={classNames(
          "h-12 w-12 rounded-full justify-center items-center hidden md:flex",
          isSelected ? "bg-black" : "bg-sos-yellow",
        )}>
        <DsText
          className={classNames(isSelected && "text-sos-yellow")}
          variant="pageTitle">
          {getNameInitials(driverName)}
        </DsText>
      </div>

      <DsText
        variant="dataDisplayTitle"
        className="text-black mt-1.5 w-full break-all">
        {formatDriverName(driverName)}
      </DsText>

      {isBinDriver && (
        <DsText>{`${binDriverTasksCount ?? t("loading")} ${t("bins") as string}`}</DsText>
      )}
      {isJunkDriver && (
        <DsText>{`${junkDriverTasksCount ?? t("loading")} ${t("junk")}`}</DsText>
      )}
    </div>
  );
};

export { DashboardDriverSelectorCard };
