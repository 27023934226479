import { FC } from "react";

import { classNames } from "@/src/utils/classNames";

import { DsText } from "./DsText";

type DsYellowBadgePropsType = {
  text: string;
  className?: string;
};

const DsYellowBadge: FC<DsYellowBadgePropsType> = ({ text, className }) => {
  return (
    <div
      className={classNames(
        "px-3 bg-sos-yellow inline-flex rounded-full items-center",
        className,
      )}>
      <DsText variant="badgeText">{text}</DsText>
    </div>
  );
};

export { DsYellowBadge };
