import { ref } from "@firebase/storage";
import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useStorage, useStorageDownloadURL } from "reactfire";
import { classNames } from "../utils/classNames";
import { DsModal } from "./ui";

function ImgFromPath({ path, className, openBubble = true }) {
  const storage = useStorage();
  const imgRef = ref(storage, path);
  const { status, data: imageURL } = useStorageDownloadURL(imgRef);
  const [open, setOpen] = useState(false);

  if (status === "loading") {
    return <span>loading...</span>;
  }

  return (
    <>
      <img
        className={classNames(className, "cursor-pointer")}
        src={imageURL}
        onClick={() => openBubble && setOpen(true)}
      />
      <DsModal isOpen={open} onDismiss={() => setOpen(false)}>
        <img
          onClick={() => setOpen(false)}
          className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:p-6 w-full"
          src={imageURL}
        />
      </DsModal>
    </>
  );
}

export default ImgFromPath;
