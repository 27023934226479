import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";

import { firestore } from "@/firebase.config";
import { BinFiltersType, UnassignBinPropsType } from "@/src/domain/bin";
import { UpdateBinSizeParamsType } from "@/src/domain/task";
import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const BinApi = {
  listBins: async (filters?: BinFiltersType) => {
    try {
      const binsCollection = SpikeSosDb.bins;

      const constraints = [];

      if (filters?.status) {
        constraints.push(where("status", "==", filters.status));
      }

      if (filters?.deleted !== undefined) {
        constraints.push(where("deleted", "==", filters.deleted));
      }

      const binsQuery = query(binsCollection, ...constraints);

      // Execute the query and return the results
      const querySnapshot = await getDocs(binsQuery);
      const bins = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      return bins;
    } catch (error) {
      logger.error("Error fetching bins", error);

      throw error;
    }
  },
  getBin: async (binId: string) => {
    try {
      const binRef = doc(SpikeSosDb.bins, binId);
      const binDoc = await getDoc(binRef);

      if (binDoc.exists()) {
        const binData = binDoc.data();
        return { id: binDoc.id, ...binData };
      }
    } catch (error) {
      logger.error("Error getting bin", error);

      throw error;
    }
  },
  deleteBin: async (binId: string) => {
    try {
      const binRef = doc(SpikeSosDb.bins, binId);
      await deleteDoc(binRef);

      return binId;
    } catch (error) {
      logger.error("Error deleting bin", error);

      throw error;
    }
  },
  unassignBin: async ({
    jobId,
    serviceId,
    binId,
    user,
  }: UnassignBinPropsType) => {
    try {
      const servicePath = `/jobs/${jobId}/services/${serviceId}`;
      const jobPath = `/jobs/${jobId}`;

      const serviceDoc = doc(firestore, servicePath);
      const binDoc = doc(firestore, `bins/${binId}`);

      // Check if serviceDoc and binDoc exist
      const [serviceSnapshot, binSnapshot] = await Promise.all([
        getDoc(serviceDoc),
        getDoc(binDoc),
      ]);

      if (!binSnapshot.exists()) {
        throw new Error(
          "Bin document does not exist, cannot proceed with unassign.",
        );
      }

      if (!user) {
        throw new Error("No user found, cannot proceed with unassign.");
      }

      // Proceed with the transaction
      await runTransaction(firestore, async transaction => {
        const updatePromises = [];

        if (serviceSnapshot.exists()) {
          const updateService = transaction.update(serviceDoc, {
            assignedBin: null,
            assignedBy: null,
            stage: 1,
            lastUpdatedBy: {
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
            },
          });
          updatePromises.push(updateService);
        } else {
          logger.debug(
            "Service document does not exist, skipping service update.",
          );
        }

        const updateBin = transaction.update(binDoc, {
          currentJob: null,
          currentService: null,
          currentData: null,
          status: "available",
        });
        updatePromises.push(updateBin);

        return Promise.all(updatePromises);
      });

      // Check if the job document exists before attempting to delete
      const jobDoc = doc(firestore, jobPath);
      const jobDocSnap = await getDoc(jobDoc);

      if (jobDocSnap.exists()) {
        await deleteDoc(jobDoc);
      } else {
        logger.debug("Job document does not exist, skipping deletion.");
      }

      return binId;
    } catch (error) {
      logger.error("Error unassigning bin", error);

      throw error;
    }
  },
  updateBinSize: async ({
    jobId,
    serviceId,
    taskId,
    size,
  }: UpdateBinSizeParamsType) => {
    try {
      if (!jobId || !serviceId || !taskId || !size) {
        throw new Error("Missing required parameters");
      }

      const jobRef = doc(SpikeSosDb.jobs, jobId);
      const serviceRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const taskRef = doc(
        SpikeSosDb.jobs,
        `${jobId}/services/${serviceId}/tasks/${taskId}`,
      );

      await updateDoc(jobRef, { size });
      await updateDoc(serviceRef, { size });
      await updateDoc(taskRef, { size });

      return { jobId, serviceId, taskId, size };
    } catch (error) {
      logger.error("Error updating bin size", error);

      throw error;
    }
  },
};
