import React, { forwardRef } from "react";
import { Input } from "@headlessui/react";
import { t } from "i18next";

import { I18nKeyType } from "@/i18n";
import { useTranslate } from "@/i18n/useTranslate";
import { classNames } from "@/src/utils/classNames";

import { DsText } from ".";

type DsInputPropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  title?: I18nKeyType;
  placeholder?: I18nKeyType;
  error?: boolean | string;
};

const DsInput = forwardRef<HTMLInputElement, DsInputPropsType>(
  ({ className, placeholder, error, title, ...props }, ref) => {
    const placeholderText = useTranslate(placeholder);

    return (
      <div className="w-full max-w-md">
        {title && <DsText variant="button">{t(title)}</DsText>}
        <Input
          ref={ref}
          placeholder={placeholderText}
          className={classNames(
            "w-full rounded-md py-1.5 pl-3 pr-10 text-left text-black sm:text-sm sm:leading-6 shadow-sm ring-1 bg-white ring-inset ring-gray-300 focus:outline-none",
            error ? "text-[#ba2727] bg-[#fcf4f4] ring-[#ba2727] ring-2" : "",
            className,
          )}
          {...props}
        />
        {error && (
          <DsText variant="errorText">
            {typeof error === "string"
              ? error
              : t("add_new_job_drawer.rules.invalid_input")}
          </DsText>
        )}
      </div>
    );
  },
);

DsInput.displayName = "DsInput";

export { DsInput };
