import React, { Dispatch } from "react";
import { SetStateAction } from "jotai";

import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";
import { classNames } from "@/src/utils/classNames";

import { DsButton } from ".";

type DsTableSelectionHeaderPropsType = {
  actionLabel?: I18nKeyType;
  isSelecting: boolean;
  onConfirm: () => void;
  setIsSelecting: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  className?: string;
  selectedRows: string[];
};

const DsTableSelectionHeader: React.FC<DsTableSelectionHeaderPropsType> = ({
  isSelecting,
  onConfirm,
  onCancel,
  className,
  setIsSelecting,
  actionLabel = "yes",
  selectedRows,
}) => {
  const { t } = useTranslator();

  return (
    <div
      className={classNames(
        "absolute right-0 md:top-[-4rem] top-[-2.4rem] flex items-center space-x-2",
        className,
      )}>
      {!isSelecting ? (
        <DsButton
          variant="custom"
          onClick={() => setIsSelecting(true)}
          className="px-4 bg-transparent text-sos-red">
          {t("select")}
        </DsButton>
      ) : (
        <>
          <DsButton
            variant="custom"
            disabled={!selectedRows?.length}
            onClick={onConfirm}
            className="px-4 bg-transparent text-[#D03E45]">
            {t(actionLabel)}
          </DsButton>
          <DsButton
            variant="custom"
            onClick={onCancel}
            className="px-4 bg-transparent text-sos-red">
            {t("cancel")}
          </DsButton>
        </>
      )}
    </div>
  );
};

export { DsTableSelectionHeader };
