import { FC } from "react";

type DsSelectBoxSelectedItemType = {
  label: string;
};

const DsSelectBoxSelectedItem: FC<DsSelectBoxSelectedItemType> = ({
  label,
}) => {
  return (
    <span className="inline-flex w-full truncate">
      <span className="truncate">{label}</span>
    </span>
  );
};

export { DsSelectBoxSelectedItem };
