import { toast, ToastOptions } from "react-toastify";

export type ToastPropsType = {
  text: string;
  options?: ToastOptions;
};

export const successToast = (toastArgs: ToastPropsType) =>
  toast.success(toastArgs.text, toastArgs.options);

export const errorToast = (toastArgs: ToastPropsType) =>
  toast.error(toastArgs.text, toastArgs.options);
