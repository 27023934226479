import { FC, useState } from "react";
import { navigate } from "gatsby";

import { BinType } from "@/domain/models/bin";
import { BinStatusEnum, ServiceTypeEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";
import { BinDetailsCustomerComponent, BinRentalStatus } from "@/src/blocks";
import {
  DsBinStatus,
  DsButton,
  DsConfirmationModal,
  DsDataDisplay,
  DsText,
} from "@/src/components/ui";
import {
  useDeleteBin,
  useUnassignBin,
} from "@/src/data/mutations/binMutations";
import { formatDate } from "@/src/utils/date";
import { formatBinSize, getBinRentalStatus } from "@/src/utils/strings";

import { JobCreationForm } from ".";
import { RightPanel } from "../components/RightPanel";
import { useServicesListQuery } from "../data/queries/serviceQueries";
import { filterServicesForBinConnection } from "../utils/service";

type BinDetailsPropsType = {
  bin: BinType | undefined;
  onBinDeleted?: () => void;
  onBinUnassigned?: () => void;
};

const BinDetails: FC<BinDetailsPropsType> = ({
  bin,
  onBinDeleted,
  onBinUnassigned,
}) => {
  const [isConfirmUnassignBinOpen, setIsConfirmUnassignBinOpen] =
    useState(false);
  const [isNewJobOpen, setIsNewJobOpen] = useState(false);
  const [isConfirmDeleteBinOpen, setIsConfirmDeleteBinOpen] = useState(false);

  const { t } = useTranslator();
  const {
    data: services,
    isLoading: isLoadingServices,
    refetch,
  } = useServicesListQuery({
    filters: {
      address: bin?.currentData?.address,
      customerId: bin?.currentData?.customer.id,
    },
  });
  const { mutate: deleteBin, isPending: isDeletingBin } = useDeleteBin();

  const { mutate: unassignBin, isPending: isUnassigningBin } = useUnassignBin();

  if (!bin) {
    return null;
  }

  const {
    id: binId,
    currentData,
    identifier: binIdentifier,
    size,
    status,
  } = bin;
  const binLocation = currentData?.address;
  const dropAt = currentData?.dropAt;
  const binRentalStatus = getBinRentalStatus(bin);

  const handleDeleteBin = () =>
    deleteBin(binId, {
      onSuccess: () => {
        setIsConfirmDeleteBinOpen(false);
        onBinDeleted?.();
      },
    });

  const handleUnassignBin = () =>
    unassignBin(
      {
        binId,
        jobId: currentData?.jobId ?? "",
        serviceId: currentData?.serviceId ?? "",
      },
      {
        onSuccess: () => {
          setIsConfirmUnassignBinOpen(false);
          onBinUnassigned?.();
        },
      },
    );
  const scheduledService = services?.find(
    service =>
      service.connectedTask && service.connectedTask.id === bin.currentService,
  );
  const availableServices = filterServicesForBinConnection(services ?? []);
  const canSchedule =
    Boolean(scheduledService) || (availableServices?.length && bin.currentJob);
  return (
    <>
      <DsConfirmationModal
        isOpen={isConfirmDeleteBinOpen}
        onCancel={() => setIsConfirmDeleteBinOpen(false)}
        onDismiss={() => setIsConfirmDeleteBinOpen(false)}
        onConfirm={handleDeleteBin}
        title="bins_page.confirm_delete_bin_message"
        titlei18nOptions={{ binIdentifier }}
        confirmButtonText="bins_page.delete_bin_confirm_message"
        isConfirmButtonLoading={isDeletingBin}
      />

      <DsConfirmationModal
        isOpen={isConfirmUnassignBinOpen}
        onCancel={() => setIsConfirmUnassignBinOpen(false)}
        onDismiss={() => setIsConfirmUnassignBinOpen(false)}
        onConfirm={handleUnassignBin}
        title="bins_page.confirm_unassign_bin_message"
        confirmButtonText="yes"
        isConfirmButtonLoading={isUnassigningBin}
      />

      <>
        <DsDataDisplay
          title="bins_page.status"
          data={
            <DsBinStatus
              variant={binRentalStatus.rentalStatus}
              status={binRentalStatus.rentalStatus}
              styleFormat="labeled"
            />
          }
        />
        <DsDataDisplay title="bins_page.size" data={formatBinSize(size)} />
        <DsDataDisplay
          title="bins_page.client"
          data={<BinDetailsCustomerComponent bin={bin} />}
        />
        <DsDataDisplay title="bins_page.bin_location" data={binLocation} />
        <DsDataDisplay
          title="bins_page.drop_date"
          data={dropAt && formatDate(dropAt?.toDate())}
        />
        <DsDataDisplay
          title="bins_page.rental_status"
          data={<BinRentalStatus bin={bin} className="text-base font-medium" />}
        />
        <div className="py-4 border-b border-[#DADADA] flex flex-row items-center gap-6">
          <DsButton
            isLoading={isLoadingServices}
            variant={scheduledService ? "secondary" : "primary"}
            onClick={() => {
              if (scheduledService) {
                void navigate(`/admin/jobs/${scheduledService.jobId}`);
              } else {
                setIsNewJobOpen(true);
              }
            }}
            disabled={!canSchedule}>
            {scheduledService
              ? t("bins_page.service_scheduled")
              : t("bins_page.schedule_service")}
          </DsButton>
          {scheduledService && (
            <DsText variant="cardTitle" className="font-medium">
              {formatDate(scheduledService?.date.toDate())}
            </DsText>
          )}
          <RightPanel
            title={t("add_new_job_drawer.title")}
            controlledOpen={isNewJobOpen}
            onDrawerClosed={() => setIsNewJobOpen(false)}
            setControlledOpen={setIsNewJobOpen}
            content={
              <JobCreationForm
                onPanelClose={() => {
                  void refetch();
                  setIsNewJobOpen(false);
                }}
                defaultValues={{
                  customer: bin.currentData?.customer.id ?? "",
                  site: bin.currentData?.address,
                  type: ServiceTypeEnum.BIN,
                  size: bin.size,
                  connectedTask: bin.id,
                }}
                isCreatingBinRelatedJob
              />
            }
          />
        </div>
        <div className="py-4 flex flex-row">
          {status === BinStatusEnum.ASSIGNED && (
            <DsButton
              className="mr-4"
              onClick={() => setIsConfirmUnassignBinOpen(true)}>
              {t("bins_page.unassign_bin")}
            </DsButton>
          )}
          <DsButton onClick={() => setIsConfirmDeleteBinOpen(true)}>
            {t("bins_page.delete_bin")}
          </DsButton>
        </div>
      </>
    </>
  );
};

export { BinDetails };
