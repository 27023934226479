import CheckCircleIcon from "@/assets/icons/check_circle.svg";
import CheckMarkIcon from "@/assets/icons/check_mark.svg";
import ChevronDownIcon from "@/assets/icons/chevron_down.svg";
import ChevronLeftIcon from "@/assets/icons/chevron_left.svg";
import ChevronRightIcon from "@/assets/icons/chevron_right.svg";
import ChevronUpDownIcon from "@/assets/icons/chevron_up_down.svg";
import ChevronUpIcon from "@/assets/icons/chevron_up.svg";
import CloseIcon from "@/assets/icons/close.svg";
import DragDropIcon from "@/assets/icons/dragdrop.svg";
import LocationMarkIcon from "@/assets/icons/location_mark.svg";
import MenuIcon from "@/assets/icons/menu.svg";
import NotesIcon from "@/assets/icons/notes.svg";
import PencilIcon from "@/assets/icons/pencil.svg";
import PlusIcon from "@/assets/icons/plus.svg";
import SearchIcon from "@/assets/icons/search.svg";
import StarSelectedIcon from "@/assets/icons/star_selected.svg";
import StartUnselectedIcon from "@/assets/icons/star_unselected.svg";
import TruckIcon from "@/assets/icons/truck.svg";

export const iconRegistry = {
  Plus: PlusIcon,
  Menu: MenuIcon,
  Notes: NotesIcon,
  Pencil: PencilIcon,
  Search: SearchIcon,
  Close: CloseIcon,
  DragDrop: DragDropIcon,
  LocationMark: LocationMarkIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ChevronUpDown: ChevronUpDownIcon,
  ChevronUp: ChevronUpIcon,
  ChevronDown: ChevronDownIcon,
  StarSelected: StarSelectedIcon,
  StartUnselected: StartUnselectedIcon,
  Truck: TruckIcon,
  CheckCircle: CheckCircleIcon,
  CheckMark: CheckMarkIcon,
};

export type IconType = keyof typeof iconRegistry;
