import React, { useState } from "react";
import { GeoPoint } from "@firebase/firestore";
import { t } from "i18next";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { SiteAddressField } from "../blocks";
import { DsButton } from "../components/ui";
import { useAddSite } from "../data/mutations/siteMutations";
import { useCustomerQuery } from "../data/queries/customerQueries";
import { logger } from "../utils/logger";

type QuickSiteCreationBoxPropsType = {
  customerId: string;
  setSiteValue?: (site: string) => void;
};
const QuickSiteCreationBox: React.FC<QuickSiteCreationBoxPropsType> = ({
  customerId,
  setSiteValue,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [address, setAddress] = useState("");
  const { mutate: addSite, isPending } = useAddSite();
  const { data: customer, isLoading } = useCustomerQuery({ customerId });
  const handleAddSite = async () => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const geopoint = new GeoPoint(latLng.lat, latLng.lng);
      if (isLoading || !customer) return;
      addSite(
        {
          customer,
          contact: customer.contacts[0],
          customerId,
          address,
          geopoint,
          floorSqft: "",
          floorsCleaned: "",
          lockbox: "",
          notes: "",
        },
        {
          onSuccess: data => {
            setSiteValue?.(data);
          },
        },
      );
    } catch (error) {
      logger.error("Error adding site", error);
    }
  };

  if (!isEditing)
    return (
      <DsButton
        variant="primary"
        onClick={() => setIsEditing(true)}
        className="w-full">
        {t("add_new_job_drawer.quick_add_site")}
      </DsButton>
    );
  return (
    <div className="border border-sos-grey p-4 w-full space-y-4 rounded-md">
      <SiteAddressField
        value={address}
        onChange={address => setAddress(address)}
        onSelect={address => setAddress(address)}
      />
      <DsButton
        onClick={() => {
          void handleAddSite();
        }}
        variant="primary"
        isLoading={isPending}
        className="w-full">
        {t("add_new_job_drawer.add_site")}
      </DsButton>
    </div>
  );
};

export { QuickSiteCreationBox };
