import { useQuery } from "@tanstack/react-query";

import { GetTaskPropsType, TaskFiltersType } from "@/src/domain/task";
import { getBinTaskIds, getJunkTaskIds } from "@/src/utils/bin";

import { TaskApi } from "../api/TaskApi";

export const TaskQueryKeys = {
  all: ["tasks"] as const,
  list: (filters?: TaskFiltersType) =>
    filters
      ? ([...TaskQueryKeys.all, "list", { filters }] as const)
      : ([...TaskQueryKeys.all, "list"] as const),
  count: (filters?: TaskFiltersType) =>
    filters
      ? ([...TaskQueryKeys.all, "count", { filters }] as const)
      : ([...TaskQueryKeys.all, "count"] as const),
  details: (props: GetTaskPropsType) => [...TaskQueryKeys.all, props],
};

type TaskListQueryType = {
  filters?: TaskFiltersType;
  enabled?: boolean;
};

export const useTasksListQuery = ({ filters, enabled }: TaskListQueryType) => {
  return useQuery({
    queryKey: TaskQueryKeys.list(filters),
    queryFn: () => TaskApi.listTasks({ filters }),
    enabled,
  });
};

export const useTasksCountQuery = ({
  filters,
  enabled = true,
}: TaskListQueryType) => {
  return useQuery({
    queryKey: TaskQueryKeys.count(filters),
    queryFn: () => TaskApi.countTasks(filters),
    enabled,
  });
};

export const useBinTasksCountQuery = ({
  filters,
  enabled = true,
}: TaskListQueryType) => {
  const taskIds = getBinTaskIds();

  const binTasksFilter = {
    ...filters,
    taskIds,
  };

  return useTasksCountQuery({ filters: binTasksFilter, enabled });
};

export const useJunkTasksCountQuery = ({
  filters,
  enabled = true,
}: TaskListQueryType) => {
  const taskIds = getJunkTaskIds();

  const binTasksFilter = {
    ...filters,
    taskIds,
  };

  return useTasksCountQuery({ filters: binTasksFilter, enabled });
};

export const useTaskQuery = (props: GetTaskPropsType) => {
  return useQuery({
    queryKey: TaskQueryKeys.details(props),
    queryFn: () => TaskApi.getTask(props),
    enabled:
      Boolean(props.jobId) && Boolean(props.serviceId) && Boolean(props.taskId),
  });
};
