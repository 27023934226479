import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ServicesType } from "@/domain/models/job";
import { UpdateServicePropsType } from "@/src/domain/service";

import { ServiceApi } from "../api/ServiceApi";
import { ServiceQueryKeys } from "../queries/serviceQueries";

export const useUpdateServiceMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateServicePropsType,
    unknown,
    UpdateServicePropsType
  >({
    mutationFn: params => ServiceApi.updateService(params),
    onSuccess: updatedService => {
      const { jobId, serviceId } = updatedService;

      if (!jobId || !serviceId) {
        return;
      }

      queryClient.setQueryData(
        ServiceQueryKeys.details({ jobId, serviceId }),
        (oldData: ServicesType | undefined) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            ...updatedService,
          };
        },
      );
    },
  });

  return mutation;
};
