import { FC } from "react";

import { useBinQuery } from "@/src/data/queries/binQueries";

import { RightPanel } from "../components/RightPanel";
import { BinDetails } from "./BinDetails";

type BinDetailDrawerPropsType = {
  binId: string;
  onCloseDrawer?: () => void;
  isOpen: boolean;
};

const BinDetailsDrawer: FC<BinDetailDrawerPropsType> = ({
  binId,
  onCloseDrawer,
  isOpen,
}) => {
  const { data: binData, isLoading } = useBinQuery({ binId });

  return (
    <RightPanel
      title={binData?.identifier ?? ""}
      controlledOpen={isOpen}
      content={
        <BinDetails
          bin={binData}
          onBinDeleted={onCloseDrawer}
          onBinUnassigned={onCloseDrawer}
        />
      }
      isLoading={isLoading}
      onDrawerClosed={onCloseDrawer}
    />
  );
};

export { BinDetailsDrawer };
