import { useQuery } from "@tanstack/react-query";

import { SiteFiltersType } from "@/src/domain/site";

import { SiteApi } from "../api/SiteApi";

export const SiteQueryKeys = {
  all: ["sites"] as const,
  list: (filters?: SiteFiltersType) =>
    filters
      ? ([...SiteQueryKeys.all, "list", { filters }] as const)
      : ([...SiteQueryKeys.all, "list"] as const),
  details: (siteId: string) => [...SiteQueryKeys.all, siteId] as const,
};

export const useSitesListQuery = ({
  filters,
  enabled,
}: {
  filters: SiteFiltersType;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: SiteQueryKeys.list(filters),
    queryFn: () => SiteApi.listSites(filters),
    enabled,
  });
};
