import { FC } from "react";
import { Trans, TransProps } from "react-i18next";

import { I18nKeyType } from "@/i18n";

import { DsText, DsTextPropsType } from "./DsText";

type DsTranslatorPropsType = Omit<
  DsTextPropsType,
  "i18nKey" | "i18nOptions" | "text"
> & {
  i18nProps: TransProps<I18nKeyType>;
};

export const DsTranslator: FC<DsTranslatorPropsType> = ({
  i18nProps,
  children,
  ...textProps
}) => {
  return (
    <DsText {...textProps}>
      <Trans {...i18nProps} />
      {children}
    </DsText>
  );
};
