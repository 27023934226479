import { useQuery } from "@tanstack/react-query";

import { GetTaskNotesPropsType } from "@/src/domain/notes";

import { NoteApi } from "../api/NoteApi";

export const NotesQueryKeys = {
  all: ["notes"] as const,
  taskNotes: (props: GetTaskNotesPropsType) => [
    ...NotesQueryKeys.all,
    "taskNotes",
    { props },
  ],
};

export const useTaskNotesQuery = (params: GetTaskNotesPropsType) => {
  return useQuery({
    queryKey: NotesQueryKeys.taskNotes(params),
    queryFn: () => NoteApi.listTaskNotes(params),
  });
};
