import {
  FC,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";

import { classNames } from "@/src/utils/classNames";

export type DsTableDataType = {
  shouldHideOnMobile?: boolean;
  shouldCentralizeContent?: boolean;
  className?: string;
  isDragOccurring?: boolean;
  isFromLastRow?: boolean;
  columnIndex?: number;
  onClick?: (e: MouseEvent<HTMLTableCellElement>) => void;
  isLastVisibleCell?: boolean;
};

type TableCellSnapshotType = {
  width: number;
  height: number;
};

const DsTableData: FC<PropsWithChildren<DsTableDataType>> = ({
  children,
  shouldHideOnMobile,
  shouldCentralizeContent,
  className,
  isDragOccurring,
  isFromLastRow,
  columnIndex,
  onClick,
  isLastVisibleCell,
}) => {
  const ref = useRef<HTMLTableCellElement | null>(null);
  const snapshotRef = useRef<TableCellSnapshotType | null>(null);
  const wereSizesCollectedRef = useRef(false);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    // If the sizes were already collected, do not collect them again
    if (wereSizesCollectedRef.current) {
      return;
    }

    const { width, height } = ref.current.getBoundingClientRect();

    wereSizesCollectedRef.current = true;

    snapshotRef.current = { width, height };
  });

  useEffect(() => {
    const snapshot = snapshotRef.current;

    if (!ref.current) {
      return;
    }

    if (snapshot) {
      ref.current.style.width = `${snapshot.width}px`;
      ref.current.style.height = `${snapshot.height}px`;
      snapshotRef.current = null; // Clear the snapshot after applying
      wereSizesCollectedRef.current = false;
      return;
    }

    if (isDragOccurring) {
      return;
    }

    // Inline styles not applied
    if (ref.current.style.width == null) {
      return;
    }

    // No snapshot and drag is finished - clear the inline styles
    ref.current.style.removeProperty("height");
    ref.current.style.removeProperty("width");
  }, [isDragOccurring]);

  return (
    <td
      ref={ref}
      className={classNames(
        "group-hover:bg-[#FFF0AE]",
        shouldHideOnMobile && "hidden md:table-cell",
        isFromLastRow &&
          columnIndex === 1 &&
          "rounded-bl-2xl xl:rounded-bl-none",
        isFromLastRow && "first:rounded-bl-2xl last:rounded-br-2xl",
        isLastVisibleCell && "rounded-br-2xl",
        className,
      )}
      onClick={onClick}>
      <div
        className={classNames(
          shouldCentralizeContent && "grid items-center justify-center",
        )}>
        {children}
      </div>
    </td>
  );
};

export { DsTableData };
