import * as React from "react";
import { Listbox, ListboxButton, ListboxOptions } from "@headlessui/react";
import { t } from "i18next";

import { I18nKeyType } from "@/i18n";
import { useTranslate } from "@/i18n/useTranslate";
import { classNames } from "@/src/utils/classNames";

import { DsIcon } from "../DsIcon";
import { DsLoading } from "../DsLoading";
import { DsText } from "../DsText";
import { DsSelectBoxItem, DsSelectBoxItemType } from "./DsSelectBoxItem";
import { DsSelectBoxSelectedItem } from "./DsSelectBoxSelectedItem";

type DsSelectBoxPropsType = {
  title?: I18nKeyType;
  i18nOptions?: object;
  selectedValue: string;
  items?: DsSelectBoxItemType[];
  children?: React.ReactNode;
  selectedItem?: React.ReactNode;
  onSelect: (value: string) => void;
  isArrowOnlyMode?: boolean;
  containerClassName?: string;
  listBoxClassName?: string;
  listBoxButtonClassName?: string;
  isLoading?: boolean;
  error?: boolean | string;
};

const DsSelectBox = React.forwardRef<HTMLButtonElement, DsSelectBoxPropsType>(
  (
    {
      selectedValue,
      onSelect,
      title,
      items,
      i18nOptions,
      children,
      selectedItem,
      isArrowOnlyMode,
      listBoxClassName,
      listBoxButtonClassName,
      isLoading,
      error,
      containerClassName,
    },
    ref,
  ) => {
    const i18nText = useTranslate(title, i18nOptions);

    const emptyOptionText = useTranslate("no_options");

    const selected =
      items?.find(item => item.value === selectedValue) ?? items?.[0];

    return (
      <Listbox value={selectedValue} onChange={value => onSelect(value)}>
        {({ open }) => (
          <>
            {i18nText && (
              <DsText className="mb-2" variant="dataDisplayTitle">
                {i18nText}
              </DsText>
            )}
            <div
              className={classNames(
                "relative",
                {
                  "absolute w-full left-0": open && isArrowOnlyMode,
                },
                containerClassName,
              )}>
              <ListboxButton
                ref={ref}
                onClick={event => event.stopPropagation()}
                className={classNames(
                  "relative w-full cursor-default rounded-md text-left text-gray-900 sm:text-sm sm:leading-6",
                  !isArrowOnlyMode
                    ? "shadow-sm ring-1 py-1.5 pl-3 pr-10 bg-white ring-inset ring-gray-300 focus:outline-none"
                    : classNames("flex justify-end items-end", {
                        "pr-3.5": open,
                        "py-3": !open,
                      }),
                  error
                    ? "ring-2 ring-[#ba2727] text-[#ba2727] bg-[#fcf4f4]"
                    : "",

                  listBoxButtonClassName,
                )}>
                {!isArrowOnlyMode && (
                  <>
                    {selectedItem ?? (
                      <DsSelectBoxSelectedItem label={selected?.label ?? ""} />
                    )}
                  </>
                )}

                <span
                  className={classNames(
                    "absolute inset-y-0 flex items-center ",
                    {
                      "mr-1.5": open,
                    },
                    {
                      "right-0 mr-2": !isArrowOnlyMode,
                    },
                  )}>
                  {isLoading ? (
                    <DsLoading size="small" />
                  ) : (
                    <DsIcon icon={open ? "ChevronUp" : "ChevronDown"} />
                  )}
                </span>
              </ListboxButton>

              <ListboxOptions
                className={classNames(
                  "absolute z-10 mt-1 max-h-60 w-full overflow-auto py-1 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm",
                  listBoxClassName,
                )}>
                {!isLoading && !items?.length && !children && (
                  <DsText className="px-4 py-1 text-gray-500 italic">
                    {emptyOptionText}
                  </DsText>
                )}

                {items?.map(item => (
                  <DsSelectBoxItem
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    labelSecondaryText={item.labelSecondaryText}
                  />
                ))}
                {children && children}
              </ListboxOptions>
              {error && (
                <DsText variant="errorText">
                  {typeof error === "string"
                    ? error
                    : t("add_new_job_drawer.rules.invalid_input")}
                </DsText>
              )}
            </div>
          </>
        )}
      </Listbox>
    );
  },
);
DsSelectBox.displayName = "DsSelectBox";

export { DsSelectBox };
