import React, { useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { useFirestore, useSigninCheck } from "reactfire";
import { DsConfirmationModal } from "./ui";

export default function DeleteDocModal({
  buttonClassName,
  buttonContent,
  path,
  type,
  onSuccess,
}) {
  const [open, setOpen] = useState(false);
  const firestore = useFirestore();
  const ref = doc(firestore, path);

  const handleDelete = () => {
    deleteDoc(ref);
    if (onSuccess) onSuccess();
    setOpen(false);
  };

  const { status, data: signInCheckResult } = useSigninCheck({
    // Only have managers able to delete notes
    requiredClaims: type === "note" ? { manager: true } : { superadmin: true },
  });

  return status !== "loading" &&
    signInCheckResult.signedIn &&
    signInCheckResult.hasRequiredClaims === true ? (
      <>
        <div className={buttonClassName} onClick={() => setOpen(true)}>
          {buttonContent}
        </div>
        <DsConfirmationModal 
          isOpen={open}
          onCancel={() => setOpen(false)}
          onConfirm={handleDelete}
          title={'delete_doc_modal.title'}
          titlei18nOptions={{ type }}
          message={'delete_doc_modal.message'}
          messagei18nOptions={{ type }}
          hasConfirmationInpupt
          confirmationText={type}
        />
      </>
  ) : null;
}
