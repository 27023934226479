import {
  collection,
  collectionGroup,
  CollectionReference,
} from "firebase/firestore";

import { AccountRequestType } from "@/domain/models/account-request";
import { ActivityType } from "@/domain/models/activity";
import { BinType } from "@/domain/models/bin";
import { ConfigurationType } from "@/domain/models/configuration";
import { CustomerType } from "@/domain/models/customer";
import { EventType } from "@/domain/models/event";
import { FirestoreDataType, ResourceEnum } from "@/domain/models/firebase";
import {
  JobType,
  NotesType,
  ServicesType,
  TasksType,
} from "@/domain/models/job";
import { SiteType } from "@/domain/models/site";
import { StatType } from "@/domain/models/stat";
import { UserType } from "@/domain/models/user";
import { firestore } from "@/firebase.config";

function createCollection<T>(collectionName: string) {
  return collection(firestore, collectionName) as CollectionReference<
    FirestoreDataType<T>
  >;
}

function createCollectionGroup<T>(collectionName: string) {
  return collectionGroup(firestore, collectionName) as CollectionReference<
    FirestoreDataType<T>
  >;
}

export const SpikeSosDb = {
  users: createCollection<UserType>(ResourceEnum.USERS),
  stats: createCollection<StatType>(ResourceEnum.STATS),
  sites: createCollection<SiteType>(ResourceEnum.SITES),
  jobs: createCollection<JobType>(ResourceEnum.JOBS),
  events: createCollection<EventType>(ResourceEnum.EVENTS),
  customers: createCollection<CustomerType>(ResourceEnum.CUSTOMERS),
  configurations: createCollection<ConfigurationType>(
    ResourceEnum.CONFIGURATIONS,
  ),
  bins: createCollection<BinType>(ResourceEnum.BINS),
  activity: createCollection<ActivityType>(ResourceEnum.ACTIVITY),
  accountRequests: createCollection<AccountRequestType>(
    ResourceEnum.ACCOUNT_REQUESTS,
  ),
  services: createCollectionGroup<ServicesType>(ResourceEnum.SERVICES),
  tasks: createCollectionGroup<TasksType>(ResourceEnum.TASKS),
  notes: createCollectionGroup<NotesType>(ResourceEnum.NOTES),
};
