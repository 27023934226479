import { useQuery } from "@tanstack/react-query";

import { BinFiltersType } from "@/src/domain/bin";

import { BinApi } from "../api/BinApi";

export const BinQueryKeys = {
  all: ["bins"] as const,
  list: (filters?: BinFiltersType) =>
    filters
      ? ([...BinQueryKeys.all, "list", { filters }] as const)
      : ([...BinQueryKeys.all, "list"] as const),
  details: (binId: string) => [...BinQueryKeys.all, binId] as const,
};

type BinsListQueryType = {
  filters?: BinFiltersType;
};

export const useBinsListQuery = (params?: BinsListQueryType) => {
  const { filters } = params ?? {};

  return useQuery({
    queryKey: BinQueryKeys.list(filters),
    queryFn: () => BinApi.listBins(filters),
  });
};

export const useBinQuery = ({ binId }: { binId: string }) => {
  return useQuery({
    queryKey: BinQueryKeys.details(binId),
    queryFn: () => BinApi.getBin(binId),
  });
};
