import { User } from "firebase/auth";
import { FirebaseStorage } from "firebase/storage";
import { z } from "zod";

import {
  BinMaterialEnum,
  BinSizeEnum,
  BinTaskEnum,
  CleanupTypeEnum,
  ContactType,
  CustomerType,
  SchedulePreferenceEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";
import {
  JobConnectedTaskSummaryType,
  JobType,
  ServicesType,
} from "@/domain/models/job";
import { SiteType } from "@/domain/models/site";

export const ServiceTypeEnumSchema = z.enum([
  ServiceTypeEnum.BIN,
  ServiceTypeEnum.JUNK,
]);

export type ServiceType = z.infer<typeof ServiceTypeEnumSchema>;

export const BinTaskEnumSchema = z.enum([
  BinTaskEnum.DROPOFF,
  BinTaskEnum.PICKUP,
  BinTaskEnum.EXCHANGE,
  BinTaskEnum.DUMPANDRETURN,
  BinTaskEnum.WAITANDLOAD,
]);

export type BinTaskEnumType = z.infer<typeof BinTaskEnumSchema>;

export const CleanupTypeEnumSchema = z.enum([
  CleanupTypeEnum.NONE,
  CleanupTypeEnum.ROUGHSWEEP,
  CleanupTypeEnum.FINESWEEP,
  CleanupTypeEnum.FULLDETAIL,
]);

export type CleanupTypeEnumType = z.infer<typeof CleanupTypeEnumSchema>;

export const BinSizeEnumSchema = z.enum([
  BinSizeEnum.FIVE,
  BinSizeEnum.SIX,
  BinSizeEnum.TEN,
  BinSizeEnum.FOURTEEN,
  BinSizeEnum.TWENTY,
]);

export type BinSizeEnumType = z.infer<typeof BinSizeEnumSchema>;

export const SchedulePreferenceEnumSchema = z.enum([
  SchedulePreferenceEnum.FLEXIBLE,
  SchedulePreferenceEnum.MORNING,
  SchedulePreferenceEnum.AFTERNOON,
]);

export type SchedulePreferenceEnumType = z.infer<
  typeof SchedulePreferenceEnumSchema
>;

export type JobFormSubmissionType = {
  binTask: BinTaskEnum | null;
  cleanupType: CleanupTypeEnum | null;
  contact: ContactType | null;
  customer: CustomerType;
  date: Date | null;
  hasBin: boolean;
  hasJunk: boolean;
  site: SiteType;
  adminNotes?: string | null;
  notes?: string | null;
  schedulePreference: SchedulePreferenceEnum | null;
  size: BinSizeEnum | null;
  customerNote?: string | null;
  taskNotes?: string | null;
  type: ServiceTypeEnum | null;
  user?: User | null;
  connectedTask?: ServicesType | null;
  material: BinMaterialEnum | null;
  yardAmount: number | null;
  images: File[] | null;
};

export type JobFormType = {
  customer: string;
  contact?: string;
  site: string;
  type: ServiceType | null;
  binTask: BinTaskEnumType | null;
  cleanupType: CleanupTypeEnumType | null;
  size: BinSizeEnumType | null;
  date: Date | null;
  notes: string;
  adminNotes: string;
  schedulePreference: SchedulePreferenceEnumType | null;
  taskNotes: string;
  connectedTask: string | null;
  material: BinMaterialEnum | null;
  yardAmount: number | null;
  images: File[] | null;
};

export type UpdateJobPropsType = Partial<JobType> & {
  jobId: string;
  serviceId?: string;
  taskId?: string;
  type?: ServiceTypeEnum;
};

export type JobDetailFormType = {
  type: ServiceType;
  binTask: BinTaskEnum | null;
  dueAt: Date;
  contact: string;
  connectedTask: JobConnectedTaskSummaryType | null;
  size: string;
  adminNotes?: string;
  schedulePreference: SchedulePreferenceEnum;
  taskNotes?: string;
  assignedDriver?: string;
  cleanupType: CleanupTypeEnum | null;
  material?: BinMaterialEnum;
  yardAmount?: number;
};
