import React, { useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Controller, useForm } from "react-hook-form";

import {
  BinTaskEnum,
  ContactType,
  SchedulePreferenceEnum,
  ServiceTypeEnum,
} from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";

import {
  DsButton,
  DsComboBox,
  DsDatePicker,
  DsDivider,
  DsInput,
  DsSelectBox,
  DsSelectBoxSelectedItem,
  DsText,
  DsTextArea,
  DsToggleGroup,
} from "../components/ui";
import { useCreateJob } from "../data/mutations/jobMutations";
import { useCustomersListQuery } from "../data/queries/customerQueries";
import { useActiveServicesQuery } from "../data/queries/serviceQueries";
import { useSitesListQuery } from "../data/queries/siteQueries";
import { JobFormType } from "../domain/job";
import {
  getBinMaterialOptions,
  getBinSizeOptions,
  getBinTaskOptions,
} from "../utils/bin";
import {
  getCleanupTypeOptions,
  getSchedulingPreferenceOptions,
} from "../utils/job";
import { getServiceTypeOptions } from "../utils/service";
import { successToast } from "../utils/toast";
import { ImageUploadController } from "./ImageUploadController";
import { QuickSiteCreationBox } from "./QuickSiteCreationBox";

type JobCreationFormPropsType =
  | {
      onPanelClose: () => void;
      defaultValues: Partial<JobFormType>;
      isCreatingBinRelatedJob: true;
    }
  | {
      onPanelClose: () => void;
      defaultValues?: Partial<JobFormType>;
      isCreatingBinRelatedJob?: undefined;
    };
const JobCreationForm: React.FC<JobCreationFormPropsType> = ({
  onPanelClose,
  defaultValues,
  isCreatingBinRelatedJob,
}) => {
  const { control, handleSubmit, watch, reset, setValue, trigger } =
    useForm<JobFormType>({
      defaultValues: {
        customer: "",
        site: "",
        binTask: null,
        cleanupType: null,
        size: null,
        date: null,
        ...defaultValues,
      },
    });

  const selectedCustomer = watch("customer");
  const selectedSiteId = watch("site");
  const selectedContactEmail = watch("contact");
  const selectedServiceType = watch("type");
  const selectedBinTask = watch("binTask");
  const selectedBinSize = watch("size");
  const selectedCleanupType = watch("cleanupType");
  const dropoffDate = watch("date");
  const selectedService = watch("connectedTask");

  const { data: customers, isLoading: isLoadingCustomers } =
    useCustomersListQuery();

  const { data: sites, isLoading: isLoadingSites } = useSitesListQuery({
    filters: { customerId: selectedCustomer },
    enabled: Boolean(selectedCustomer),
  });
  const selectedSite = sites?.find(item => {
    if (isCreatingBinRelatedJob) return item.address === selectedSiteId;
    return item.id === selectedSiteId;
  });

  const { data: services, isLoading: isLoadingServices } =
    useActiveServicesQuery({
      filters: {
        customerId: selectedCustomer,
        type: selectedServiceType,
        address: isCreatingBinRelatedJob
          ? defaultValues.site
          : selectedSite?.address,
      },
      enabled: selectedServiceType === ServiceTypeEnum.BIN,
    });

  const { t } = useTranslator();
  const { mutate: createJob, isPending: isJobPending } = useCreateJob();

  const onSubmit = (data: JobFormType) => {
    const customer = customers?.find(item => item.id === data.customer);
    const connectedTask = services?.find(
      item => item.assignedBin?.id === data.connectedTask,
    );
    const contact =
      siteContact.find(contact => contact.email === selectedContactEmail) ??
      null;
    if (!customer || !selectedSite) return;
    createJob(
      {
        ...data,
        connectedTask,
        customer,
        contact,
        site: selectedSite,
        hasBin: Boolean(data.type === ServiceTypeEnum.BIN),
        hasJunk: Boolean(data.type === ServiceTypeEnum.JUNK),
      },
      {
        onSuccess: () => {
          onPanelClose();
          successToast({ text: t("toast.create", { key: "Job" }) });
        },
      },
    );
  };

  useEffect(() => {
    if (isCreatingBinRelatedJob) return;
    if (services?.length === 1) {
      setValue("connectedTask", services[0].id);
    }
  }, [services, setValue]);

  useEffect(() => {
    if (isCreatingBinRelatedJob) return;
    if (selectedCustomer !== "") {
      reset({ customer: selectedCustomer }, { keepValues: false });
    }
  }, [selectedCustomer, reset]);

  const siteContact = (
    Array.isArray(selectedSite?.contact)
      ? selectedSite.contact
      : [selectedSite?.contact]
  ) as ContactType[];

  const contactOptions = siteContact.map((item: ContactType | undefined) => ({
    label: item?.name ?? "",
    value: item?.email ?? "",
  }));

  return (
    <form
      onSubmit={e => {
        void handleSubmit(onSubmit)(e);
      }}
      className="mt-2 mb-24">
      <div className="space-y-4">
        <DsText variant="button">
          {t("add_new_job_drawer.select_a_customer")}
        </DsText>
        <Controller
          name="customer"
          rules={{
            required: t("add_new_job_drawer.rules.required", {
              key: "customer",
            }),
          }}
          control={control}
          render={({ field }) =>
            isCreatingBinRelatedJob ? (
              <DsInput
                value={
                  customers?.find(customer => customer.id === field.value)?.name
                }
                readOnly
              />
            ) : (
              <DsComboBox
                selectedValue={field.value}
                onSelect={e => field.onChange(e)}
                isLoading={isLoadingCustomers}
                items={customers?.map(customer => ({
                  label: customer.name,
                  value: customer.id,
                }))}
              />
            )
          }
        />
        {Boolean(selectedCustomer) && (
          <>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.select_site")}
            </DsText>
            <Controller
              name="site"
              rules={{
                required: t("add_new_job_drawer.rules.required", {
                  key: "site",
                }),
              }}
              control={control}
              render={({ field }) =>
                isCreatingBinRelatedJob ? (
                  <DsInput readOnly value={selectedSite?.address} />
                ) : (
                  <DsComboBox
                    selectedValue={field.value}
                    onSelect={e => field.onChange(e)}
                    isLoading={isLoadingSites}
                    items={sites?.map(site => ({
                      label: site.address,
                      value: site.id,
                    }))}
                  />
                )
              }
            />
            {!selectedSite && (
              <QuickSiteCreationBox
                customerId={selectedCustomer}
                setSiteValue={newSite => {
                  setValue("site", newSite);
                }}
              />
            )}
          </>
        )}
        {Boolean(selectedSite) && (
          <>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.select_contact")}
            </DsText>
            <Controller
              name="contact"
              control={control}
              defaultValue={siteContact?.[0].email}
              render={({ field }) => (
                <DsSelectBox
                  selectedValue={field.value ?? ""}
                  onSelect={e => field.onChange(e)}
                  items={contactOptions}
                />
              )}
            />
          </>
        )}
        {Boolean(selectedSite) && (
          <>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.select_a_service")}
            </DsText>
            <Controller
              name="type"
              defaultValue={
                isCreatingBinRelatedJob ? defaultValues?.type : null
              }
              rules={{
                required: t("add_new_job_drawer.rules.required", {
                  key: "service type",
                }),
              }}
              control={control}
              render={({ field }) => (
                <DsToggleGroup
                  ref={field.ref}
                  options={getServiceTypeOptions(
                    isCreatingBinRelatedJob ? ServiceTypeEnum.JUNK : undefined,
                  )}
                  value={field.value}
                  onChange={e => field.onChange(e)}
                />
              )}
            />
          </>
        )}
        {selectedServiceType === ServiceTypeEnum.BIN && (
          <>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.select_a_task")}
            </DsText>
            <Controller
              name="binTask"
              rules={{
                required: t("add_new_job_drawer.rules.required", {
                  key: "bin task type",
                }),
              }}
              control={control}
              render={({ field }) => (
                <DsToggleGroup
                  ref={field.ref}
                  options={getBinTaskOptions(isCreatingBinRelatedJob)}
                  value={field.value}
                  onChange={e => field.onChange(e)}
                />
              )}
            />
            {selectedBinTask && (
              <DsText className="flex my-4 text-gray-700 font-medium">
                <InformationCircleIcon
                  width={20}
                  height={20}
                  className="mr-1"
                />
                {t(`add_new_job_drawer.bin_task_tip.${selectedBinTask}`)}
              </DsText>
            )}
            {selectedBinTask &&
              (selectedBinTask === BinTaskEnum.DROPOFF ||
                selectedBinTask === BinTaskEnum.EXCHANGE ||
                selectedBinTask === BinTaskEnum.WAITANDLOAD) && (
                <>
                  <DsDivider />
                  <DsText variant="button">
                    {t("add_new_job_drawer.select_bin_size")}
                  </DsText>
                  <Controller
                    name="size"
                    control={control}
                    defaultValue={
                      isCreatingBinRelatedJob ? defaultValues.size : null
                    }
                    render={({ field }) => (
                      <DsToggleGroup
                        ref={field.ref}
                        options={getBinSizeOptions(
                          isCreatingBinRelatedJob ? field.value : undefined,
                        )}
                        value={field.value}
                        onChange={e => field.onChange(e)}
                      />
                    )}
                  />
                  {selectedBinSize && (
                    <>
                      <DsDivider />
                      <DsText variant="button">
                        {t("add_new_job_drawer.aggregate")}
                      </DsText>
                      <Controller
                        name="material"
                        defaultValue={null}
                        control={control}
                        rules={{
                          validate: (value, formValues) => {
                            if (!value && formValues.yardAmount)
                              return t(
                                "add_new_job_drawer.rules.material_required",
                              );
                            return true;
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <DsSelectBox
                            ref={field.ref}
                            selectedValue={field.value ?? ""}
                            onSelect={e => {
                              field.onChange(e);
                              void trigger(["material", "yardAmount"]);
                            }}
                            items={[...getBinMaterialOptions(true)]}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                      <Controller
                        name="yardAmount"
                        defaultValue={null}
                        control={control}
                        rules={{
                          validate: (value, formValues) => {
                            if (!value && formValues.material)
                              return t(
                                "add_new_job_drawer.rules.aggregate_amount_required",
                              );
                            return true;
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <DsInput
                            value={field.value ?? ""}
                            type="number"
                            placeholder="add_new_job_drawer.placeholder.amount_in_cubic_yards"
                            onChange={e => {
                              field.onChange(e.target.valueAsNumber);
                              void trigger(["material", "yardAmount"]);
                            }}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </>
                  )}
                </>
              )}
            {/*For type exchange, bin size should be selected to show bin on site*/}
            {selectedBinTask &&
              (selectedBinTask === BinTaskEnum.PICKUP ||
                (selectedBinTask === BinTaskEnum.EXCHANGE && selectedBinSize) ||
                selectedBinTask === BinTaskEnum.DUMPANDRETURN) && (
                <>
                  <DsDivider />
                  <DsText variant="button">
                    {t("add_new_job_drawer.select_bin_on_ite")}
                  </DsText>
                  <Controller
                    name="connectedTask"
                    control={control}
                    defaultValue={
                      isCreatingBinRelatedJob
                        ? defaultValues.connectedTask
                        : null
                    }
                    render={({ field }) =>
                      isCreatingBinRelatedJob ? (
                        <DsInput
                          value={
                            services?.find(
                              item => item?.assignedBin?.id === field.value,
                            )?.assignedBin?.identifier
                          }
                          readOnly
                        />
                      ) : (
                        <DsSelectBox
                          ref={field.ref}
                          selectedItem={
                            !selectedService ? (
                              <DsSelectBoxSelectedItem label="" />
                            ) : undefined
                          }
                          isLoading={isLoadingServices}
                          items={services?.map(item => ({
                            label: item.assignedBin?.identifier ?? "",
                            value: item.assignedBin?.id ?? "",
                          }))}
                          selectedValue={field.value ?? ""}
                          onSelect={e => field.onChange(e)}
                        />
                      )
                    }
                  />
                </>
              )}
            {/*The bin size should be selected under options dropoff and waitload*/}
            {(selectedBinTask === BinTaskEnum.DROPOFF ||
            selectedBinTask === BinTaskEnum.WAITANDLOAD
              ? Boolean(selectedBinSize)
              : selectedBinTask === BinTaskEnum.PICKUP ||
                selectedBinTask === BinTaskEnum.EXCHANGE ||
                selectedBinTask === BinTaskEnum.DUMPANDRETURN) && (
              <>
                <DsDivider />
                <DsText variant="button">
                  {t("add_new_job_drawer.drop_off_date")}
                </DsText>
                <Controller
                  name="date"
                  rules={{
                    required: t("add_new_job_drawer.rules.required", {
                      key: "date",
                    }),
                  }}
                  control={control}
                  render={({ field }) => (
                    <DsDatePicker
                      ref={field.ref}
                      value={field.value}
                      placeholder="add_new_job_drawer.placeholder.select_date"
                      onChange={e => field.onChange(e)}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
        {selectedServiceType === ServiceTypeEnum.JUNK && (
          <>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.interior_cleanup_type")}
            </DsText>
            <Controller
              name="cleanupType"
              rules={{
                required: t("add_new_job_drawer.rules.required", {
                  key: "cleanup type",
                }),
              }}
              control={control}
              render={({ field }) => (
                <DsToggleGroup
                  ref={field.ref}
                  options={getCleanupTypeOptions()}
                  value={field.value}
                  onChange={e => field.onChange(e)}
                />
              )}
            />
            {selectedCleanupType && (
              <DsText className="flex my-4 text-gray-500 font-medium">
                <InformationCircleIcon
                  width={20}
                  height={20}
                  className="mr-1 shrink-0"
                />
                {t(
                  `add_new_job_drawer.cleanup_type_tip.${selectedCleanupType}`,
                )}
              </DsText>
            )}
            {selectedCleanupType && (
              <>
                <DsDivider />
                <DsText variant="button">{t("add_new_job_drawer.date")}</DsText>
                <Controller
                  name="date"
                  rules={{
                    required: t("add_new_job_drawer.rules.required", {
                      key: "date",
                    }),
                  }}
                  control={control}
                  render={({ field }) => (
                    <DsDatePicker
                      ref={field.ref}
                      value={field.value}
                      placeholder="add_new_job_drawer.placeholder.select_date"
                      onChange={e => field.onChange(e)}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
        {dropoffDate && (
          <>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.scheduling_preference")}
            </DsText>
            <Controller
              name="schedulePreference"
              defaultValue={SchedulePreferenceEnum.FLEXIBLE}
              rules={{
                required: t("add_new_job_drawer.rules.required", {
                  key: "scheduling perference",
                }),
              }}
              control={control}
              render={({ field }) => (
                <DsToggleGroup
                  ref={field.ref}
                  variant="flex"
                  options={getSchedulingPreferenceOptions()}
                  value={field.value}
                  onChange={e => field.onChange(e)}
                />
              )}
            />
            <DsDivider />
            <div>
              <DsText variant="button">{t("add_new_job_drawer.notes")}</DsText>
              <Controller
                name="notes"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <DsTextArea
                    ref={field.ref}
                    value={field.value}
                    onChange={e => field.onChange(e)}
                    placeholder={t(
                      "add_new_job_drawer.placeholder.driver_note",
                    )}
                  />
                )}
              />
              <ImageUploadController
                control={control}
                setValue={setValue}
                value={watch("images")}
              />
            </div>
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.admin_notes")}
            </DsText>
            <Controller
              name="adminNotes"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <DsTextArea
                  ref={field.ref}
                  value={field.value}
                  onChange={e => field.onChange(e)}
                  placeholder={t("add_new_job_drawer.admin_notes")}
                />
              )}
            />
            <DsDivider />
            <DsText variant="button">
              {t("add_new_job_drawer.scheduler_task_note")}
            </DsText>
            <Controller
              name="taskNotes"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <DsTextArea
                  ref={field.ref}
                  value={field.value}
                  onChange={e => field.onChange(e)}
                  size="sm"
                  placeholder={t("add_new_job_drawer.placeholder.task_note")}
                />
              )}
            />
            <DsDivider />
          </>
        )}
        {dropoffDate && (
          <DsButton
            type="submit"
            disabled={isJobPending}
            isLoading={isJobPending}>
            {t("add_new_job_drawer.submit_button")}
          </DsButton>
        )}
      </div>
    </form>
  );
};

export { JobCreationForm };
