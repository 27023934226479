import { addDoc, collection, serverTimestamp } from "@firebase/firestore";
import React, { useRef, useState } from "react";
import { useFirestore, useUser } from "reactfire";
import getRelativeDate from "../utils/getRelativeDate";
import DeleteDocModal from "./DeleteDocModal";
import ImgFromPath from "./ImgFromPath";
import UploadFile from "./UploadFile";
import { useQueryClient } from "@tanstack/react-query";
import { NotesQueryKeys } from "../data/queries/notesQueries";

function NotesFeed({ notes, path, ids, customer = false }) {
  const firestore = useFirestore();
  const { status, data: user } = useUser();
  const newNoteRef = useRef();
  const [files, setFiles] = useState([]);

  const queryClient = useQueryClient()

  const handleSubmit = (e) => {
    e.preventDefault();
    const noteBody = newNoteRef.current.value;
    const note = {
      note: noteBody,
      createdAt: serverTimestamp(),
      createdBy: {
        id: user.uid,
        photoURL: user.photoURL,
        name: user.displayName,
      },
      files,
      ...ids,
    };
    const notesCollection = collection(firestore, path);
    addDoc(notesCollection, note)
      .then(() => {
        newNoteRef.current.value = "";
        setFiles([]);

        // Invalidate task notes query to reflect Dashboard icon
        queryClient.invalidateQueries({ queryKey: NotesQueryKeys.all });
      })
      .catch((e) => {
        alert("There was an error adding your note. Please try again.");
        newNoteRef.current.value = noteBody;
      });
  };

  // console.log("notes", notes);
  if (status === "loading") {
    return <div>Loading User...</div>;
  }
  return (
    <div>
      <section aria-labelledby="notes-title">
        <div className="bg-white sm:rounded-lg sm:overflow-hidden">
          <div className="">
            <h2
              id="notes-title"
              className="text-lg font-medium text-gray-900 sr-only"
            >
              Notes
            </h2>
            <div className="">
              <ul role="list" className="flex flex-col gap-4 divide-y">
                {notes?.length > 0 &&
                  notes.map((note) => (
                    <li key={note.id} className="pt-4 last-of-type:pb-4 px-4">
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full border"
                            src={note.createdBy.photoURL}
                            alt=""
                          />
                        </div>
                        <div className="w-full flex">
                          <div>
                            <div className="text-sm flex">
                              <p className="font-medium text-gray-900">
                                {note.createdBy.name}
                              </p>
                            </div>
                            <div className="mt-1 text-md text-gray-700">
                              <p className="">{note.note}</p>
                            </div>
                            {note.files?.length > 0 && (
                              <div className="flex gap-4 my-4 flex-wrap">
                                {note.files.map((file, index) => (
                                  <ImgFromPath
                                    key={index}
                                    className="w-12 h-12 object-cover rounded shadow"
                                    path={file}
                                  />
                                ))}
                              </div>
                            )}
                            <div className="mt-2 text-xs space-x-2">
                              <span className="text-gray-500 font-medium">
                                {getRelativeDate(note.createdAt?.toDate())}
                              </span>
                            </div>
                          </div>
                          {!customer && (
                            <DeleteDocModal
                              buttonContent={<span>&times;</span>}
                              buttonClassName="ml-auto leading-[.5] cursor-pointer p-2 -top-2 -right-2 relative text-gray-500"
                              path={`jobs/${note.jobId}/${
                                note.serviceId
                                  ? `services/${note.serviceId}/${
                                      note.taskId
                                        ? `tasks/${note.taskId}/notes/${note.id}`
                                        : `notes/${note.id}`
                                    }`
                                  : `notes/${note.id}`
                              }`}
                              type="note"
                            />
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {!customer && (
            <>
              <div className="bg-gray-50 px-4 py-6 sm:px-6">
                <div className="flex space-x-3">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full border"
                      src={user.photoURL}
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <form action="#">
                      <div className="mb-2">
                        <label htmlFor="comment" className="sr-only">
                          About
                        </label>
                        <textarea
                          ref={newNoteRef}
                          id="comment"
                          name="comment"
                          rows={3}
                          className="shadow-sm block w-full py-2 px-3 focus:ring-yellow-300 focus:border-yellow-300 sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Add a note"
                          defaultValue={""}
                        />
                      </div>

                      <div className="flex gap-4">
                        {files.map((file, index) => (
                          <ImgFromPath
                            key={index}
                            className="w-12 h-12 object-cover rounded shadow"
                            path={file}
                          />
                        ))}
                      </div>

                      <UploadFile
                        multiple
                        basePath={path}
                        baseName={"notefile"}
                        onUploadComplete={(photoRef) => {
                          // // console.log("adding photo", photoRef);
                          setFiles((files) => photoRef);
                        }}
                      />

                      <div className="mt-3 flex items-center justify-between">
                        <button
                          onClick={handleSubmit}
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-500 bg-[#ffe502] hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                        >
                          Add Note
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default NotesFeed;
