import { Timestamp } from "@firebase/firestore";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(weekday);

export type DateType = dayjs.ConfigType;

export const dateAsDayjs = (
  date?: DateType,
  format?: dayjs.OptionType,
): Dayjs => {
  return dayjs(date, format);
};

export const formatDate = (date: DateType): string =>
  dateAsDayjs(date).format("DD/MM/YYYY");

export const formatCalendarWeekDay = (date: DateType): string =>
  dateAsDayjs(date).format("ddd,");

export const formatCalendarMonthAndDate = (date: DateType): string =>
  dateAsDayjs(date).format("MMM D");

export const formatCalendarDate = (date: DateType): string =>
  dateAsDayjs(date).format(`ddd, MMM D`);

export const getDayjsRange = ({
  start,
  end,
  unit,
}: {
  start: DateType;
  end: DateType;
  unit: ManipulateType;
}) => {
  const range = [];
  let current = dayjs(start);

  while (!current.isAfter(end)) {
    range.push(current);
    current = current.add(1, unit);
  }
  return range;
};

export const generateFirebaseTimestamp = (date?: Date | null) => {
  const dt = date ?? new Date();

  return Timestamp.fromDate(dt);
};
