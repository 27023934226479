import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SiteFormType } from "@/src/domain/site";

import { SiteApi } from "../api/SiteApi";
import { SiteQueryKeys } from "../queries/siteQueries";

export const useAddSite = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, unknown, SiteFormType>({
    mutationFn: (siteData: SiteFormType) => SiteApi.addSite(siteData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: SiteQueryKeys.all });
    },
  });

  return mutation;
};
