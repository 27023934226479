import {
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { FirestoreDataType } from "@/domain/models/firebase";
import { ServicesType } from "@/domain/models/job";
import {
  GetServicePropsType,
  ServiceFiltersType,
  UpdateServicePropsType,
} from "@/src/domain/service";
import { logger } from "@/src/utils/logger";

import { SpikeSosDb } from "./SpikesosDb";

export const ServiceApi = {
  getRef: ({ jobId, serviceId }: { jobId: string; serviceId: string }) => {
    return doc(
      SpikeSosDb.jobs,
      `${jobId}/services/${serviceId}`,
    ) as DocumentReference<FirestoreDataType<ServicesType>>;
  },
  listServices: async (filters?: ServiceFiltersType) => {
    try {
      const servicesCollection = SpikeSosDb.services;

      const constraints = [];

      if (filters?.assignedBinStatus) {
        constraints.push(where("status", "==", filters.assignedBinStatus));
      }
      if (filters?.type) {
        constraints.push(where("type", "==", filters.type));
      }
      if (filters?.address) {
        constraints.push(where("address", "==", filters.address));
      }
      if (filters?.customerId) {
        constraints.push(where("customer.id", "==", filters.customerId));
      }
      const servicesQuery = query(servicesCollection, ...constraints);

      // Execute the query and return the results
      const querySnapshot = await getDocs(servicesQuery);
      const services = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as unknown as FirestoreDataType<ServicesType>),
      }));

      return services;
    } catch (error) {
      logger.error("Error fetching services", error);

      throw error;
    }
  },
  getService: async ({ jobId, serviceId }: GetServicePropsType) => {
    try {
      if (!jobId || !serviceId) {
        throw new Error("Missing required parameters");
      }

      const docRef = doc(SpikeSosDb.jobs, `${jobId}/services/${serviceId}`);
      const document = await getDoc(docRef);

      if (!document.exists()) {
        throw new Error("Service not found");
      }

      return {
        id: document.id,
        ...(document.data() as unknown as FirestoreDataType<ServicesType>),
      };
    } catch (error) {
      logger.error("Error fetching service", error);

      throw error;
    }
  },

  updateService: async (params: UpdateServicePropsType) => {
    try {
      const { serviceId, ...serviceParams } = params;
      const { jobId } = serviceParams;

      if (!jobId || !serviceId) {
        throw new Error("Missing required parameters");
      }

      const serviceRef = ServiceApi.getRef({ jobId, serviceId });

      await updateDoc(serviceRef, serviceParams);

      return params;
    } catch (error) {
      logger.error("Error updating service", error);

      throw error;
    }
  },
};
