import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { TransProps } from "react-i18next";

import { I18nKeyType } from "@/i18n";

import { DsIcon, DsLoading, DsTranslator } from "./ui";

type RightPanelPropsType = {
  children?: React.ReactNode;
  content: React.ReactNode;
  title: string;
  subtitle?: TransProps<I18nKeyType>;
  autoOpen?: boolean;
  controlledOpen?: boolean;
  setControlledOpen?: (value: boolean) => void;
  isLoading?: boolean;
  onDrawerClosed?: () => void;
};

const RightPanel: FC<RightPanelPropsType> = ({
  children,
  content,
  title,
  subtitle,
  autoOpen = false,
  controlledOpen = false,
  isLoading,
  onDrawerClosed,
}) => {
  const [isOpen, setIsOpen] = useState(controlledOpen);

  useEffect(() => {
    setIsOpen(controlledOpen);
  }, [controlledOpen, onDrawerClosed]);

  const onClosePress = () => {
    setIsOpen(false);

    onDrawerClosed?.();
  };

  return (
    <>
      {!autoOpen && children && (
        <span onClick={() => setIsOpen(true)}>{children}</span>
      )}
      <Dialog open={isOpen} onClose={() => null} className="relative z-30">
        <DialogBackdrop
          transition
          className="transform transition fixed inset-0 bg-gray-500 bg-opacity-75 duration-300 ease-in-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-0 md:left-auto flex max-w-full pl-0 md:pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-300 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="py-6 bg-[#ffe502] px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        {title}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <DsIcon icon="Close" onClick={onClosePress} />
                      </div>
                    </div>
                    {subtitle && (
                      <div className="mt-1">
                        <DsTranslator
                          className="text-sm text-black"
                          i18nProps={subtitle}
                        />
                      </div>
                    )}
                  </div>
                  <div className="relative mt-3 flex-1 px-5 sm:px-6">
                    {isLoading ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <DsLoading />
                      </div>
                    ) : (
                      <div className="inset-0">{content}</div>
                    )}
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export { RightPanel };
