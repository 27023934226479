import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { upperFirst } from "lodash";

import { classNames } from "@/src/utils/classNames";
import { RentalStatusType } from "@/src/utils/strings";

const binStatusVariants = cva("rounded-full", {
  variants: {
    variant: {
      available: "bg-[#5CE76F]",
      rented: "bg-[#EAEAEA]",
      overdue: "bg-[#D03E45] text-white",
    },
    styleFormat: {
      circle: "h-3.5 w-3.5",
      labeled: "inline px-4 py-1",
    },
  },
  defaultVariants: {
    variant: "available",
    styleFormat: "circle",
  },
});

export type BinStatusVariantsType = VariantProps<typeof binStatusVariants>;

export type BinStatusPropsType = React.BaseHTMLAttributes<HTMLDivElement> &
  BinStatusVariantsType & {
    status?: RentalStatusType;
  };

const DsBinStatus = React.forwardRef<HTMLButtonElement, BinStatusPropsType>(
  ({ className, variant, styleFormat, ...props }) => (
    <div
      className={classNames(
        binStatusVariants({ variant, className, styleFormat }),
      )}
      {...props}>
      {props.status && (
        <span className="text-sm font-semibold">
          {upperFirst(props.status)}
        </span>
      )}
    </div>
  ),
);
DsBinStatus.displayName = "DsBinStatus";

export { DsBinStatus };
