import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  AssignTaskToDriverParamsType,
  UpdateConnectedTaskType,
  UpdateTaskBaseType,
  UpdateTaskCleanupParamsType,
  UpdateTaskDueAtParamsType,
} from "@/src/domain/task";

import { TaskApi } from "../api/TaskApi";
import { JobQueryKeys } from "../queries/jobQueries";
import { ServiceQueryKeys } from "../queries/serviceQueries";
import { TaskQueryKeys } from "../queries/taskQueries";

export const useAssignTaskToDriverMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    AssignTaskToDriverParamsType,
    unknown,
    AssignTaskToDriverParamsType
  >({
    mutationFn: params => TaskApi.assignTaskToDriver(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};

export const useUnassignTaskFromDriverMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<UpdateTaskBaseType, unknown, UpdateTaskBaseType>(
    {
      mutationFn: params => TaskApi.unassignTaskFromDriver(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
      },
    },
  );

  return mutation;
};

export const useUpdateTaskDueAtMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateTaskDueAtParamsType,
    unknown,
    UpdateTaskDueAtParamsType
  >({
    mutationFn: params => TaskApi.updateTaskDueAt(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};

export const useUpdateCleanupTypeMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateTaskCleanupParamsType,
    unknown,
    UpdateTaskCleanupParamsType
  >({
    mutationFn: params => TaskApi.updateTaskCleanupType(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: JobQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};

export const useUpdateConnectedTaskMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateConnectedTaskType,
    unknown,
    UpdateConnectedTaskType
  >({
    mutationFn: params => TaskApi.updateConnectedTask(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ServiceQueryKeys.all });
      await queryClient.invalidateQueries({ queryKey: TaskQueryKeys.all });
    },
  });

  return mutation;
};
