import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { DsModal } from "./ui";

export const BUILD = 2.48;

const VersionCheck = () => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const firestore = useFirestore();
  const ref = doc(firestore, "configurations", "versions");
  const [outdated, setOutdated] = useState(false);
  const [moreTime, setMoreTime] = useState(false);

  const { status, data: versions } = useFirestoreDocData(ref);

  useEffect(() => {
    if (status === "loading") return;
    if (outdated !== parseFloat(BUILD) < parseFloat(versions.build))
      setOutdated(parseFloat(BUILD) < parseFloat(versions.build));
    if (!moreTime && outdated) {
      setOpen(true);
    }
  }, [outdated, versions, moreTime, status]);

  const getMoreTime = () => {
    setOpen(false);
    setMoreTime(true);
    window.setTimeout(() => {
      setMoreTime(false);
    }, 60000);
  };

  if (status === "loading") {
    return <span className="text-xs">checking build</span>;
  }

  const updateLink = `${window.location.protocol}//${window.location.hostname}${
    window.location.port !== "" ? `:${window.location.port}` : ""
  }${window.location.pathname}?updateTo=${versions?.build}`;

  const refreshApp = () => {
    window.location.reload(true);
  };

  return (
    <div className="mt-4">
      <div className="flex flex-col items-center justify-between">
        <span className="w-full text-center text-gray-700 text-xs">
          {outdated ? (
            <button onClick={refreshApp} className="text-red-500 underline">
              build {parseFloat(BUILD)} ({versions.build} available)
            </button>
          ) : (
            <span>build {BUILD}</span>
          )}
        </span>
      </div>
      <DsModal isOpen={open} canDismiss={false}>
        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-3 sm:align-middle sm:max-w-lg">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                App Outdated
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Your app is outdated. You are on build {BUILD}. Build{" "}
                  {versions.build} is available now.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              onClick={refreshApp}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed bg-red-500 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Refresh App
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={getMoreTime}
              ref={cancelButtonRef}
            >
              Give me a minute
            </button>
          </div>
        </div>
      </DsModal>
    </div>
  );
};

export default VersionCheck;
