import React, { forwardRef } from "react";
import { Checkbox } from "@headlessui/react";

import { classNames } from "@/src/utils/classNames";

import { DsIcon } from ".";

type DsCheckboxPropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  onChange?: (val: boolean) => void;
};

const DsCheckbox = forwardRef<HTMLInputElement, DsCheckboxPropsType>(
  ({ ...props }, ref) => {
    return (
      <Checkbox
        ref={ref}
        {...props}
        className={classNames(
          "group flex justify-center items-center size-5 rounded border-2 bg-white data-[checked]:bg-sos-red data-[checked]:border-0",
        )}>
        <DsIcon icon="CheckMark" />
      </Checkbox>
    );
  },
);

DsCheckbox.displayName = "DsCheckbox";

export { DsCheckbox };
