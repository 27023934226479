import {
  BinSizeEnum,
  BinTaskEnum,
  CleanupTypeEnum,
  ServiceTaskTypeEnum,
} from "@/domain/models/common";
import { i18n } from "@/i18n";

import { MATERIAL_LIST } from "../domain/constants";
import { formatBinSize } from "./strings";

export const convertTaskTypeToTaskId = (
  taskType: BinTaskEnum | CleanupTypeEnum,
) => {
  switch (taskType) {
    case BinTaskEnum.DROPOFF:
      return ServiceTaskTypeEnum.DROPBIN;
    case BinTaskEnum.DUMPANDRETURN:
      return ServiceTaskTypeEnum.DUMPANDRETURNBIN;
    case BinTaskEnum.EXCHANGE:
      return ServiceTaskTypeEnum.EXCHANGEBIN;
    case BinTaskEnum.PICKUP:
      return ServiceTaskTypeEnum.PICKUPBIN;
    case BinTaskEnum.WAITANDLOAD:
      return ServiceTaskTypeEnum.WAITANDLOADBIN;
    case CleanupTypeEnum.NONE:
      return ServiceTaskTypeEnum.JUNKREMOVAL;
    default:
      return ServiceTaskTypeEnum.JUNKREMOVAL;
  }
};

export const getBinTaskIds = () => {
  return [
    ServiceTaskTypeEnum.DROPBIN,
    ServiceTaskTypeEnum.DUMPANDRETURNBIN,
    ServiceTaskTypeEnum.EXCHANGEBIN,
    ServiceTaskTypeEnum.PICKUPBIN,
    ServiceTaskTypeEnum.WAITANDLOADBIN,
  ];
};

export const getJunkTaskIds = () => {
  return [ServiceTaskTypeEnum.JUNKREMOVAL];
};

export const getBinTaskOptions = (isCreatingBinRelatedJob?: boolean) => [
  {
    value: BinTaskEnum.DROPOFF,
    disabled: isCreatingBinRelatedJob,
    label: i18n.t("add_new_job_drawer.bin_task.drop_off"),
  },
  {
    value: BinTaskEnum.WAITANDLOAD,
    disabled: isCreatingBinRelatedJob,
    label: i18n.t("add_new_job_drawer.bin_task.wait_load"),
  },
  {
    value: BinTaskEnum.PICKUP,
    label: i18n.t("add_new_job_drawer.bin_task.pick_up"),
  },
  {
    value: BinTaskEnum.EXCHANGE,
    label: i18n.t("add_new_job_drawer.bin_task.exchange"),
  },
  {
    value: BinTaskEnum.DUMPANDRETURN,
    label: i18n.t("add_new_job_drawer.bin_task.dump_return"),
  },
];

export const getBinSizeOptions = (enabledValue?: BinSizeEnum | null) =>
  Object.values(BinSizeEnum).map(value => ({
    label: value
      .replace(/_/g, " ")
      .replace(/\d+/, match => formatBinSize(match as BinSizeEnum)),
    value,
    disabled: enabledValue ? value !== enabledValue : false,
  }));

export const getBinMaterialOptions = (hasNull?: boolean) => {
  const options = MATERIAL_LIST.map(item => ({
    label: i18n.t("add_new_job_drawer.bin_material_label", {
      material: item.material,
      unitPrice: item.unitPrice,
    }),
    value: item.material,
  }));
  if (hasNull) return [{ label: i18n.t("none"), value: "" }, ...options];
  return options;
};
