import Resizer from "react-image-file-resizer";

export const resizeFile = (file: File) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      1920, // width
      1920, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      80, // quality
      0, // rotation
      uri => {
        resolve(uri);
      },
      "base64",
    );
  });
