import { FC } from "react";

import { BinType } from "@/domain/models/bin";
import { DsText } from "@/src/components/ui/DsText";
import { classNames } from "@/src/utils/classNames";
import { getBinRentalStatus } from "@/src/utils/strings";

type BinRentalStatusType = {
  bin: BinType;
  className?: string;
};

const BinRentalStatus: FC<BinRentalStatusType> = ({ bin, className }) => {
  const binFormatedRentalStatus = getBinRentalStatus(bin);

  if (!binFormatedRentalStatus) return null;

  const { isOverdue, rentalFormattedStatus } = binFormatedRentalStatus;

  return (
    <DsText className={classNames(className, isOverdue && "text-[#D03E45]")}>
      {rentalFormattedStatus}
    </DsText>
  );
};

export { BinRentalStatus };
