import { FC, useCallback, useState } from "react";
import { DropResult } from "@hello-pangea/dnd";
import { useAtomValue } from "jotai";
import { upperFirst } from "lodash";

import { FirebaseDateType } from "@/domain/models/firebase";
import { TasksType } from "@/domain/models/job";
import { useTranslator } from "@/i18n/useTranslator";

import {
  DashboardCustomerNameText,
  DashboardTableNotesIcon,
  ServiceDetailsDrawer,
} from ".";
import {
  DsIcon,
  DsLoading,
  DsSchedulerNotes,
  DsTable,
  DsTaskTypeBadge,
  DsText,
} from "../components/ui";
import {
  useAssignTaskToDriverMutation,
  useUnassignTaskFromDriverMutation,
  useUpdateTaskDueAtMutation,
} from "../data/mutations/taskMutations";
import { useTasksListQuery } from "../data/queries/taskQueries";
import { useUserQuery } from "../data/queries/userQueries";
import { TASK_TIME_DURATION } from "../domain/constants";
import { DASHBOARD_TASKS_ORDER } from "../domain/task";
import { useThemeBreakPoints } from "../hooks/useThemeBreakPoints";
import {
  adminDashboardSelectedDateAtom,
  adminDashboardSelectedDriverIdAtom,
  adminDashboardSelectedTaskViewAtom,
} from "../stores/atoms";
import { getBinTaskIds, getJunkTaskIds } from "../utils/bin";
import {
  dateAsDayjs,
  formatCalendarDate,
  generateFirebaseTimestamp,
} from "../utils/date";
import { logger } from "../utils/logger";
import { sortArrayByCustomOrder } from "../utils/sorting";
import { formatDriverName } from "../utils/strings";
import { AssignDriverComponent } from "./AssignDriverComponent";

type DashboardTableDataType = {
  priorityComponent: JSX.Element;
  customerNameComponent: JSX.Element;
  location: string;
  taskComponent: JSX.Element;
  schedulerNotes: JSX.Element;
  notesComponent: JSX.Element;
  assignedDriverComponent: JSX.Element;
  jobId: string;
  serviceId: string;
  taskId: string;
  dueAt?: FirebaseDateType;
  className?: string;
  completed?: boolean;
};

type DashboardTablePropsType = {
  driverId?: string;
  isFromListOfDrivers?: boolean;
};

const DashboardTable: FC<DashboardTablePropsType> = ({
  driverId,
  isFromListOfDrivers = false,
}) => {
  const selectedDate = useAtomValue(adminDashboardSelectedDateAtom);
  const selectedDriverId = useAtomValue(adminDashboardSelectedDriverIdAtom);
  const selectedTaskView = useAtomValue(adminDashboardSelectedTaskViewAtom);
  const [selectedTask, setSelectedTask] =
    useState<DashboardTableDataType | null>(null);
  const [isServiceDetailsDrawerOpened, setIsServiceDetailsDrawerOpened] =
    useState(false);

  const { isLargeScreen } = useThemeBreakPoints();

  const { t } = useTranslator();

  const binTaskIds = getBinTaskIds();
  const junkTaskIds = getJunkTaskIds();

  const treatedDriverId = driverId ?? "";

  const { data: driverData } = useUserQuery({
    userId: treatedDriverId,
    enabled: Boolean(treatedDriverId),
  });

  const {
    mutate: assignTaskToDriver,
    isPending: isAssigningTaskToDriver,
    variables: assignTaskToDriverVariables,
  } = useAssignTaskToDriverMutation();

  const {
    mutate: unassignTaskFromDriver,
    isPending: isUnassigningTaskFromDriver,
    variables: unassignTaskFromDriverVariables,
  } = useUnassignTaskFromDriverMutation();

  const { mutateAsync: updateTaskDueAt } = useUpdateTaskDueAtMutation();

  const { data: rawTasksData, isLoading } = useTasksListQuery({
    filters: {
      dueAtStart: dateAsDayjs(selectedDate).startOf("day").toDate(),
      dueAtEnd: dateAsDayjs(selectedDate).endOf("day").toDate(),
      assignedTo: driverId,
      taskIds:
        selectedTaskView === "bins"
          ? binTaskIds
          : selectedTaskView === "junk"
            ? junkTaskIds
            : [...binTaskIds, ...junkTaskIds],
    },
  });

  const taskView =
    selectedTaskView !== "all" ? upperFirst(selectedTaskView) : "";

  const onSelectDriver = ({
    taskData,
    driverId,
  }: {
    taskData: TasksType;
    driverId: string;
  }) => {
    if (driverId) {
      assignTaskToDriver({
        ...taskData,
        assignedTo: driverId,
        selectedDate,
      });
    } else {
      unassignTaskFromDriver({
        ...taskData,
      });
    }
  };

  const isDriverTable = Boolean(driverId);

  let sortedRawTasksData = rawTasksData
    // If the driverId is not provided, we only show unassigned tasks
    // If the driverId is provided, it will be filtered by the query filters
    ?.filter(
      task =>
        (!driverId && (!task.assignedTo || task.assignedTo === "unassigned")) ||
        driverId,
    )
    .sort((a, b) => a.dueAt.toDate().getTime() - b.dueAt.toDate().getTime());

  if (!isDriverTable && sortedRawTasksData) {
    sortedRawTasksData = sortArrayByCustomOrder({
      array: sortedRawTasksData,
      key: "taskId",
      customOrder: DASHBOARD_TASKS_ORDER,
    });
  }

  const hasDragAndDrop = Boolean(
    driverId && sortedRawTasksData && sortedRawTasksData.length >= 2,
  );

  const tableData: DashboardTableDataType[] =
    sortedRawTasksData?.map(task => ({
      priorityComponent: (
        <DsIcon className="hover:cursor-pointer" icon="StartUnselected" />
      ),
      customerNameComponent: (
        <DashboardCustomerNameText
          jobId={task.jobId}
          serviceId={task.serviceId}
        />
      ),
      location: task.address,
      taskComponent: <DsTaskTypeBadge taskType={task.taskId} />,
      schedulerNotes: (
        <DsSchedulerNotes
          jobId={task.jobId}
          serviceId={task.serviceId}
          taskId={task.taskId}
        />
      ),
      notesComponent: (
        <DashboardTableNotesIcon
          jobId={task.jobId}
          serviceId={task.serviceId}
          taskId={task.taskId}
        />
      ),
      assignedDriverComponent: (
        <AssignDriverComponent
          taskType={task.taskId}
          taskAssignedTo={task.assignedTo}
          isLoading={
            (isAssigningTaskToDriver &&
              assignTaskToDriverVariables.jobId === task.jobId) ||
            (isUnassigningTaskFromDriver &&
              unassignTaskFromDriverVariables.jobId === task.jobId)
          }
          onSelectAssignedDriver={selectedDriverId => {
            onSelectDriver({ taskData: task, driverId: selectedDriverId });
          }}
          selectedAssignedDriverId={task.assignedTo ?? ""}
          isDriverTable={Boolean(driverId)}
          selectBoxClassName={hasDragAndDrop ? "md:pr-16 lg:pr-0" : ""}
          isTaskCompleted={task.completed}
        />
      ),
      jobId: task.jobId,
      serviceId: task.serviceId,
      taskId: task.taskId,
      dueAt: task.dueAt,
      className: task.completed ? "bg-[#DADADA]" : "",
      completed: task.completed,
    })) ?? [];

  const getTableTitle = useCallback(() => {
    const tableFor = driverId
      ? formatDriverName(driverData?.displayName ?? "")
      : t("dashboard_table.unassigned_title", { taskView });
    const titleDate = formatCalendarDate(selectedDate);

    return `${tableFor} - ${titleDate}`;
  }, [driverId, selectedDate, taskView, t, driverData]);

  const tableTitle = getTableTitle();

  const handleDragAndDrop = async (
    result: DropResult<string>,
    reorderedList: DashboardTableDataType[],
  ) => {
    if (!result.destination) {
      console.log(
        "Unable to reoder task list. Please refresh and make sure your latest changes were saved",
      ); // TODO: replace with the future toast message
      return;
    }

    const updatePromises = reorderedList?.map((task, index) => {
      // Reorganize the dueAt time for each task from 6am
      const newDueAt = dateAsDayjs(task.dueAt?.toDate())
        .startOf("day")
        .hour(6)
        .add(index * TASK_TIME_DURATION, "minute")
        .toDate();

      // Do not need to update if the new dueAt is the same as the current dueAt
      if (task.dueAt?.toDate().getTime() === newDueAt.getTime()) {
        return;
      }

      return updateTaskDueAt({
        jobId: task.jobId,
        serviceId: task.serviceId,
        taskId: task.taskId,
        dueAt: generateFirebaseTimestamp(newDueAt),
      });
    });

    await Promise.all(updatePromises);
  };

  // Hide the table when
  // - It's a driver's table AND it's from the list of drivers AND (there are no tasks OR there are tasks and the driverId is the selected driverId)
  // OR
  // - It's a driver's table AND it's not from the list of drivers AND it's the selected driver (in this case it will be rendered by the AdminDashboard page)
  const isSelectedDriverTable = driverId === selectedDriverId;
  const hasTasks = Boolean(tableData.length);

  const shouldHideTable =
    (isDriverTable &&
      isFromListOfDrivers &&
      (!hasTasks || (hasTasks && isSelectedDriverTable))) ??
    (isDriverTable && !isFromListOfDrivers && isSelectedDriverTable);

  if (shouldHideTable) {
    return null;
  }

  const onTableRowClick = (selectedTask: DashboardTableDataType) => {
    logger.debug("Selected task", selectedTask);
    setIsServiceDetailsDrawerOpened(true);
    setSelectedTask(selectedTask);
  };

  const onCloseServiceDetailsDrawer = () => {
    setIsServiceDetailsDrawerOpened(false);
  };

  // Double hidden declaration is required to overwrite the default lg: class in the DsTable component
  const clientColumnClassName =
    "hidden lg:hidden xl:table-cell xl:w-[150px] 2xl:w-[200px]";
  const taskColumnClassName = "text-center w-[44px] lg:w-[180px]";
  const schedulerNotesColumnClassName =
    "text-center w-[62px] lg:w-[100px] xl:w-[120px] 2xl:w-[200px] px-1";
  const notesColumnClassName = "text-center w-[51px] lg:w-[84px]";
  const assignedDriverColumnClassName = "w-[39px] lg:w-[200px] 2xl:w-[350px]";

  const unCompletedJobsCount = tableData.filter(task => !task.completed).length;

  return (
    <div>
      <ServiceDetailsDrawer
        jobId={selectedTask?.jobId ?? ""}
        serviceId={selectedTask?.serviceId ?? ""}
        taskId={selectedTask?.taskId ?? ""}
        isOpen={isServiceDetailsDrawerOpened}
        onCloseDrawer={onCloseServiceDetailsDrawer}
      />
      <div className="flex flex-row justify-between">
        <DsText variant="button">{tableTitle}</DsText>
        {isLoading ? (
          <DsLoading size="small" />
        ) : (
          <DsText variant="dataDisplayValue">
            {isDriverTable
              ? t("dashboard_table.jobs_left_counter", {
                  count: unCompletedJobsCount,
                  taskView,
                })
              : t("dashboard_table.jobs_counter", {
                  count: tableData?.length,
                  taskView,
                })}
          </DsText>
        )}
      </div>

      <DsTable
        onRowClick={onTableRowClick}
        data={tableData ?? []}
        className="mt-2 relative"
        hasDragAndDrop={hasDragAndDrop}
        onDragEnd={(result, reorderedList) => {
          void handleDragAndDrop(result, reorderedList);
        }}
        droppableId="tasks"
        headerColumns={[
          // TODO { shouldHideOnMobile: true }, We should add this back when we have the priority feature
          {
            title: "dashboard_table.client",
            className: `${clientColumnClassName} xl:rounded-tl-2xl`,
          },
          {
            title: "dashboard_table.location",
            className: "rounded-tl-2xl xl:rounded-tl-none",
          },
          {
            title: "dashboard_table.task",
            className: taskColumnClassName,
          },
          {
            title: isLargeScreen
              ? "dashboard_table.scheduler_notes"
              : "dashboard_table.scheduler_notes_short",
            className: schedulerNotesColumnClassName,
          },
          {
            title: "dashboard_table.notes",
            className: notesColumnClassName,
          },
          {
            title: isLargeScreen
              ? "dashboard_table.assigned_driver"
              : undefined,
            className: `${assignedDriverColumnClassName}`,
          },
        ]}
        dataColumns={[
          // TODO { dataColumn: "priorityComponent", shouldHideOnMobile: true }, We should add this back when we have the priority feature
          {
            dataColumn: "customerNameComponent",
            className: clientColumnClassName,
          },
          {
            dataColumn: "location",
          },
          {
            dataColumn: "taskComponent",
            shouldCentralizeContent: true,
            className: taskColumnClassName,
          },
          {
            dataColumn: "schedulerNotes",
            shouldCentralizeContent: true,
            className: schedulerNotesColumnClassName,
          },
          {
            dataColumn: "notesComponent",
            shouldCentralizeContent: true,
            className: notesColumnClassName,
          },
          {
            dataColumn: "assignedDriverComponent",
            shouldCentralizeContent: !isLargeScreen,
            className: assignedDriverColumnClassName,
          },
        ]}
      />
    </div>
  );
};

export { DashboardTable };
