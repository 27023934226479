import { FC } from "react";

import { BinType } from "@/domain/models/bin";

import { DsIcon } from "../components/ui";
import { classNames } from "../utils/classNames";

type BinDetailsCustomerComponentPropsType = {
  bin: BinType;
};

const BinDetailsCustomerComponent: FC<BinDetailsCustomerComponentPropsType> = ({
  bin,
}) => {
  const customerName = bin.currentData?.customer.name ?? "--";
  const customerId = bin.currentData?.customer.id;

  return (
    <a
      href={customerId ? `/admin/customers/${customerId}` : undefined}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames("inline-flex items-center w-full")}>
      <p className="flex flex-1 ">{customerName}</p>
      {customerId && <DsIcon icon="ChevronRight" className="h-4 w-4" />}
    </a>
  );
};

export { BinDetailsCustomerComponent };
