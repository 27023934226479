import { FC } from "react";

import { IconType } from "@/src/icons/icons";

import { DsButton, DsButtonPropsType } from "./DsButton";
import { DsIcon } from "./DsIcon";

type DsIconButtonPropsType = DsButtonPropsType & {
  icon: IconType;
};

const DsIconButton: FC<DsIconButtonPropsType> = ({ icon, ...buttonProps }) => {
  return (
    <DsButton className="border border-gray-300 rounded-2xl" {...buttonProps}>
      <DsIcon icon={icon} />
    </DsButton>
  );
};

export { DsIconButton };
