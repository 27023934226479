import React from "react";
import { cva, VariantProps } from "class-variance-authority";

import { classNames } from "@/src/utils/classNames";

import { DsDivider, DsLoading } from ".";
import { DsToggle } from "./DsToggle";

export type DsToggleGroupPropsType = {
  options: { label: string; value: string; disabled?: boolean }[];
  value?: string | null;
  onChange: (value: string) => void;
  className?: string;
  isLoading?: boolean;
} & VariantProps<typeof toggleGroupVariants>;

const toggleGroupVariants = cva("flex", {
  variants: {
    variant: {
      grid: "grid grid-cols-2 gap-2",
      flex: "flex border rounded-md item-center relative",
    },
  },
  defaultVariants: {
    variant: "grid",
  },
});

const DsToggleGroup = React.forwardRef<
  HTMLButtonElement,
  DsToggleGroupPropsType
>(
  (
    { className, options, value, onChange, isLoading, variant = "grid" },
    ref,
  ) => {
    const handleToggle = (value: string) => {
      onChange(value);
    };
    return (
      <div className={classNames(toggleGroupVariants({ variant, className }))}>
        {options.map((option, index) => (
          <React.Fragment key={option.value}>
            <DsToggle
              ref={ref}
              isDisabled={option.disabled}
              variant={variant === "grid" ? "outlined" : "text"}
              className={variant === "flex" ? "flex-1" : ""}
              isChecked={value === option.value}
              onToggle={() => handleToggle(option.value)}>
              {option.label}
            </DsToggle>
            {variant === "flex" && index < options.length - 1 && (
              <DsDivider orientation="vertical" flex variant="middle" />
            )}
          </React.Fragment>
        ))}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-40">
            <DsLoading size="small" />
          </div>
        )}
      </div>
    );
  },
);

DsToggleGroup.displayName = "DsToggleGroup";

export { DsToggleGroup };
