import { FC } from "react";
import { useAtomValue } from "jotai";

import { ServiceTaskTypeEnum } from "@/domain/models/common";
import { useTranslator } from "@/i18n/useTranslator";

import {
  DsButton,
  DsSelectBox,
  DsSelectBoxSelectedItem,
  DsText,
} from "../components/ui";
import { useUserQuery } from "../data/queries/userQueries";
import { useDashboardDrivers } from "../hooks/useDashboardDrivers";
import { useThemeBreakPoints } from "../hooks/useThemeBreakPoints";
import { adminDashboardSelectedDriverIdAtom } from "../stores/atoms";
import { formatDriverName } from "../utils/strings";
import { DashboardAssignedDriverSelectBoxItem } from "./DashboardAssignedDriverSelectBoxItem";

type AssignDriverComponentPropsType = {
  selectedAssignedDriverId: string;
  onSelectAssignedDriver: (driverId: string) => void;
  taskType: ServiceTaskTypeEnum;
  taskAssignedTo: string | null;
  isDriverTable: boolean;
  isLoading?: boolean;
  selectBoxClassName?: string;
  isTaskCompleted?: boolean;
  isArrowOnlyMode?: boolean;
};

const AssignDriverComponent: FC<AssignDriverComponentPropsType> = ({
  selectedAssignedDriverId,
  onSelectAssignedDriver,
  taskType,
  taskAssignedTo,
  isDriverTable,
  isLoading,
  selectBoxClassName,
  isTaskCompleted,
  isArrowOnlyMode,
}) => {
  const { t } = useTranslator();

  const { isLargeScreen } = useThemeBreakPoints();

  const dashboardSelectedDriverId = useAtomValue(
    adminDashboardSelectedDriverIdAtom,
  );

  const { data: dashboardSelectedDriverData } = useUserQuery({
    userId: dashboardSelectedDriverId,
    enabled: Boolean(dashboardSelectedDriverId),
  });

  const drivers = useDashboardDrivers({
    shouldListBinDrivers: taskType !== ServiceTaskTypeEnum.JUNKREMOVAL,
    shouldListJunkDrivers: taskType === ServiceTaskTypeEnum.JUNKREMOVAL,
  });

  const selectBoxOptions = [isDriverTable ? { id: "" } : null, ...drivers];

  const selectedDriver = drivers.find(
    driver => driver.id === selectedAssignedDriverId,
  );

  // Check if there's selected driver and if they can be assigned to the task
  const canAssignDriver = drivers.some(
    driver => driver.id === dashboardSelectedDriverId,
  );

  //If there's a driver selected in the dashboard AND it's the same driver of the Table, OR there's no selected driver then show the SelectBox
  if (
    (dashboardSelectedDriverId &&
      taskAssignedTo &&
      taskAssignedTo !== "unassigned" &&
      dashboardSelectedDriverId === taskAssignedTo) ||
    !dashboardSelectedDriverId
  ) {
    return (
      <DsSelectBox
        selectedValue={selectedAssignedDriverId}
        onSelect={onSelectAssignedDriver}
        isArrowOnlyMode={isArrowOnlyMode ?? !isLargeScreen} // If the screen is small or medium, then only show the arrow
        listBoxClassName="mt-3 lg:mt-1"
        isLoading={isLoading}
        listBoxButtonClassName={isTaskCompleted ? "bg-transparent" : ""}
        selectedItem={
          <DsSelectBoxSelectedItem
            label={
              formatDriverName(selectedDriver?.displayName ?? "") ??
              t("dashboard_assigned_driver_select_box_item.unassigned")
            }
          />
        }>
        {selectBoxOptions
          .filter(driver => driver !== null)
          .map(driver => (
            <DashboardAssignedDriverSelectBoxItem
              key={driver.id}
              driverId={driver.id}
            />
          ))}
      </DsSelectBox>
    );
  }

  // If there's a diver select in the dashboard AND the task is assigned AND it's not the same driver of the Table, then show the Reassign button
  if (
    dashboardSelectedDriverId &&
    taskAssignedTo &&
    taskAssignedTo !== "unassigned" &&
    taskAssignedTo !== dashboardSelectedDriverId &&
    dashboardSelectedDriverData
  ) {
    return (
      <DsButton
        disabled={!canAssignDriver}
        isLoading={isLoading}
        className="bg-white border border-gray-300 hover:bg-transparent w-full hidden lg:inline-flex truncate items-center"
        onClick={e => {
          e.stopPropagation();
          onSelectAssignedDriver(dashboardSelectedDriverId);
        }}>
        <DsText variant="button" className="text-yellow-700 truncate">
          {t("dashboard_assigned_driver_select_box_item.reassign_to_driver", {
            driverName: formatDriverName(
              dashboardSelectedDriverData.displayName,
            ),
            interpolation: { escapeValue: false },
          })}
        </DsText>
      </DsButton>
    );
  }

  // If there's a selected driver in the dashboard but the task is not assigned, then show the Assign button
  if (
    dashboardSelectedDriverId &&
    (!taskAssignedTo || taskAssignedTo === "unassigned") &&
    dashboardSelectedDriverData
  ) {
    return (
      <DsButton
        disabled={!canAssignDriver}
        isLoading={isLoading}
        onClick={e => {
          e.stopPropagation();
          onSelectAssignedDriver(dashboardSelectedDriverId);
        }}
        className="w-full hidden lg:inline-flex truncate items-center">
        <DsText variant="button" className="truncate">
          {t("dashboard_assigned_driver_select_box_item.assign_to_driver", {
            driverName: formatDriverName(
              dashboardSelectedDriverData.displayName,
            ),
            interpolation: { escapeValue: false },
          })}
        </DsText>
      </DsButton>
    );
  }
};

export { AssignDriverComponent };
