import { FC } from "react";

import { DsText } from "../components/ui";
import { classNames } from "../utils/classNames";

type BinSizeFilterType = {
  title: string;
  quantity: number;
  handleOnSelect: () => void;
  isSelected?: boolean;
};

const BinSizeFilter: FC<BinSizeFilterType> = ({
  title,
  quantity,
  handleOnSelect,
  isSelected = false,
}) => {
  const onSelect = () => {
    handleOnSelect();
  };

  return (
    <div
      onClick={onSelect}
      className={classNames(
        "h-20 md:max-w-28 w-[calc(33.33%-0.5rem)] rounded-2xl border border-gray-300 flex flex-col justify-center pl-2 hover:cursor-pointer bg-white",
        isSelected && "bg-sos-yellow border-sos-yellow",
      )}>
      <DsText
        className={classNames(
          "text-[#BD5100] font-medium",
          isSelected && "text-black",
        )}>
        {title}
      </DsText>
      <DsText
        className={classNames(
          "text-2xl text-[#BD5100] font-bold",
          isSelected && "text-black",
        )}>
        {quantity}
      </DsText>
    </div>
  );
};

export { BinSizeFilter };
