import { FC } from "react";

import { TasksType } from "@/domain/models/job";
import { I18nKeyType } from "@/i18n";
import { useTranslator } from "@/i18n/useTranslator";

import { DsText } from "../components/ui";
import { DriverTaskCard } from "./DriverTaskCard";

type DriverDashboardSectionPropsType = {
  title: I18nKeyType;
  tasks: TasksType[];
};

const DriverDashboardSection: FC<DriverDashboardSectionPropsType> = ({
  title,
  tasks,
}) => {
  const { t } = useTranslator();

  if (!tasks?.length) {
    return null;
  }

  return (
    <div className="mb-7">
      <DsText variant="pageTitle" className="mb-3">
        {t(title) as string}
      </DsText>

      <ul role="list">
        {tasks?.map((task, index) => {
          return (
            <li key={index}>
              <DriverTaskCard task={task} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { DriverDashboardSection };
